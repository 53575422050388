import DetectRTC from 'detectrtc';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from '../context/auth';
import { MOBILE_WIDTH, ROLES } from './constants';

interface WindowSize {
    width: number | undefined;
    height: number | undefined;
}

export const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export const useIsMobile = () => {
    const { width } = useWindowSize();

    return !!width && width <= MOBILE_WIDTH;
};

export const usePageTitle = (title: string, retainOnUnmount = false) => {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        return () => {
            if (!retainOnUnmount) {
                document.title = defaultTitle.current;
            }
        };
    }, []);
};

export const useOnClickOutside = (ref: any, handler: Function) => {
    useEffect(
        () => {
            const listener = (event: any) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler],
    );
};

export const useInterval = (callback: () => void, delay: number | null) => {
    const savedCallback = useRef(callback);

    // Remember the latest callback if it changes.
    useLayoutEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return;
        }

        const id = setInterval(() => savedCallback.current(), delay);

        return () => clearInterval(id);
    }, [delay]);
};

export const useLocalStorage = <T>(key: string, initialValue?: T) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue] as const;
};

export const useHasCameraAndMicPermissions = () => {
    const [hasPermissions, setHasPermissions] = useState(
        DetectRTC.isWebsiteHasWebcamPermissions && DetectRTC.isWebsiteHasMicrophonePermissions,
    );

    useInterval(() => {
        DetectRTC.load(() => {
            const permission =
                DetectRTC.isWebsiteHasWebcamPermissions &&
                DetectRTC.isWebsiteHasMicrophonePermissions;
            if (permission !== hasPermissions) setHasPermissions(permission);
        });
    }, 500);

    return hasPermissions;
};

export const useCurrentPermission = () => {
    const { companyId } = useParams();
    const { permissions } = useAuthState();

    const [lastOpenedCompanyId, setLastOpenedCompanyId] = useLocalStorage('LAST_OPENED_COMPANY_ID');

    const permission =
        permissions.find((p) => p.companyId.toString() === companyId) ||
        permissions.find((p) => p.companyId.toString() === lastOpenedCompanyId) ||
        permissions[0];

    return permission;
};

export const useCurrentCompany = () => {
    return useCurrentPermission()?.company || {};
};

export const useCurrentRole = () => {
    return useCurrentPermission()?.role || 'NONE';
};

export const useIsAdmin = () => {
    return [ROLES.ADMIN, ROLES.OWNER].includes(useCurrentRole());
};

export const useIsReviewer = () => {
    return [ROLES.REVIEWER].includes(useCurrentRole());
};

export const useIsPublicView = () => {
    const { companyId } = useParams();
    const { authenticated, permissions } = useAuthState();

    // For the case if user is viewing public link of some other company in which they dont belong
    return !(authenticated && !!permissions.find((p) => p.companyId.toString() === companyId));
};
