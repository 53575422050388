import styled from 'styled-components';

import ContentEditable from 'react-contenteditable';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';

import FormField from './FormField';
import dimensions, { scalingSize } from '../../styles/dimensions';
import fonts from '../../styles/fonts';

const padding = 16;

interface EditableProps extends TextareaAutosizeProps {
    error?: string;
    label?: any;
    font?: any;
    containerStyle?: any;
}

const StyledEditable = styled(TextareaAutosize)<{ font?: any }>`
    ${(props) => props.font || fonts.body}

    min-height: 1em;
    min-width: 5em;
    overflow-y: ${(props) => props.maxRows === 1 && 'hidden'};

    flex-grow: 1;

    color: ${(props) => props.theme.onBackground};
    ::placeholder {
        color: ${(props) => props.theme.onBackgroundLight};
    }

    /* background: ${(props) => (props.value ? props.theme.background : props.theme.foreground)}; */
    background: ${(props) => props.theme.foreground};
    border: none;
    border-radius: ${dimensions.borderRadius};

    &:hover {
        background: ${(props) => props.theme.backgroundDark};
    }

    &:focus {
        background: ${(props) => props.theme.foregroundDark};
        outline: none;
    }

    padding: 1em;
`;

export default ({ label, error, containerStyle, ...otherProps }: EditableProps) => {
    return (
        <FormField label={label} error={error} containerStyle={containerStyle}>
            <StyledEditable {...otherProps} />
        </FormField>
    );
};
