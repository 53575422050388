import styled from 'styled-components';

const StyledIcon = styled.img<{ size: number }>`
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;

    margin: 0.5em;
`;

const Icon = ({ name = '', color = '', size = 16 }) => {
    return (
        <StyledIcon
            src={require(`../res/icons${color === 'white' ? '-white' : ''}/${name}.png`)}
            size={size}
        />
    );
};

export default Icon;
