import { useMutation, useQueryClient } from 'react-query';
import useAxios from './useAxios';
import axios from 'axios';
import { useState } from 'react';
import { ErrorType } from '../../types/global';
import { User } from '../../types/schema';
import { toast } from 'react-toastify';

interface UserUpdateData {
    name?: string;
}

interface UserPasswordData {
    oldPassword: string | undefined;
    newPassword: string | undefined;
}

interface ResetPasswordData {
    userId: number;
    slug: string;
    newPassword: string | undefined;
}

interface VerifyUserData {
    userId: number;
    slug: string;
}

const useUser = () => {
    const [errors, setErrors] = useState<ErrorType>({});
    const { api } = useAxios();

    const uploadPicture = async (file: File): Promise<User> => {
        const { data: uploadUrlData } = await api.get('/user/uploadPictureUrl', {
            params: { fileType: file.type.split('/')[1] },
        });

        const { uploadUrl, path } = uploadUrlData;

        await axios.put(uploadUrl, file, {
            headers: { 'Content-Type': file.type },
        });

        const { data: confirmUploadData } = await api.put('/user/confirmPictureUpload', { path });
        return confirmUploadData.user;
    };

    const updateUserData = async (updateData: UserUpdateData): Promise<User> => {
        const { data } = await api.put('user/updateData', updateData);
        return data.user;
    };

    const changeUserPassword = async (updateData: UserPasswordData): Promise<User> => {
        const { data } = await api.put('user/changePassword', updateData, {
            withCredentials: true,
        });
        return data.user;
    };

    const setUserPassword = async (newPassword: string | undefined): Promise<User> => {
        const { data } = await api.put('user/setPassword', { newPassword });
        return data.user;
    };

    const resetUserPassword = async (updateData: ResetPasswordData): Promise<boolean> => {
        await api.put('user/resetPassword', updateData);
        return true;
    };

    const forgotUserPassword = async (email: string | undefined): Promise<boolean> => {
        await api.post('user/generatePasswordResetLink', { email });
        return true;
    };

    const sendUserVerificationLink = async (): Promise<boolean> => {
        await api.get('user/generateVerificationLink');
        return true;
    };

    const verifyUser = async (updateData: VerifyUserData): Promise<boolean> => {
        await api.post('user/verify', updateData);
        return true;
    };

    const queryClient = useQueryClient();
    const uploadProfilePicture = useMutation(uploadPicture, {
        onMutate: () => setErrors({}),
        onSuccess: (user) => {
            queryClient.setQueryData('me', { user });
            toast.success('Profile picture Updated');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const updateData = useMutation(updateUserData, {
        onMutate: () => setErrors({}),
        onSuccess: (user) => {
            queryClient.setQueryData('me', { user });
            toast.success('Changes saved');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const changePassword = useMutation(changeUserPassword, {
        onMutate: () => setErrors({}),
        onSuccess: (user) => {
            queryClient.setQueryData('me', { user });
            toast.success('Password changed successfully');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const setPassword = useMutation(setUserPassword, {
        onMutate: () => setErrors({}),
        onSuccess: (user) => {
            queryClient.setQueryData('me', { user });
            toast.success('Password set successfully');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const forgotPassword = useMutation(forgotUserPassword, {
        onMutate: () => setErrors({}),
        onError: (error: any) => setErrors(error.response.data),
    });

    const resetPassword = useMutation(resetUserPassword, {
        onMutate: () => setErrors({}),
        onError: (error: any) => setErrors(error.response.data),
    });

    const verify = useMutation(verifyUser, {
        onMutate: () => setErrors({}),
        onError: (error: any) => setErrors(error.response.data),
    });

    const sendVerificationLink = useMutation(sendUserVerificationLink, {
        onMutate: () => setErrors({}),
        onError: (error: any) => setErrors(error.response.data),
    });

    return {
        errors,
        setErrors,
        changePassword,
        uploadProfilePicture,
        updateData,
        forgotPassword,
        resetPassword,
        setPassword,
        sendVerificationLink,
        verify,
    };
};

export default useUser;
