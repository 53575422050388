import styled from 'styled-components';

import dimensions from '../styles/dimensions';

const ModuleScrollView = styled.div<{ padding?: string }>`
    height: calc(100% - ${dimensions.navBarHeight});

    overflow-y: auto;
    padding: ${(props) => props.padding || '1em'};

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default ModuleScrollView;
