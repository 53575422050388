import {
    Box,
    Flex,
    Spacer,
    Image,
    HStack,
    Center,
    Heading,
    Text,
    Button,
    VStack,
    Wrap,
    Tabs,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import useInterviewCandidate from '../../api/hooks/useInterviewCandidate';
import RichTextInput from '../../components/Form/RichTextInput';
import Loading from '../../components/Loading';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';
import { useColors } from '../../styles/colors';
import { getColorBasedOnBg } from '../../utils';
import CompanyLogo from '../../components/Company/CompanyLogo';
import CompanyButton from '../../components/Company/CompanyButton';
import AppLogo from '../../components/AppLogo';
import { Footer, Header } from './components';
import analytics from '../../utils/analytics';

const MAX_WIDTH = 700;

const StyledRichTextInput = styled(RichTextInput)`
    margin-top: 1em;
    .ql-container {
        font-family: Inter, sans-serif;
    }
`;

const IntroVideo = () => {
    const colors = useColors();

    return <div style={{ width: '100%', height: '25em', background: colors.backgroundDark }}></div>;
};

export default () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const { interviewId, slug } = useParams();
    const colors = useColors();
    const [tabIndex, setTabIndex] = useState(1);
    const navigate = useNavigate();

    const { interview, isLoading } = useInterviewCandidate({ interviewId, slug });

    const position = interview?.position;
    const company = interview?.company;
    const themeColor = company?.themeColors?.[0] || colors.primary;

    const instructions = [
        { image: '/instructions_1.svg', text: 'Allow Camera & \nMicrophone Access' },
        { image: '/instructions_2.svg', text: 'Sit In Front \nOf A Light Source' },
        { image: '/instructions_3.svg', text: 'Maintain A \nDistraction Free Zone' },
        { image: '/instructions_4.svg', text: 'Ensure Good \nNetwork Connection' },
        { image: '/instructions_5.svg', text: 'Make Sure \nYou Are Plugged In' },
    ];

    if (isLoading) return <Loading />;

    return (
        <Flex h="100%" flexDir="column" align="center">
            <Header company={company} />

            <Tabs
                align="center"
                w={['98%', '98%', '80%']}
                mt="-4"
                index={tabIndex}
                onChange={setTabIndex}
                _focus={{ boxShadow: 'none' }}
            >
                <TabList position="sticky" top="0" bg="background.main" zIndex="1">
                    {['JOB BRIEF', 'INTERVIEW'].map((title) => (
                        <Tab
                            key={title}
                            fontWeight="bold"
                            w="40%"
                            maxW="80"
                            p="4"
                            px="5%"
                            _selected={{ color: themeColor, borderColor: themeColor }}
                            _focus={{ boxShadow: 'none' }}
                        >
                            {title}
                        </Tab>
                    ))}
                </TabList>

                <TabPanels>
                    <TabPanel w={['98%', '98%', '60%']} p="4">
                        <StyledRichTextInput readOnly value={position?.jobDescription} />

                        <CompanyButton mt="12" company={company} onClick={() => setTabIndex(1)}>
                            GO TO INTERVIEW
                        </CompanyButton>
                    </TabPanel>
                    <TabPanel pt="4">
                        <Center flex="1" flexDir="column">
                            <Box width="100%" maxWidth="2xl">
                                <Center m="2" mt="8">
                                    <Heading textAlign="center" size="lg">
                                        One way video interview for
                                        <br />
                                        <Box as="span" color={themeColor}>
                                            {position?.name}
                                        </Box>
                                        <br />
                                        at {company?.name}
                                    </Heading>
                                </Center>
                            </Box>

                            <Text
                                maxW="lg"
                                m="4"
                                mt="8"
                                fontSize="md"
                                fontWeight="500"
                                textAlign="center"
                            >
                                Dear candidate, the aim of this screening interview is to allow you
                                to tell us about yourself in a much more convenient manner and help
                                us understand you closely.
                            </Text>

                            <Center>
                                <Wrap
                                    justify="center"
                                    textAlign="center"
                                    m="8"
                                    spacing={[8, 10, 12]}
                                >
                                    {instructions.map(({ image, text }) => (
                                        <VStack key={text} w={[32, 32, 32]} whiteSpace="pre-wrap">
                                            <Image src={image} h={[12, 12, 12]} alt={text} />
                                            <Box fontWeight="bold" fontSize="xs" lineHeight="1rem">
                                                {text}
                                            </Box>
                                        </VStack>
                                    ))}
                                </Wrap>
                            </Center>

                            <CompanyButton
                                onClick={() =>
                                    navigate('/interviews/hardware/' + interviewId + '/' + slug)
                                }
                                company={company}
                                mt="12"
                            >
                                GET STARTED
                            </CompanyButton>
                        </Center>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Spacer />

            <Footer />
        </Flex>
    );
};
