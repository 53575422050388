import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FlexView from 'react-flexview/lib';
import { Box, useInterval, Image, Button, Spacer, Flex } from '@chakra-ui/react';

import VideoRecorder, {
    updateAudioDevice,
    updateVideoDevice,
} from '../../components/VideoRecorder';
import { BodyDark, BodyText, SubHeading } from '../../components/Fonts';
import Form from '../../components/Form/FormContainer';
import Select from '../../components/Form/Select';
import { Footer, FullWidthContainer, HalfWidthContainer, Header } from './components';
import { useHasCameraAndMicPermissions, useIsMobile } from '../../utils/hooks';
import useInterviewCandidate from '../../api/hooks/useInterviewCandidate';
import { useColors } from '../../styles/colors';
import CompanyButton from '../../components/Company/CompanyButton';
import TawkTo from '../../components/TawkTo';

const testMic = (deviceId: string, stream1?: MediaStream) =>
    new Promise((resolve) =>
        navigator.mediaDevices.getUserMedia({ audio: { deviceId: deviceId } }).then((stream) => {
            const audioContext = new AudioContext();
            const analyser = audioContext.createAnalyser();
            const microphone = audioContext.createMediaStreamSource(stream);
            let count = 0;

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 32;

            microphone.connect(analyser);

            const intervalId = setInterval(() => {
                const array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                const sum = array.reduce((sum, v) => sum + v, 0);
                const average = sum / array.length;

                if (average > 0) {
                    clearInterval(intervalId);
                    resolve(average);
                }
                count++;

                if (count > 10) {
                    // give candidate about 5 seconds for mic testing
                    clearInterval(intervalId);
                }
            }, 500);
        }),
    );

export default () => {
    const { interviewId, slug } = useParams();
    const colors = useColors();

    const { interview, isLoading } = useInterviewCandidate({ interviewId, slug });
    const position = interview?.position;
    const company = interview?.company;

    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const hasPermissions = useHasCameraAndMicPermissions();

    const [cameraOptions, setCameraOptions] = useState<any>([]);
    const [micOptions, setMicOptions] = useState<any>([]);

    const [selectedCam, setSelectedCam] = useState('');
    const [selectedMic, setSelectedMic] = useState('');

    const getDeviceOptions = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();

        const camOptions = devices
            .filter((d) => d.kind === 'videoinput')
            .map((d) => ({ label: d.label, value: d.deviceId }));
        setCameraOptions(camOptions);
        setSelectedCam(camOptions?.[0]?.value);

        const micOptions = devices
            .filter((d) => d.kind === 'audioinput')
            .map((d) => ({ label: d.label, value: d.deviceId }));
        setMicOptions(micOptions);
        setSelectedMic(micOptions?.[0]?.value);
    };

    const onAudioDeviceChange = (o: any) => {
        setMicWarningLevel(1);
        setSelectedMic(o.value);
        updateAudioDevice(o.value);
    };
    const onVideoDeviceChange = (o: any) => {
        setSelectedCam(o.value);
        updateVideoDevice(o.value);
    };

    useEffect(() => {
        getDeviceOptions();
    }, [hasPermissions]);

    useEffect(() => {
        const tawk = new TawkTo('628a433fb0d10b6f3e7376d4', '1g3m1dcvq');
        tawk.hideWidget();
    }, []);

    const [micVolume, setMicVolume] = useState(0);
    const [micWarningLevel, setMicWarningLevel] = useState(1);

    useInterval(() => {
        micVolume <= 0 && setMicWarningLevel(micWarningLevel === 1 ? 2 : 1);
        micVolume > 0 && setMicWarningLevel(0);
    }, 3000);

    return (
        <Flex h="100%" align="center" flexDir="column">
            <Header company={company} />

            <FullWidthContainer>
                <VideoRecorder hideTimer hideRec onMicVolumeChange={setMicVolume} />

                <HalfWidthContainer>
                    <SubHeading>Interview Settings</SubHeading>
                    <BodyText marginTop="1em">
                        {hasPermissions
                            ? 'Please make sure your webcam and microphone are working properly before you start the interview.'
                            : 'As this is a video interview, your camera and microphone permissions are required. Please allow them when prompted.'}
                    </BodyText>

                    {hasPermissions && (
                        <Form
                            onSubmit={async (e) => {
                                e.preventDefault();
                            }}
                        >
                            <BodyText marginTop="2em">Camera Settings</BodyText>
                            <Select
                                placeholder="Select camera"
                                isSearchable={false}
                                options={cameraOptions}
                                value={cameraOptions.find((o: any) => o.value === selectedCam)}
                                onChange={onVideoDeviceChange}
                            />

                            <BodyText marginTop="1em">Microphone Settings</BodyText>
                            <Select
                                placeholder="Select microphone"
                                isSearchable={false}
                                options={micOptions}
                                value={micOptions.find((o: any) => o.value === selectedMic)}
                                onChange={onAudioDeviceChange}
                            />
                        </Form>
                    )}

                    <FlexView column grow vAlignContent="bottom" marginTop="2em">
                        {!hasPermissions && (
                            <Box m="2" color="red.500">
                                Please grant camera and mic permissions
                            </Box>
                        )}
                        {hasPermissions && micWarningLevel === 1 && (
                            <Box m="2" color="gray.600">
                                Testing your mic, say "Hello" out loud
                            </Box>
                        )}
                        {hasPermissions && micWarningLevel === 2 && (
                            <Box m="2" color="red.500">
                                We can't hear you, try switching to another mic
                            </Box>
                        )}
                        <CompanyButton
                            company={company}
                            disabled={!hasPermissions || micWarningLevel > 0}
                            onClick={() =>
                                navigate('/interviews/countdown/' + interviewId + '/' + slug)
                            }
                            type="submit"
                        >
                            BEGIN INTERVIEW
                        </CompanyButton>
                    </FlexView>
                </HalfWidthContainer>
            </FullWidthContainer>

            <Spacer />

            <Footer />
        </Flex>
    );
};
