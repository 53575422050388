import { useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Center, Heading, Image, Text, Flex } from '@chakra-ui/react';

import Button from '../../components/Button';
import Form from '../../components/Form/FormContainer';
import Input from '../../components/Form/Input';
import GoogleAuth from '../Login/GoogleAuth';
import LinkedInAuth from '../Login/LinkedInAuth';
import { useAuthFunctions, useAuthState } from '../../context/auth';
import dimensions from '../../styles/dimensions';
import fonts from '../../styles/fonts';
import MessageBox from '../../components/MessageBox';
import LoginArt from '../Login/LoginArt';
import analytics from '../../utils/analytics';

const maxBoxWidth = 420;

const RegisterBox = styled.div`
    width: 60%;
    min-width: 300px;
    max-width: ${maxBoxWidth}px;

    display: flex;
    flex-direction: column;

    margin: 0 auto;
`;

const Separator = styled.div`
    height: 1px;
    min-width: 200px;

    background: ${({ theme }) => theme.foregroundDark};

    margin: 2em 0em;
`;

const MaxWidthInput = styled(Input)`
    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        max-width: ${maxBoxWidth}px;
    }

    max-width: ${maxBoxWidth / 2 - 16}px;
    margin: 8px;
`;

export default () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const { authenticated } = useAuthState();
    const { register, errors, setErrors } = useAuthFunctions();
    const { mutate, isLoading } = register;

    if (authenticated) return <Navigate to="/" />;

    return (
        <Flex h="100%">
            <LoginArt />

            <Box flex="1" overflow="auto">
                <Center flexDir="column" my="16">
                    <Heading mx="2" size="xl" maxW="xl" textAlign="center">
                        Hiring Is About To Become Your{' '}
                        <Box as="span" color="primary.main">
                            Superpower.
                        </Box>
                    </Heading>

                    <Text mx="4" mt="8" maxW="lg" fontSize="md" fontWeight="500" textAlign="center">
                        Register with your business email and start screening candidates using one
                        way video interviews.
                    </Text>
                </Center>

                <RegisterBox>
                    <LinkedInAuth isRegistering />
                    <GoogleAuth isRegistering />

                    <Separator />

                    {errors.message && (
                        <MessageBox status={'warning'} description={errors.message} />
                    )}

                    <Form>
                        <Input
                            name="name"
                            label="Full Name"
                            placeholder="Full Name"
                            onChange={(e) => {
                                setErrors({});
                                setName(e.target.value);
                            }}
                            error={errors.name}
                        />

                        <Input
                            name="email"
                            type="Email"
                            label="Email"
                            placeholder="Email"
                            onChange={(e) => {
                                setErrors({});
                                setEmail(e.target.value);
                            }}
                            error={errors.email}
                        />
                    </Form>

                    {/* Keeping this outside form prevents chrome autocomplete */}
                    <Input
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Set a strong password"
                        autoComplete="new-password"
                        onChange={(e) => {
                            setErrors({});
                            setPassword(e.target.value);
                        }}
                        error={errors.password}
                    />

                    <Button
                        isLoading={isLoading}
                        onClick={() => {
                            analytics.event('Login', 'Default Signup', 'Button clicked');
                            mutate({ name, email, password });
                        }}
                    >
                        SIGN UP
                    </Button>

                    <Box m="4" textAlign="center">
                        Already have an account?{' '}
                        <Link to="/login" replace>
                            <Box as="span" color="primary.main">
                                Login
                            </Box>
                        </Link>
                    </Box>
                </RegisterBox>
            </Box>

            <Image
                src="/12_1.svg"
                position="absolute"
                maxH={['0', '16rem', '22rem']}
                bottom="0"
                right="2"
                opacity="0.9"
            />
        </Flex>
    );
};
