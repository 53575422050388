import { useEffect, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import FlexView from 'react-flexview';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { HamburgerIcon, QuestionOutlineIcon, TimeIcon } from '@chakra-ui/icons';
import moment from 'moment';

import { Position } from '../../types/schema';
import NavBar from '../../components/NavBar';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';

import { useColors } from '../../styles/colors';
import dimensions from '../../styles/dimensions';
import Card from '../../components/Card';
import Loading from '../../components/Loading';
import { BodyDark, BodyText, ButtonLight, Heading, SubHeading } from '../../components/Fonts';
import fonts from '../../styles/fonts';
import ModuleScrollView from '../../components/ModuleScrollView';
import Button from '../../components/Button';
import Welcome from '../../components/Welcome';
import PositionStats from './PositionStats';
import AddPosition from './AddPosition';
import AddFirstPosition from './AddFirstPosition';
import { useIsMobile } from '../../utils/hooks';
import { INTERVIEW_STATUSES } from '../../utils/constants';
import { useManyPositions, usePosition } from '../../api/hooks/usePositions';
import analytics from '../../utils/analytics';
import Tutorial from '../../components/Tutorial';

const POSITIONS_MAX_WIDTH = 1000;

const StyledPositionCard = styled(Card)`
    width: ${POSITIONS_MAX_WIDTH / 4.3}px;
    /* flex: 1 1 ${POSITIONS_MAX_WIDTH / 4.3}px; */
    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        flex: 1 1 ${POSITIONS_MAX_WIDTH / 4.3}px;
    }

    max-height: 30em;
    overflow: auto;
    cursor: pointer;

    display: flex;
    flex-direction: column;

    background-color: ${(props) => props.theme.background};
    border: 1px solid ${({ theme }) => theme.foregroundDark};
    border-bottom: 6px solid ${({ theme }) => theme.backgroundDark};
`;

const StyledPositionLink = styled(Link)`
    text-decoration: none;
    color: ${(props) => props.theme.secondary};
    :hover {
        color: ${(props) => props.theme.secondaryDark};
    }

    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

const PositionCard = ({ name, id, companyId, questions }: Position) => {
    const colors = useColors();
    const { stats: statsData, isLoading } = useManyPositions(companyId);

    let initialStats: Array<any> = [
        INTERVIEW_STATUSES.INVITED,
        INTERVIEW_STATUSES.REVIEW_PENDING,
        INTERVIEW_STATUSES.ACCEPTED,
        INTERVIEW_STATUSES.REJECTED,
    ];
    const [stats, setStats] = useState(initialStats);

    useEffect(() => {
        console.log('D_77', isLoading, statsData, stats);
        if (!isLoading && statsData) {
            const updatedStats = stats.map((s: any) => {
                const count =
                    statsData.find(({ status, positionId }: any) => {
                        return status === s.value && positionId === id;
                    })?._count.status || 0;

                console.log('D_77 A', count, s);
                return { ...s, count };
            });

            setStats(updatedStats);
        }
    }, [isLoading, statsData]);

    const totalResponseTime = questions.reduce((sum, q) => sum + q.idealResponseTime, 0);

    const PositionStat = ({ label = '', count = 0, color, Icon }: any) => {
        return (
            <FlexView key={label} vAlignContent="center" marginTop={5}>
                {/* <Icon color={color} mr="0.5rem" boxSize="1rem" /> */}
                <BodyText>{label}</BodyText>
                <FlexView grow />
                <BodyDark>{count}</BodyDark>
            </FlexView>
        );
    };

    return (
        <StyledPositionCard>
            <StyledPositionLink to={'/positions/overview/' + id + '/' + companyId}>
                <Box style={{ ...fonts.heading }} maxW="12rem">
                    {name}
                </Box>

                <FlexView vAlignContent="center" marginTop="1.2em">
                    <HamburgerIcon color={colors.lightblue} mr="0.5rem" />
                    <BodyDark color={colors.blue}>{questions.length} questions</BodyDark>
                </FlexView>

                <FlexView vAlignContent="center" marginTop=".5em">
                    <TimeIcon color={colors.lightgreen} mr="0.5rem" />
                    <BodyDark color={colors.green}>
                        {moment.duration(totalResponseTime, 'seconds').humanize()}
                    </BodyDark>
                </FlexView>

                <FlexView grow />

                <Box mt="2" color="onBackground.main" overflow="auto">
                    {stats.map(PositionStat)}
                </Box>
            </StyledPositionLink>
        </StyledPositionCard>
    );
};

export default () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const isMobile = useIsMobile();
    const { companyId } = useParams();

    const { positions, isLoading } = useManyPositions(companyId);

    if (isLoading) return <Loading />;

    return (
        <>
            <NavBar
                moduleName="Positions"
                navBarRight={positions.length ? <AddPosition /> : null}
            />

            {!positions.length ? (
                <AddFirstPosition />
            ) : (
                <ModuleScrollView>
                    <ModuleMaxWidthContainer maxWidth={POSITIONS_MAX_WIDTH}>
                        {/* <PositionStats /> */}

                        <Heading marginTop="1em" marginBottom="1em">
                            Active Positions ({positions.length})
                        </Heading>

                        <FlexView wrap>
                            {positions.map((p) => (
                                <PositionCard key={p.id} {...p} />
                            ))}
                        </FlexView>
                    </ModuleMaxWidthContainer>
                </ModuleScrollView>
            )}

            <Welcome />

            <Tutorial
                tutorialKey={'POSITIONS_TUTORIAL_DONE'}
                steps={[
                    {
                        disableBeacon: true,
                        target: '.new-position-button',
                        content: 'Start by creating a new job position',
                    },
                ]}
            />
        </>
    );
};
