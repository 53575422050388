import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Center, Flex, Heading, Image, Text } from '@chakra-ui/react';

import Button from '../../components/Button';
import Form from '../../components/Form/FormContainer';
import Input from '../../components/Form/Input';
import { useAuthFunctions, useAuthState } from '../../context/auth';
import fonts from '../../styles/fonts';
import GoogleAuth from './GoogleAuth';
import LinkedInAuth from './LinkedInAuth';
import LoginArt from './LoginArt';
import MessageBox from '../../components/MessageBox';
import { useColors } from '../../styles/colors';
import analytics from '../../utils/analytics';

const LoginBox = styled.div`
    width: 60%;
    min-width: 300px;
    max-width: 420px;

    display: flex;
    flex-direction: column;

    margin: 0 auto; // centers itself in container
`;

const Separator = styled.div`
    height: 1px;
    min-width: 200px;

    background: ${({ theme }) => theme.foregroundDark};

    margin: 2em 0em;
`;

const NoAccountDiv = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.onBackground};

    ${fonts.body}
`;

const SignUpButton = styled(Button)`
    margin: 0;
    padding: 0;
    padding-left: 0.3em;
    padding-right: 0.3em;
    padding-bottom: 0.25em;
`;

export default () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const colors = useColors();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const { defaultLogin, errors, setErrors } = useAuthFunctions();
    const { authenticated } = useAuthState();

    if (authenticated) return <Navigate to="/" />;

    return (
        <Flex h="100%">
            <LoginArt />

            <Box flex="1" overflow="auto">
                <Center flexDir="column" my="16">
                    <Heading mx="2" size="xl" maxW="xl" textAlign="center">
                        Bypass{' '}
                        <Box as="span" color="primary.main">
                            Ghosting{' '}
                        </Box>
                        Like A
                        <br />
                        Pro Recruiter
                    </Heading>

                    <Text mx="4" mt="8" maxW="lg" fontSize="md" fontWeight="500" textAlign="center">
                        You are one step away from making recruitment a core competency of your
                        company.
                    </Text>
                </Center>

                <LoginBox>
                    {/* <LinkedInAuth /> */}
                    <GoogleAuth />

                    <Separator />

                    {errors.message && (
                        <MessageBox status={'warning'} description={errors.message} />
                    )}

                    <Form
                        onSubmit={async (e) => {
                            e.preventDefault();
                            analytics.event('Login', 'Default Login', 'Button clicked');
                            defaultLogin.mutate({ email, password });
                        }}
                    >
                        <Input
                            name="email"
                            type="email"
                            placeholder="Email"
                            onChange={(e) => {
                                setErrors({});
                                setEmail(e.target.value);
                            }}
                            error={errors.email}
                        />
                        <Input
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={(e) => {
                                setErrors({});
                                setPassword(e.target.value);
                            }}
                            error={errors.password}
                        />

                        <Button type="submit">LOGIN</Button>
                    </Form>

                    <Link to="/forgotPassword" style={{ marginTop: '2em' }}>
                        <Box color="primary.main" textAlign="center">
                            Forgot Password
                        </Box>
                    </Link>

                    <Link to="/register" replace style={{ marginTop: '1em' }}>
                        <Box color="onBackground.main" textAlign="center">
                            Don't have an account?{' '}
                            <Box as="span" color="primary.main">
                                Sign up
                            </Box>
                        </Box>
                    </Link>
                </LoginBox>
            </Box>

            <Image
                src="/12.svg"
                position="absolute"
                maxH={['0', '16rem', '22rem']}
                bottom="0"
                right="0"
                opacity="0.9"
            />
        </Flex>
    );
};
