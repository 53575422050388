import { useEffect, useRef, useState } from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    IconButton,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { HiOutlineArchive } from 'react-icons/hi';
import { RiInboxUnarchiveLine } from 'react-icons/ri';

import { BodyText, BodyDark } from '../../components/Fonts';
import { useParams } from 'react-router-dom';
import useManyInterviews from '../../api/hooks/useManyInterviews';
import analytics from '../../utils/analytics';

const ArchiveInterview: React.FC<{
    interview: {
        name: string;
        position: string;
        id: number;
        publicSlug: string;
        isArchived: boolean;
    };
}> = ({ interview }) => {
    const { companyId } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { archive, unarchive } = useManyInterviews(companyId);
    const cancelRef = useRef<any>();

    const archiveInterview = () => {
        analytics.event('Archive Interview', 'Successfully archived', 'Button clicked');
        archive.mutate({ interviewId: interview.id, publicSlug: interview.publicSlug });
    };

    const unarchiveInterview = () => {
        analytics.event('Unrchive Interview', 'Successfully unarchived', 'Button clicked');
        unarchive.mutate({ interviewId: interview.id, publicSlug: interview.publicSlug });
    };

    const data = interview.isArchived
        ? {
              icon: <RiInboxUnarchiveLine />,
              heading: 'Restore Interview',
              question: `Are you sure, you want to restore this interview?`,
              button: 'RESTORE',
              loading: 'RESTORING',
              onClick: unarchiveInterview,
              isLoading: unarchive.isLoading,
              reset: unarchive.reset,
          }
        : {
              icon: <HiOutlineArchive />,
              heading: 'Archive Interview',
              question: `Are you sure, you want to archive this interview?`,
              button: 'ARCHIVE',
              loading: 'ARCHIVING',
              onClick: archiveInterview,
              isLoading: archive.isLoading,
              reset: archive.reset,
          };

    useEffect(() => {
        if (archive.isSuccess || unarchive.isSuccess) {
            data.reset();
            onClose();
        }
    }, [archive.isSuccess, unarchive.isSuccess]);

    return (
        <>
            <Tooltip label={data.heading}>
                <IconButton
                    variant="ghost"
                    colorScheme="red"
                    aria-label={data.heading}
                    icon={data.icon}
                    onClick={() => {
                        analytics.event(data.heading, 'Modal opened', 'Button clicked');
                        onOpen();
                    }}
                />
            </Tooltip>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {data.heading}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <BodyDark>
                                {interview?.name} for {interview?.position}
                            </BodyDark>
                            <BodyText marginTop="1em">{data.question}</BodyText>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                CANCEL
                            </Button>
                            <Button
                                ml="4"
                                colorScheme={'red'}
                                onClick={data.onClick}
                                isLoading={data.isLoading}
                                loadingText={data.loading}
                            >
                                {data.button}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default ArchiveInterview;
