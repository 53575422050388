import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { FiDownload } from 'react-icons/fi';

import Button from '../../components/Button';
import { useIsMobile } from '../../utils/hooks';
import Input from '../../components/Form/Input';
import analytics from '../../utils/analytics';

const candidateFields = [
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'email' },
    { label: 'Position', value: 'position' },
    { label: 'Status', value: 'status' },
    { label: 'Created At', value: 'createdAt' },
    { label: 'Hiring Manager', value: 'hiringManager' },
    { label: 'Verdict', value: 'verdict' },
    { label: 'Rating', value: 'averageRating' },
    { label: 'Total ratings', value: 'totalRatings' },
    { label: 'Public Link', value: 'publicLink' },
];

const ExportInterviews = ({ interviews }: any) => {
    const isMobile = useIsMobile();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [name, setName] = useState('sieve_interviews');

    const exportExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Sheet 1');

        // Add column names
        sheet.columns = candidateFields.map((f) => ({ header: f.label, key: f.value, width: 24 }));

        // Add rows
        interviews?.map((c: any) =>
            sheet.addRow({
                ...c,
                publicLink: `${window.location.origin}/candidates/evaluation/${c.id}/${c.companyId}/${c.publicSlug}`,
            }),
        );

        // Export xlsx
        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        const blob = new Blob([buffer], { type: fileType });

        await saveAs(blob, name + '.xlsx');

        analytics.event('Export Interview', 'Download', 'Button clicked');
        onClose();
    };

    return (
        <>
            <Button
                color="foreground"
                rightIcon={<FiDownload size="18" />}
                onClick={() => {
                    analytics.event('Export Interview', 'Modal opened', 'Button clicked');
                    onOpen();
                }}
            >
                EXPORT
            </Button>

            <Modal
                size={isMobile ? 'full' : 'xl'}
                onClose={onClose}
                isOpen={isOpen}
                motionPreset="slideInBottom"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Export {interviews.length} interviews</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            name="name"
                            placeholder="File name"
                            label="File name"
                            value={name} // prev value shows if undefined
                            onChange={(e) => setName(e.target.value)}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button color="foreground" onClick={onClose}>
                            CANCEL
                        </Button>
                        <Button
                            disabled={!name}
                            color="secondary"
                            rightIcon={<FiDownload size="18" />}
                            onClick={exportExcel}
                        >
                            DOWNLOAD
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ExportInterviews;
