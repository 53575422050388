import axios from 'axios';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorType } from '../../types/global';
import { Company, Permission } from '../../types/schema';
import useAxios from './useAxios';

const useCompany = () => {
    const [errors, setErrors] = useState<ErrorType>({});
    const { api } = useAxios();

    const registerCompany = async (companyData: {
        name: string;
        jobTitle: string;
        themeColor: string;
        file?: File;
    }): Promise<Company> => {
        const { file, ...createData } = companyData;
        const { data } = await api.post('/companies/create', {
            ...createData,
            fileType: file?.type.split('/')[1],
        });

        const { company, uploadUrl, path } = data;
        if (uploadUrl && file) {
            await axios.put(uploadUrl, file, {
                headers: { 'Content-Type': file.type },
            });

            await api.put('/companies/confirmLogoUpload', {
                companyId: company.id,
                path,
            });
        }

        return company;
    };

    const updateCompany = async (companyData: {
        companyId: number;
        name: string;
        jobTitle: string;
        themeColor: string;
    }): Promise<Company> => {
        const { data } = await api.post('/companies/update', companyData);
        return data.updatedCompany;
    };

    const uploadCompanyLogo = async (uploadData: {
        file: File;
        companyId: number;
    }): Promise<Company> => {
        const { file, companyId } = uploadData;

        const { data: uploadUrlData } = await api.get('/companies/uploadLogoUrl', {
            params: {
                fileType: file.type.split('/')[1],
                companyId: companyId,
            },
        });

        const { uploadUrl, path } = uploadUrlData;

        await axios.put(uploadUrl, file, {
            headers: { 'Content-Type': file.type },
        });

        const { data: confirmUploadData } = await api.put('/companies/confirmLogoUpload', {
            companyId,
            path,
        });
        return confirmUploadData.updatedCompany;
    };

    const queryClient = useQueryClient();

    const register = useMutation(registerCompany, {
        onMutate: () => setErrors({}),
        onError: (error: any) => setErrors(error.response.data),
    });

    const update = useMutation(updateCompany, {
        onMutate: () => setErrors({}),
        onSuccess: (company) => {
            queryClient.setQueryData('me', (data: any) => {
                const permissions = data.user.permissions.map((per: Permission) => {
                    if (per.companyId !== company.id) return per;
                    return { ...per, company };
                });

                return { ...data, user: { ...data.user, permissions } };
            });

            toast.success('Changes saved');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const uploadLogo = useMutation(uploadCompanyLogo, {
        onMutate: () => setErrors({}),
        onSuccess: (company) => {
            queryClient.setQueryData('me', (data: any) => {
                const permissions = data.user.permissions.map((per: Permission) => {
                    if (per.companyId !== company.id) return per;
                    return { ...per, company };
                });

                return { ...data, user: { ...data.user, permissions } };
            });

            toast.success('Logo image Updated');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    return {
        errors,
        setErrors,
        register,
        update,
        uploadLogo,
    };
};

export default useCompany;
