import { QuestionType } from './../types/schema.d';

export const DEMO_COMPANY = {
    id: 'company1',
    name: 'Coffee Co.',
    logo: 'https://png.pngtree.com/element_our/png_detail/20180912/coffee-time-png_91570.jpg',
};

export const DEMO_POSITION = {
    id: 'position1',
    name: 'Night Auditor',
    description:
        'We’re looking for a Night Auditor to assist guests with their overnight requests and balance accounts from the day shift. Night Auditor responsibilities include checking in guests, handling their requests and taking reservations. The Night Auditor also reconciles all accounts, processes invoices and prepares paychecks. Ultimately, you will provide excellent customer service to guests and keep the front desk and accounting operations running smoothly.',
    resposibilities: `Check in guests, answer phones and take reservations
        Respond to guest complaints, requests and emergencies
        Process invoices, post checks to vendors and distribute employee checks
        Reconcile accounts
        Balance the cash drawer and log receipts
        Investigate and resolve out-of-balance accounts
        Keep accurate financial records and ledgers
        Help prepare for forecasts and audits`,
    requirements: `Proven experience as a Night Auditor or in a similar role, preferably in the hospitality industry
        Experience with accounting and facilities management software
        Excellent math skills
        An eye for detail
        Good problem-solving skills
        Strong customer service skills
        The ability to multitask
        Strong written and verbal communication skills
        Availability to work overnight
        BSc in accounting, finance or relevant field`,
    companyId: DEMO_COMPANY.id,
};

export const DEMO_QUESTION = {
    id: 'question1',
    positionId: DEMO_POSITION.id,
    type: QuestionType.TEXT,
    title: `Which is the most challenging project you've worked on?`,
    description: `Let us know the challenges you faced and how you resolved them.`,
};

export const DEMO_USER = {
    id: 'user1',
    name: 'Some Owner',
    email: 'some@email.com',
    companyIds: [DEMO_COMPANY.id],
};

export const DEMO_CANDIDATE = {
    id: 'user1',
    name: 'Some Candidate',
    email: 'some@email.com',

    status: 'invited',
    position: DEMO_POSITION,
    date: '2022-02-28T17:40:05.350Z',

    responses: [
        {
            id: 1,
            questionId: DEMO_QUESTION.id,
            displayQuestion: DEMO_QUESTION,
            orderNumber: 0,
            file: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
        },
        {
            id: 2,
            questionId: DEMO_QUESTION.id,
            displayQuestion: DEMO_QUESTION,
            orderNumber: 1,
            file: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
        },
        {
            id: 3,
            questionId: DEMO_QUESTION.id,
            displayQuestion: DEMO_QUESTION,
            orderNumber: 2,
            file: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
        },
        {
            id: 4,
            questionId: DEMO_QUESTION.id,
            displayQuestion: DEMO_QUESTION,
            orderNumber: 3,
            file: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
        },
        {
            id: 5,
            questionId: DEMO_QUESTION.id,
            displayQuestion: DEMO_QUESTION,
            orderNumber: 2,
            file: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
        },
    ],
};

export const DEMO_PERMISSION = {
    id: 'permission1',
    userId: DEMO_USER.id,
    companyId: DEMO_COMPANY.id,
    role: 'admin',
};

export const DEMO_INTERVIEW = {
    id: 'interview1',
    userId: DEMO_CANDIDATE.id,
    positionId: DEMO_POSITION.id,
    assignedToId: DEMO_USER.id,
    status: 'invited',
    verdict: 'pending',
    date: '2022-02-28T17:40:05.350Z',
};

export const DEMO_REPONSE = {
    id: 'response1',
    interviewId: DEMO_INTERVIEW.id,
    questionId: DEMO_QUESTION.id,
    videoLink:
        'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4',
    orderNumber: 0,
};
