import { useEffect, useState } from 'react';
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { CommentType } from '../../types/global';
import { InterviewCandidate } from '../../types/schema';
import useAxios from './useAxios';

interface CreateData {
    name?: string;
    email?: string;
    companyId?: number;
    positionId?: number;
    deadline?: string | null;
}

const paramString = (key: string, values: Array<any>): string => {
    let str: string = ``;

    if (values.length === 0) return str;

    values.forEach((value) => {
        if (value !== undefined && value !== null) {
            if (!str) str = `${key}=${value}`;
            else str = str + `&${key}=${value}`;
        }
    });

    return str;
};

const matchOnlyArchivedQuery = (query: any) => {
    if (query.queryKey[0] === 'candidate-list') {
        if (query.queryKey[1] && (query.queryKey[1] as string).includes('onlyArchived')) {
            return true;
        }
    }

    return false;
};

const notMatchOnlyArchivedQuery = (query: any) => {
    if (query.queryKey[0] === 'candidate-list') {
        if (query.queryKey[1] && (query.queryKey[1] as string).includes('onlyArchived')) {
            return false;
        }
        return true;
    }
    return false;
};

const useManyInterviews = (companyId: string | undefined) => {
    const [errors, setErrors] = useState<{ [key: string]: any }>({});

    const [filterParams, setFilterParams] = useState<{ [key: string]: any }>({});
    const [filterString, setFilterString] = useState<string>();

    const { api, isAuthenticated } = useAxios();

    useEffect(() => {
        if (!companyId) return;

        let arrParams = ``,
            strFilters = {};

        Object.keys(filterParams).map((key) => {
            if (Array.isArray(filterParams[key])) {
                if (arrParams === ``) {
                    arrParams = paramString(key, filterParams[key]);
                } else {
                    arrParams += '&' + paramString(key, filterParams[key]);
                }
            } else {
                if (filterParams[key] !== undefined && filterParams[key] !== null)
                    strFilters = { ...strFilters, [key]: filterParams[key] };
            }
        });

        const strParams = new URLSearchParams({ companyId, ...strFilters });
        const queryParams = strParams + (arrParams !== `` ? '&' + arrParams : ``);

        setFilterString(queryParams);
    }, [filterParams, companyId]);

    const getManyInterview = async ({
        queryKey: [_, queryParams],
    }: QueryFunctionContext): Promise<{ interviews: Array<InterviewCandidate>; count: number }> => {
        const { data } = await api.get(`/interviews/getMany?` + queryParams);
        return data;
    };

    const { data: responseData, isLoading } = useQuery({
        queryKey: ['candidate-list', filterString],
        queryFn: getManyInterview,
        enabled: isAuthenticated && !!filterString && !!companyId && !!filterString,
        onError: (error: any) => setErrors(error.response.data),
    });

    const getInterviewStats = async ({
        queryKey: [_, companyId],
    }: QueryFunctionContext): Promise<Array<{ status: string; _count: { status: number } }>> => {
        const { data } = await api.get(`/interviews/getStats`, { params: { companyId } });
        return data.stats;
    };

    const { data: statsData, isLoading: isLoadingStats } = useQuery({
        queryKey: ['candidate-stats', companyId],
        queryFn: getInterviewStats,
        enabled: isAuthenticated,
    });

    const createInterview = async (createData: CreateData): Promise<InterviewCandidate> => {
        const { data } = await api.post('/interviews/create', createData);
        return data.createdInterview;
    };

    const createManyInterview = async (
        createData: CreateData[],
    ): Promise<Array<InterviewCandidate>> => {
        const { data } = await api.post('/interviews/createMany', {
            companyId: parseInt(companyId as string),
            interviews: createData,
        });
        return data.createdInterviews;
    };

    const resendInterview = async (
        resendData: CreateData & { interviewId: number; publicSlug: string },
    ): Promise<InterviewCandidate> => {
        const { data } = await api.put('/interviews/resend', resendData);
        return data.updatedInterview;
    };

    const archiveInterview = async (archiveData: {
        interviewId: number;
        publicSlug: string;
    }): Promise<InterviewCandidate> => {
        const { data } = await api.put('/interviews/archive', {
            companyId: parseInt(companyId as string),
            ...archiveData,
        });
        return data.updatedInterview;
    };

    const archiveManyInterviews = async (
        interviews: Array<{
            interviewId: number;
            publicSlug: string;
        }>,
    ): Promise<Array<InterviewCandidate>> => {
        const { data } = await api.put('/interviews/archiveMany', {
            companyId: parseInt(companyId as string),
            interviews,
        });
        return data.updatedInterviews;
    };

    const unarchiveInterview = async (unarchiveData: {
        interviewId: number;
        publicSlug: string;
    }): Promise<InterviewCandidate> => {
        const { data } = await api.put('/interviews/unarchive', {
            companyId: parseInt(companyId as string),
            ...unarchiveData,
        });
        return data.updatedInterview;
    };

    const unarchiveManyInterviews = async (
        interviews: Array<{
            interviewId: number;
            publicSlug: string;
        }>,
    ): Promise<Array<InterviewCandidate>> => {
        const { data } = await api.put('/interviews/unarchiveMany', {
            companyId: parseInt(companyId as string),
            interviews,
        });
        return data.updatedInterviews;
    };

    const assign = async (assignData: {
        assignedToId: number;
        interviewId: number;
        publicSlug: string;
    }): Promise<{ createdComment: CommentType; updatedInterview: InterviewCandidate }> => {
        const { data } = await api.put('/comments/assign', {
            companyId: parseInt(companyId as string),
            ...assignData,
        });
        return data;
    };

    const assignMany = async (
        assignManyData: Array<{
            assignedToId: number;
            interviewId: number;
            publicSlug: string;
        }>,
    ): Promise<Array<{ createdComment: CommentType; updatedInterview: InterviewCandidate }>> => {
        const { data } = await api.put('/comments/assign', {
            companyId: parseInt(companyId as string),
            interviews: assignManyData,
        });
        return data;
    };

    const queryClient = useQueryClient();

    const create = useMutation(createInterview, {
        onSuccess: (createdInterview) => {
            /** Append the created Interview */
            queryClient.setQueriesData('candidate-list', (data: any) => {
                if (!data) return data;

                const interviews = [createdInterview, ...data.interviews];
                return { ...data, interviews };
            });
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const createMany = useMutation(createManyInterview, {
        onMutate: () => setErrors({}),
        onSuccess: (createdInterviews) => {
            /** Append the created Interviews */
            queryClient.setQueriesData('candidate-list', (data: any) => {
                if (!data) return data;

                const interviews = [...createdInterviews, ...data.interviews];
                return { ...data, interviews };
            });
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const resend = useMutation(resendInterview, {
        onMutate: () => setErrors({}),
        onSuccess: (updatedInterview) => {
            queryClient.setQueriesData('evaluation', (interview: any) => {
                if (!interview) return interview;
                if (interview.id !== updatedInterview.id) return interview;

                return { ...interview, ...updatedInterview };
            });

            queryClient.setQueriesData('candidate-list', (data: any) => {
                if (!data) return data;

                const interviews = data.interviews.map((int: InterviewCandidate) => {
                    if (int.id !== updatedInterview.id) return int;
                    return { ...int, ...updatedInterview };
                });

                return { ...data, interviews };
            });
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const archive = useMutation(archiveInterview, {
        onMutate: () => setErrors({}),
        onSuccess: (updatedInterview) => {
            queryClient.setQueriesData('evaluation', (interview: any) => {
                if (!interview) return interview;
                if (interview.id !== updatedInterview.id) return interview;

                return { ...interview, ...updatedInterview };
            });

            queryClient.setQueriesData({ predicate: notMatchOnlyArchivedQuery }, (data: any) => {
                if (!data) return data;
                console.log('D_77', data);

                const interviews = data.interviews.filter((int: InterviewCandidate) => {
                    return int.id !== updatedInterview.id;
                });

                return { ...data, interviews };
            });

            queryClient.invalidateQueries({ predicate: matchOnlyArchivedQuery });
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const archiveMany = useMutation(archiveManyInterviews, {
        onMutate: () => setErrors({}),
        onSuccess: (updatedInterviews) => {
            queryClient.setQueriesData('evaluation', (interview: any) => {
                if (!interview) return interview;

                const updatedInterview = updatedInterviews.find(({ id }) => id === interview.id);

                if (!updatedInterview) return interview;
                return { ...interview, ...updatedInterview };
            });

            queryClient.setQueriesData({ predicate: notMatchOnlyArchivedQuery }, (data: any) => {
                if (!data) return data;

                const interviews = data.interviews.filter((int: InterviewCandidate) => {
                    return updatedInterviews.find(({ id }) => id === int.id);
                });

                return { ...data, interviews };
            });

            queryClient.invalidateQueries({ predicate: matchOnlyArchivedQuery });
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const unarchive = useMutation(unarchiveInterview, {
        onMutate: () => setErrors({}),
        onSuccess: (updatedInterview) => {
            queryClient.setQueriesData('evaluation', (interview: any) => {
                if (!interview) return interview;
                if (interview.id !== updatedInterview.id) return interview;

                return { ...interview, ...updatedInterview };
            });

            queryClient.setQueriesData({ predicate: matchOnlyArchivedQuery }, (data: any) => {
                if (!data) return data;

                const interviews = data.interviews.filter((int: InterviewCandidate) => {
                    return int.id !== updatedInterview.id;
                });

                return { ...data, interviews };
            });

            queryClient.invalidateQueries({ predicate: notMatchOnlyArchivedQuery });
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const unarchiveMany = useMutation(unarchiveManyInterviews, {
        onMutate: () => setErrors({}),
        onSuccess: (updatedInterviews) => {
            queryClient.setQueriesData('evaluation', (interview: any) => {
                if (!interview) return interview;

                const updatedInterview = updatedInterviews.find(({ id }) => id === interview.id);

                if (!updatedInterview) return interview;
                return { ...interview, ...updatedInterview };
            });

            queryClient.setQueriesData({ predicate: matchOnlyArchivedQuery }, (data: any) => {
                if (!data) return data;

                const interviews = data.interviews.filter((int: InterviewCandidate) => {
                    return updatedInterviews.find(({ id }) => id === int.id);
                });

                return { ...data, interviews };
            });

            queryClient.invalidateQueries({ predicate: notMatchOnlyArchivedQuery });
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const assignMember = useMutation(assign, {
        onSuccess: ({ updatedInterview, createdComment }) => {
            console.log('D_419 A');
            queryClient.setQueriesData('evaluation', (interview: any) => {
                if (!interview) return interview;
                if (interview.id !== updatedInterview.id) return interview;

                /**
                 * Add the created comment and updated data
                 */
                const comments = [createdComment, ...interview.comments];
                return { ...interview, ...updatedInterview, comments };
            });

            queryClient.setQueriesData('candidate-list', (data: any) => {
                if (!data) return data;

                /**
                 * Add the created comment and updated data
                 */
                const interviews = data.interviews.map((int: any) => {
                    if (updatedInterview.id === int.id) {
                        return { ...int, ...updatedInterview };
                    }

                    return int;
                });

                return { ...data, interviews };
            });
        },
    });

    const assignManyMember = useMutation(assignMany, {
        onSuccess: (interviews) => {
            console.log('D_419 A');
            queryClient.setQueriesData('evaluation', (interview: any) => {
                if (!interview) return interview;

                /** Find if update is required for this interview */
                const update = interviews.find(({ id }: any) => id === interview.id);
                if (!update) return interview;

                /** Add the created comment and updated data */
                const { updatedInterview, createdComment } = update;
                const comments = [createdComment, ...interview.comments];
                return { ...interview, ...updatedInterview, comments };
            });

            queryClient.setQueriesData('candidate-list', (data: any) => {
                if (!data) return data;

                const interviews = data.interviews.map((int: any) => {
                    /** Find if update is required for this interview */
                    const update = interviews.find(({ id }: any) => id === int.id);
                    if (!update) return int;

                    /** Update data */
                    const { updatedInterview } = update;
                    if (updatedInterview.id === int.id) {
                        return { ...int, ...updatedInterview };
                    }

                    return int;
                });

                return { ...data, interviews };
            });
        },
    });

    return {
        isLoading,
        interviews: responseData?.interviews || [],
        count: responseData?.count,
        createMany,
        filterParams,
        setFilterParams,
        errors,
        setErrors,
        create,
        resend,
        archive,
        archiveMany,
        assignMember,
        assignManyMember,
        statsData,
        isLoadingStats,
        unarchive,
        unarchiveMany,
    };
};

export default useManyInterviews;
