import React from 'react';
import styled from 'styled-components';

const StyledVideo = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;
`;

const StyledIframe = styled.iframe`
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
`;

const YoutubeEmbed = ({ embedId }: { embedId: string }) => (
    <StyledVideo>
        <StyledIframe
            width="853"
            height="480"
            src={`https://www.youtube-nocookie.com/embed/${embedId}?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </StyledVideo>
);

export default YoutubeEmbed;
