import { MOBILE_WIDTH } from './../utils/constants';

const navBarHeight = 85;
const maxWidth = 900;

const baseFontSize = 16;
export const scalingSize = (size: number) => `${size / baseFontSize}rem`;

const dimensions = {
    maxWidthNumeric: maxWidth,
    maxWidth: scalingSize(maxWidth),

    mobileWidth: `${MOBILE_WIDTH}px`,

    navBarHeightNumeric: navBarHeight,
    navBarHeight: scalingSize(navBarHeight),

    borderRadius: '5px',
};

export default dimensions;
