import { LockOutlined } from '@ant-design/icons';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AppLogo from '../../components/AppLogo';
import { BodyDark, BodyText } from '../../components/Fonts';

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: #ddd;
`;

const Container = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NavContainer = styled.div`
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5%;
    padding-right: 5%;
    border-bottom: 1px solid #ddd;
`;

const PageError: React.FC = () => {
    return (
        <Main>
            <NavContainer>
                <AppLogo color="black" />
            </NavContainer>
            <Main>
                <Container>
                    <LockOutlined style={{ fontSize: 40, marginBottom: 5 }} />
                    <BodyDark>{`The Interview link is not public`}</BodyDark>
                    <Line />
                    <BodyText textAlign="center">{`Not to worry, you still have access to the interview. Just login.`}</BodyText>
                    <Link to="/login" style={{ marginTop: '2em' }}>
                        <Box color="primary.main" textAlign="center">
                            Login Here
                        </Box>
                    </Link>
                </Container>
            </Main>
        </Main>
    );
};

export default PageError;
