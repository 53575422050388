import { Box, Flex, Button, Heading, Text, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import NavBarPromo from '../../components/NavBarPromo';
import YoutubeEmbed from '../../components/YoutubeEmbed';
import StartCreatingInterviewsButton from '../../components/NewWorkspaceButton';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';
import ModuleScrollView from '../../components/ModuleScrollView';

export const MembersPromoCard = () => {
    return (
        <Flex
            position="relative"
            bg="foreground.main"
            p={[6, 6, 12]}
            m={[4, 4, 8, 8, 8, 12, 16]}
            maxW="60rem"
            borderRadius="12"
        >
            <Box>
                <Heading color="primaryLight.600" maxW="sm">
                    Share responses with your hiring team
                </Heading>

                <Text fontSize={['lg', 'xl']} mt={[6, 6, 12]} maxW="md">
                    Collaborate with internal and external teams to give feedback and review the
                    candidate
                </Text>

                <Image
                    src="/feature_image3.jpg"
                    maxH={['sm', 'sm']}
                    display={['inherit', 'inherit', 'none']}
                    boxShadow="5px 5px 18px 0px rgba(0,0,0,0.1)"
                    mt="10"
                />

                <StartCreatingInterviewsButton text="Start Creating Interviews" size="lg" mt="16" />
            </Box>

            <Image
                position="absolute"
                right="-8%"
                src="/feature_image3.jpg"
                maxH={['xs', 'xs', 'xs', 'md', 'lg']}
                display={['none', 'none', 'inherit']}
                boxShadow="5px 5px 18px 0px rgba(0,0,0,0.1)"
                borderRadius="8"
            />
        </Flex>
    );
};

export default () => {
    return (
        <>
            <NavBarPromo />

            <ModuleScrollView>
                <ModuleMaxWidthContainer maxWidth={1200}>
                    <MembersPromoCard />

                    <Text fontSize={['md', 'md', 'md', 'lg']} mt="44" ml={[6, 6, 12]}>
                        Watch Tutorial Videos To Learn More
                    </Text>

                    <Box
                        ml={[4, 4, 8, 8, 8, 12, 16]}
                        mt="6"
                        mb="20"
                        maxW="lg"
                        borderRadius="16"
                        overflow="hidden"
                    >
                        <YoutubeEmbed embedId="rEmRRxEcmwo" />
                    </Box>
                </ModuleMaxWidthContainer>
            </ModuleScrollView>
        </>
    );
};
