import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import dimensions from '../../styles/dimensions';
import fonts from '../../styles/fonts';
import { useColors } from '../../styles/colors';

const modules = {
    toolbar: [
        [{ header: [2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        // ['link', 'image'],
        ['code-block'],
        // ['clean'],
    ],
};

const StyledReactQuill = styled(ReactQuill)<{ readonly?: boolean; bottomToolbar?: boolean }>`
    display: flex;
    flex-direction: ${(props) => (props.bottomToolbar ? 'column-reverse' : 'column')};

    .ql-toolbar {
        border-width: 0;
    }

    .ql-container {
        /* ${fonts.body} */
        border-width: 0;
        flex: 1;
    }

    .ql-editor {
        padding: ${(props) => props.readOnly && 0};
    }
`;

const RichTextInput = ({ readOnly, bottomToolbar, style, onChange, ...otherProps }: any) => {
    const colors = useColors();

    useEffect(() => {
        // Disable tab index for toolbar
        var tabHideEls = document.querySelectorAll(
            '.ql-picker-label, .ql-bold, .ql-italic, .ql-underline, .ql-list, .ql-code-block',
        );
        tabHideEls.forEach((item) => {
            item.setAttribute('tabindex', '-1');
        });
    }, []);

    return (
        <StyledReactQuill
            theme={!readOnly && 'snow'}
            readOnly={readOnly}
            bottomToolbar={bottomToolbar}
            style={{
                ...fonts.body,
                minHeight: !readOnly && '8em',
                backgroundColor: !readOnly && colors.foreground,
                borderRadius: dimensions.borderRadius,
                padding: !readOnly && '0em',
                margin: !readOnly && '0.5em',
                ...style,
            }}
            modules={readOnly ? { toolbar: [] } : modules}
            onChange={(value) => onChange && onChange(value === '<p><br></p>' ? '' : value)}
            {...otherProps}
        />
    );
};

export default RichTextInput;
