import { useState } from 'react';
import styled from 'styled-components';

import Button from '../../components/Button';
import Form from '../../components/Form/FormContainer';
import Input from '../../components/Form/Input';
import dimensions from '../../styles/dimensions';
import { Image } from '@chakra-ui/react';
import { BodyText, Heading } from '../../components/Fonts';
import useUser from '../../api/hooks/useUser';
import MessageBox from '../../components/MessageBox';
import analytics from '../../utils/analytics';

const LoginContainer = styled.div`
    height: 100%;

    display: flex;
    align-items: center;
`;

const Art = styled.div`
    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        display: none;
    }

    height: 100%;
    width: 10%;
    min-width: 120px;
    max-width: 300px;

    background-color: ${({ theme }) => theme.secondary};
`;

const LoginBox = styled.div`
    width: 60%;
    min-width: 300px;
    max-width: 420px;

    display: flex;
    flex-direction: column;

    margin: 0 auto;
`;

export default () => {
    const [email, setEmail] = useState<string>();
    const { forgotPassword, errors } = useUser();

    return (
        <LoginContainer>
            <Art />

            <LoginBox>
                <Form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        analytics.event('Forgot Password', 'Recieve Email', 'Button clicked');
                        forgotPassword.mutate(email);
                    }}
                >
                    <Heading marginTop="3em">Forgot Password? No worries.</Heading>
                    <BodyText marginTop="1em" marginBottom="3em">
                        Enter your email and we'll send a password reset link to you. There you can
                        set a new password and be on your way :)
                    </BodyText>
                    <Input
                        name="email"
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        error={errors.email}
                    />

                    {!forgotPassword.isSuccess ? (
                        <Button
                            disabled={!email}
                            isLoading={forgotPassword.isLoading}
                            type="submit"
                        >
                            RECIEVE RESET LINK
                        </Button>
                    ) : (
                        <MessageBox
                            status="success"
                            title="Link Sent"
                            description="Password reset link has been set to your email Id"
                        ></MessageBox>
                    )}
                </Form>
            </LoginBox>

            <Image
                src="/12.svg"
                position="absolute"
                maxH={['15rem', '20rem', '22rem']}
                bottom="0"
                right="0"
                opacity="0.9"
            />
        </LoginContainer>
    );
};
