import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import usePermissions from '../../api/hooks/usePermissions';

import Button from '../../components/Button';
import ErrorString from '../../components/ErrorString';
import { BodyText } from '../../components/Fonts';
import Form from '../../components/Form/FormContainer';
import Input from '../../components/Form/Input';
import GoogleAuth from '../Login/GoogleAuth';
import LinkedInAuth from '../Login/LinkedInAuth';
import Loading from '../../components/Loading';
import { useAuthFunctions } from '../../context/auth';
import analytics from '../../utils/analytics';

const maxBoxWidth = 420;

const Container = styled.div`
    height: 100%;

    display: flex;
    align-items: center;
`;

const RegisterBox = styled.div`
    width: 60%;
    min-width: 300px;
    max-width: ${maxBoxWidth}px;

    display: flex;
    flex-direction: column;

    margin: 0 auto;
`;

const Separator = styled.div`
    height: 1px;
    min-width: 200px;

    background: ${({ theme }) => theme.foregroundDark};

    margin: 2em 0em;
`;

export default () => {
    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const { permissionId, slug } = useParams();
    const { permission, errors } = usePermissions({ permissionId, slug });

    const { register } = useAuthFunctions();
    const { mutate, isLoading } = register;

    if (!permission) return <Loading />;

    return (
        <>
            <Box bg="primary.50" borderRadius="md" p="6" mt="4">
                <BodyText>
                    No account found for <strong>{permission.user.email}</strong>
                    <br />
                    Please register this email to accept the invite
                </BodyText>
            </Box>

            <Box mt="16" width="100%" maxW="sm">
                <FlexView column width="100%" grow>
                    <GoogleAuth />
                    <LinkedInAuth />
                </FlexView>

                <Separator />

                <Input
                    name="name"
                    placeholder="Full Name"
                    onChange={(e) => setName(e.target.value)}
                    error={errors.name}
                />

                {/* Keeping one field outside form prevents chrome autocomplete */}
                <Form>
                    <Input
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                        error={errors.password}
                    />

                    <Button
                        isLoading={isLoading}
                        onClick={() => {
                            analytics.event('Member Invite', 'New Registration', 'Button clicked');
                            mutate({ name, email: permission.user.email, password });
                        }}
                    >
                        REGISTER
                    </Button>
                </Form>
            </Box>
        </>
    );
};
