import { useEffect, useState } from 'react';
import '@reach/dialog/styles.css';
import FlexView from 'react-flexview/lib';
import { useParams } from 'react-router-dom';

import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';

import Button from '../../components/Button';
import { BodyLight } from '../../components/Fonts';
import Input from '../../components/Form/Input';
import Editable from '../../components/Form/Editable';
import Select from '../../components/Form/Select';

import { ROLE_OPTIONS } from '../../utils/constants';
import useWorkspace from '../../api/hooks/useWorkspace';
import ErrorString from '../../components/ErrorString';
import analytics from '../../utils/analytics';
import { useManyPositions } from '../../api/hooks/usePositions';
import { Position } from '../../types/schema';

const AddMember = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { companyId } = useParams();

    const [email, setEmail] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const [jobTitle, setJobTitle] = useState<string>('');
    const [permittedPositions, setPermittedPositions] = useState<Array<number>>([]);
    const [message, setMessage] = useState<string>('');

    const closeModal = () => {
        setErrors({});

        setEmail('');
        setRole('');
        setJobTitle('');
        setMessage('');

        onClose();
    };

    const { addMember, errors, setErrors } = useWorkspace(companyId);
    const { mutate, isSuccess, isLoading, reset } = addMember;

    const { positions } = useManyPositions(companyId);
    const positionOptions = [
        { label: 'All current and future positions', value: -1 },
        ...positions.map((p: Position) => ({
            label: p.name,
            value: p.id,
        })),
    ];

    useEffect(() => {
        if (
            permittedPositions.length > 1 &&
            permittedPositions[permittedPositions.length - 1] === -1 // if last selected is all option, remove everything else
        )
            setPermittedPositions([-1]);

        if (
            permittedPositions.find((p) => p === -1) &&
            permittedPositions[permittedPositions.length - 1] !== -1 // else if all was present and something else is selected, remove all
        )
            setPermittedPositions(permittedPositions.filter((p) => p !== -1));
    }, [permittedPositions]);

    useEffect(() => {
        if (isSuccess) {
            reset();
            closeModal();
        }
    }, [isSuccess]);

    const createMember = () => {
        analytics.event('Add Member', 'Invite sent', 'Button clicked');
        mutate({
            jobTitle: jobTitle as string,
            role: role as string,
            email: email as string,
            positions: permittedPositions.length
                ? permittedPositions.includes(-1)
                    ? []
                    : permittedPositions
                : null,
            message,
        });
    };

    if (!companyId) return <div>No companyId</div>;

    console.log('D_3', role);

    return (
        <>
            <Button
                className="new-member-button" // for react-joyride
                onClick={() => {
                    analytics.event('Add Member', 'Modal opened', 'Button clicked');
                    onOpen();
                }}
            >
                ADD MEMBER
            </Button>

            <Modal isCentered onClose={closeModal} isOpen={isOpen} motionPreset="slideInBottom">
                <ModalOverlay />
                <ModalContent margin="1.5em">
                    <ModalHeader>
                        Invite Member
                        <BodyLight>They'll receive an email invite</BodyLight>
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <FlexView column>
                            <Input
                                name="email"
                                type="email"
                                value={email}
                                label="Email Address"
                                placeholder="Email Address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <ErrorString error={errors.email} />

                            <Input
                                name="jobTitle"
                                label="Job Title"
                                placeholder="Recruiter"
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                            />
                            <ErrorString error={errors.jobTitle} />

                            <Select
                                label="Permission"
                                placeholder="Select access level"
                                options={ROLE_OPTIONS.filter((o) => o.value !== 'OWNER')}
                                onChange={(r: typeof ROLE_OPTIONS[0]) => {
                                    setRole(r.value);
                                    // setPermittedPositions(positionOptions.map((o: any) => o.value));
                                }}
                            />
                            <ErrorString error={errors.role} />

                            {['MEMBER', 'REVIEWER'].includes(role) && (
                                <>
                                    <Select
                                        isMulti
                                        label="Allowed Positions"
                                        placeholder="Select positions"
                                        options={positionOptions}
                                        value={positionOptions.filter((o) =>
                                            permittedPositions.includes(o.value),
                                        )}
                                        onChange={(arr: any) => {
                                            setPermittedPositions(arr.map((o: any) => o.value));
                                        }}
                                    />
                                    <ErrorString error={errors.positions} />
                                </>
                            )}
                        </FlexView>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={createMember}
                            isLoading={isLoading}
                            loadingText="SENDING"
                            marginTop="1em"
                        >
                            SEND INVITE
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddMember;
