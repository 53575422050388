import { DatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import dimensions from '../../styles/dimensions';
import { useColors } from '../../styles/colors';
import { useIsMobile } from '../../utils/hooks';
import FormField from './FormField';
import { fontSizes } from '../../styles/fonts';

// To have calendar in column, but doesnt work right now
const StyledRangePicker = styled(DatePicker.RangePicker)`
    background-color: red;

    .ant-picker-input > input {
        /* font-size: ${fontSizes.md}; */ // Date got cut
    }
    .ant-picker-panel {
        flex-direction: column;
    }
    .ant-calendar-range {
        width: 320px;
    }
    .ant-calendar-range-part {
        width: 100%;
    }
`;

const DateRangePicker = (props: any) => {
    const colors = useColors();
    const isMobile = useIsMobile();

    return (
        <FormField label={props.label} error={props.error}>
            <StyledRangePicker
                style={{
                    backgroundColor: colors.foreground,
                    padding: '0.98em 0.75em',
                    borderWidth: 0,
                    borderRadius: dimensions.borderRadius,
                }}
                popupStyle={{
                    zIndex: 9999,
                    width: isMobile && '280px',
                    flexDirection: 'column',
                }}
                ranges={{
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
                    'This Week': [moment().startOf('week'), moment().endOf('week')],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                    ],
                }}
                {...props}
            />
        </FormField>
    );
};

export default DateRangePicker;
