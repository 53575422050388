import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Flex,
    IconButton,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { BsArrowRepeat } from 'react-icons/bs';

import Input from '../../components/Form/Input';
import DatePicker from '../../components/Form/DatePicker';
import Select from '../../components/Form/Select';
import { useManyPositions } from '../../api/hooks/usePositions';
import moment from 'moment';
import useManyInterviews from '../../api/hooks/useManyInterviews';
import { toast } from 'react-toastify';
import analytics from '../../utils/analytics';

const ResendInterview = ({ interview, ...props }: any) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef<any>();

    const { companyId } = useParams();

    const { positions } = useManyPositions(companyId);
    const positionOptions = positions?.map((p) => ({ label: p.name, value: p.id })) || [];

    const { resend, errors } = useManyInterviews(companyId);

    const [email, setEmail] = useState<string | undefined>(interview?.candidate?.email);
    const [name, setName] = useState<string | undefined>(interview?.candidate?.name);
    const [deadline, setDeadline] = useState<string | undefined>(interview?.deadline);
    const [positionId, setPositionId] = useState<number | undefined>(interview?.positionId);

    const { mutate, isSuccess, isLoading, reset } = resend;

    useEffect(() => {
        if (isSuccess) {
            analytics.event('Resend Interview', 'Successfully resent', 'Button clicked');
            toast.success('Interview resent!');
            reset();
            onClose();
        }
    }, [isSuccess]);

    const resendInterview = () => {
        mutate({
            companyId: parseInt(companyId as string),
            publicSlug: interview.publicSlug,
            interviewId: interview.id,
            email,
            name,
            deadline,
            positionId,
        });
    };

    return (
        <>
            <Tooltip label="Resend Interview">
                <IconButton
                    variant="ghost"
                    aria-label="Resend interview"
                    icon={<RepeatIcon />}
                    onClick={() => {
                        analytics.event('Resend Interview', 'Modal opened', 'Button clicked');
                        onOpen();
                    }}
                    {...props}
                />
            </Tooltip>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Resend Interview
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Flex flexDir="column">
                                <Input
                                    disabled
                                    name="email"
                                    type="email"
                                    label="Email"
                                    placeholder="Email"
                                    defaultValue={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <div>{errors.email}</div>

                                <Input
                                    name="name"
                                    type="name"
                                    label="Name"
                                    placeholder="Name"
                                    defaultValue={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <div>{errors.name}</div>

                                <Select
                                    label="Position"
                                    placeholder="Position"
                                    options={positionOptions}
                                    defaultValue={positionOptions.find(
                                        (o) => o.value === positionId,
                                    )}
                                    onChange={(p: typeof positionOptions[0]) =>
                                        setPositionId(p.value)
                                    }
                                />

                                <DatePicker
                                    name="deadline"
                                    label="Deadline"
                                    placeholder="Deadline"
                                    minDate={moment()}
                                    value={deadline && moment(deadline)}
                                    onChange={(momentDate: moment.Moment) => {
                                        const date = momentDate
                                            ? moment(momentDate).endOf('day').format()
                                            : undefined;

                                        setDeadline(date);
                                    }}
                                />
                            </Flex>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                CANCEL
                            </Button>
                            <Button
                                ml="4"
                                colorScheme={'primary'}
                                onClick={resendInterview}
                                isLoading={isLoading}
                                loadingText="Sending"
                            >
                                RESEND
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default ResendInterview;
