import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    IconButton,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { AiFillDelete } from 'react-icons/ai';

import { CommentType } from '../../types/global';
import useInterviewEvaluation from '../../api/hooks/useInterviewEvaluation';
import analytics from '../../utils/analytics';

const ArchiveComment = ({ comment, ...props }: { comment: CommentType }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef<any>();

    const { companyId, interviewId, publicSlug } = useParams();
    const { deleteText } = useInterviewEvaluation({ companyId, interviewId, publicSlug });

    const deleteComment = () => {
        deleteText.mutate(comment.id);
    };

    useEffect(() => {
        if (deleteText.isSuccess) {
            analytics.event('Comments', 'Successfully deleted', 'Button clicked');
            deleteText.reset();
            onClose();
        }
    }, [deleteText.isSuccess]);

    return (
        <>
            <Tooltip label="Delete comment">
                <IconButton
                    aria-label="Delete question"
                    icon={<AiFillDelete size={14} />}
                    variant="link"
                    p="1"
                    // colorScheme="red"
                    size="sm"
                    onClick={() => {
                        analytics.event('Comments', 'Delete modal opened', 'Button clicked');
                        onOpen();
                    }}
                />
            </Tooltip>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete comment
                        </AlertDialogHeader>

                        <AlertDialogBody>Are you sure?</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                CANCEL
                            </Button>
                            <Button
                                ml="4"
                                colorScheme={'red'}
                                isLoading={deleteText.isLoading}
                                onClick={deleteComment}
                            >
                                DELETE
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default ArchiveComment;
