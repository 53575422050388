import {
    ArrowRightIcon,
    CheckIcon,
    SpinnerIcon,
    Search2Icon,
    CheckCircleIcon,
    ArrowForwardIcon,
} from '@chakra-ui/icons';
import { InterviewStatus } from './../types/schema.d';
export const __DEV__ = process.env && process.env.NODE_ENV === 'development';

export const MOBILE_WIDTH = 800;
export const QUERY_STALE_TIME = 1000 * 60;
export const QUERY_REFETCH_TRIES = 1;
export const QUERY_RETRY_INTERVAL = 500; // milliseconds

export const INTERVIEW_STATUSES: {
    [key in InterviewStatus]: { label: string; value: InterviewStatus; color: string; Icon: any };
} = {
    INVITED: {
        label: 'Invited',
        value: InterviewStatus.INVITED,
        color: '#fff',
        Icon: ArrowForwardIcon,
    },
    OPENED: {
        label: 'Opened',
        value: InterviewStatus.OPENED,
        color: '#fff',
        Icon: CheckIcon,
    },
    UNDERWAY: {
        label: 'Underway',
        value: InterviewStatus.UNDERWAY,
        color: '#fff3b0',
        Icon: SpinnerIcon,
    },
    REVIEW_PENDING: {
        label: 'Ready for Review',
        value: InterviewStatus.REVIEW_PENDING,
        color: '#f9c80e',
        Icon: Search2Icon,
    },
    // COMPLETED: {
    //     label: 'Completed',
    //     value: InterviewStatus.COMPLETED,
    //     color: '#37C99E',
    //     Icon: CheckCircleIcon,
    // },
    ACCEPTED: {
        label: 'Accepted',
        value: InterviewStatus.ACCEPTED,
        color: '#37C99E',
        Icon: CheckCircleIcon,
    },
    REJECTED: {
        label: 'Rejected',
        value: InterviewStatus.REJECTED,
        color: '#e56b6f',
        Icon: CheckCircleIcon,
    },
    // ARCHIVED: {
    //     label: 'Archived',
    //     value: InterviewStatus.ARCHIVED,
    //     color: '#748cab',
    //     Icon: CheckCircleIcon,
    // },
};

export const PENDING_INTERVIEW_STATUSES = [
    INTERVIEW_STATUSES.INVITED.value,
    INTERVIEW_STATUSES.OPENED.value,
    INTERVIEW_STATUSES.UNDERWAY.value,
];

export const ROLES = {
    OWNER: 'OWNER',
    ADMIN: 'ADMIN',
    MEMBER: 'MEMBER',
    REVIEWER: 'REVIEWER',
};

export const ROLE_OPTIONS = [
    {
        label: 'Owner - Create positions, send and evaluate interviews & add members',
        value: 'OWNER',
    },
    {
        label: 'Admin - Create positions, send and evaluate interviews & add members',
        value: 'ADMIN',
    },
    { label: 'Member - Send and evaluate interviews', value: 'MEMBER' },
    { label: 'Reviewer - Evaluate interviews only', value: 'REVIEWER' },
];

export const VERDICT_OPTIONS = [
    { label: 'Accepted', value: 'ACCEPTED' },
    { label: 'Rejected', value: 'REJECTED' },
];

// export const RATING_OPTIONS = [
//     { label: '1', value: 1, color: '#8A2432' },
//     { label: '2', value: 2, color: '#9B2F70' },
//     { label: '3', value: 3, color: '#7A2184' },
//     { label: '4', value: 4, color: '#621782' },
//     { label: '5', value: 5, color: '#3F0454' },
// ];

// export const RATING_OPTIONS = [
//     { label: '1', value: 1, color: '#D58E8E' },
//     { label: '2', value: 2, color: '#DAA4A9' },
//     { label: '3', value: 3, color: '#E3C4AF' },
//     { label: '4', value: 4, color: '#CCB0F0' },
//     { label: '5', value: 5, color: '#B19CF5' },
// ];

// export const RATING_OPTIONS = [
//     { label: '1', value: 1, color: '#BD4C4C' },
//     { label: '2', value: 2, color: '#C46E75' },
//     { label: '3', value: 3, color: '#CA8F68' },
//     { label: '4', value: 4, color: '#9B67E0' },
//     { label: '5', value: 5, color: '#6C45ED' },
// ];

export const RATING_OPTIONS = [
    { label: '1', value: 1, color: '#e56b6f' },
    { label: '2', value: 2, color: '#fb9649' },
    { label: '3', value: 3, color: '#CB76DC' },
    { label: '4', value: 4, color: '#A56CE6' },
    { label: '5', value: 5, color: '#7C59CC' },
];

export const QUESTION_TIME_OPTIONS = [
    { label: '15 sec', value: 15 },
    { label: '30 sec', value: 30 },
    { label: '45 sec', value: 45 },
    { label: '60 sec', value: 60 },
    { label: '90 sec', value: 90 },
    { label: '2 min', value: 120 },
    { label: '3 min', value: 180 },
    { label: '5 min', value: 300 },
    { label: '10 min', value: 600 },
    { label: '15 min', value: 900 },
    { label: '30 min', value: 1800 },
];
