import Toggle from 'react-toggle';
import '../../../node_modules/react-toggle/style.css';
import styled from 'styled-components';
import FlexView from 'react-flexview/lib';
import { BodyText } from '../Fonts';

const ToggleContainer = styled(FlexView)`
    .react-toggle--checked .react-toggle-track {
        background-color: ${(props) => props.theme.primary};
    }

    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: ${(props) => props.theme.primaryDark};
    }
`;

const ToggleWithLabel = (props: any) => {
    return (
        <ToggleContainer vAlignContent="center">
            <BodyText marginRight="0.5em">{props.label || 'Toggle'}</BodyText>
            <Toggle {...props} value={'yes'} checked={props.value} icons={false} />
        </ToggleContainer>
    );
};

export default ToggleWithLabel;
