import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ErrorType } from '../../types/global';
import { Position } from '../../types/schema';
import useAxios from './useAxios';

interface TemplateCreateArgs {
    id: number;
}

const useTemplates = (companyId: string | undefined) => {
    const [errors, setErrors] = useState<ErrorType>();

    const { api, isAuthenticated } = useAxios();

    const getPositionTemplates = async (): Promise<Array<Position>> => {
        const { data } = await api.get('/positions/getTemplates');
        return data.positions;
    };

    const { data: templates, isLoading } = useQuery({
        queryKey: ['position-templates'],
        queryFn: getPositionTemplates,
        enabled: !!companyId && isAuthenticated,
        onError: (error: any) => setErrors(error.response.data),
    });
    const createTemplatePosition = async (createData: TemplateCreateArgs): Promise<Position> => {
        const { data } = await api.post('/positions/createFromTemplate', {
            companyId: parseInt(companyId as string),
            ...createData,
        });
        return data.createdPosition;
    };

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const createTemplate = useMutation(createTemplatePosition, {
        onMutate: () => setErrors({}),
        onSuccess: (createdPosition) => {
            queryClient.setQueryData(['positions', companyId], (data: any) => {
                const positions = [...data.positions, createdPosition];
                console.log('E_1', data.positions, createdPosition, positions);
                return { ...data, positions };
            });

            navigate(`/positions/overview/${createdPosition.id}/${companyId}`);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    return {
        templates: templates || [],
        createTemplate,
        errors,
        isLoading,
        setErrors,
    };
};

export { useTemplates };
