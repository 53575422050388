import { Center } from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {
    Player,
    BigPlayButton,
    ControlBar,
    PlaybackRateMenuButton,
    VolumeMenuButton,
} from 'video-react';
import '../../node_modules/video-react/dist/video-react.css';
import dimensions from '../styles/dimensions';

import { SubHeading } from './Fonts';

const PlayerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    border-radius: ${dimensions.borderRadius};
`;

const VideoPlayer = ({ url, autoplay, speed, playerState, setPlayerState }: any) => {
    const [dimensions, setDimensions] = useState<any>(false);
    const aspectRatio = 10 / 16; // [used to calculate height based on width convered by the player

    const playerRef = useRef<any>();

    useEffect(() => {
        playerRef.current?.subscribeToStateChange((state: any) => {
            setPlayerState(state);
        });
    }, []);

    useEffect(() => {
        if (autoplay && !playerState.hasStarted && playerRef.current) {
            setTimeout(() => playerRef.current?.play(), 500); // Play button doesn't hide if play is not delayed
            playerRef.current.playbackRate = speed;
        }
    }, [autoplay, playerState]);

    return (
        <PlayerContainer
        // onLayout={(e) => {
        //     setDimensions({
        //         width: e.nativeEvent.layout.width,
        //         height: e.nativeEvent.layout.width * aspectRatio,
        //     });
        // }}
        >
            {url ? (
                <Player
                    ref={playerRef}
                    // autoPlay={autoplay}
                    playsInline
                    // muted
                    fluid={false}
                    height={300}
                    width={'100%'}
                    // poster="/assets/poster.png"
                    src={url}
                >
                    <BigPlayButton position="center" />
                    <ControlBar>
                        <VolumeMenuButton vertical={false} />
                        <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.75, 0.5]} order={7} />
                    </ControlBar>
                </Player>
            ) : (
                <Center h={300} w="100%" backgroundColor="secondaryDark.main">
                    <SubHeading style={{ color: '#fff' }}>{'No response yet!'}</SubHeading>
                </Center>
            )}
        </PlayerContainer>
    );
};

export default VideoPlayer;
