import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
    ArrowForwardIcon,
    HamburgerIcon,
    PlusSquareIcon,
    SearchIcon,
    TimeIcon,
} from '@chakra-ui/icons';
import {
    useDisclosure,
    Box,
    Flex,
    Heading,
    InputGroup,
    InputLeftElement,
    Input as ChakraInput,
    Divider,
    Spacer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    IconButton,
    HStack,
    Image,
} from '@chakra-ui/react';
import { CgArrowLeft } from 'react-icons/cg';
import styled from 'styled-components';
import { Empty } from 'antd';

import Button from '../../components/Button';
import { useManyPositions, usePosition } from '../../api/hooks/usePositions';
import Input from '../../components/Form/Input';
import CheckBox from '../../components/Form/CheckBox';
import analytics from '../../utils/analytics';
import { useCurrentCompany, useIsMobile } from '../../utils/hooks';
import { useColors } from '../../styles/colors';
import { Position, Question } from '../../types/schema';
import { BodyDark, SubHeading } from '../../components/Fonts';
import RichTextInput from '../../components/Form/RichTextInput';
import { QUESTION_TIME_OPTIONS } from '../../utils/constants';
import { useTemplates } from '../../api/hooks/useTemplates';

const StyledRichTextInput = styled(RichTextInput)`
    .ql-container {
        font-family: Inter, sans-serif;
    }
`;

const QuestionCard = ({
    orderNumber,
    title,
    description,
    idealResponseTime,
    type,
    mcqOptions,
}: Question) => {
    const colors = useColors();

    return (
        <Flex mb="10">
            <SubHeading margin="0.5em" marginRight="1em">{`Q${orderNumber + 1}`}</SubHeading>

            <Box flex="1">
                <Flex align="flex-start">
                    <BodyDark
                        whiteSpace="pre-wrap"
                        margin="0.5em"
                        color={!title ? colors.error : undefined}
                    >
                        {title || 'Please enter a question'}
                    </BodyDark>

                    <Spacer />

                    <Flex align="center" mt="0.5em">
                        <TimeIcon color={colors.lightgreen} mx="0.3rem" />
                        <BodyDark color={colors.green} style={{ whiteSpace: 'nowrap' }}>
                            {
                                QUESTION_TIME_OPTIONS.find((o) => o.value === idealResponseTime)
                                    ?.label
                            }
                        </BodyDark>
                    </Flex>
                </Flex>

                {description && (
                    <RichTextInput readOnly value={description} style={{ margin: '0.5em' }} />
                )}

                {type === 'MCQ' && (
                    <Flex flexDir="column" ml="1em">
                        {mcqOptions?.map((o: any) => (
                            <CheckBox
                                isChecked={o.value}
                                value={o.value}
                                iconSize={40}
                                colorScheme="purple"
                                size="sm"
                                spacing="0.6rem"
                                marginTop="0.6rem"
                            >
                                {o.label}
                            </CheckBox>
                        ))}
                    </Flex>
                )}
            </Box>
        </Flex>
    );
};

const TemplatesWidescreen = ({ onBackClick, initialRef }: any) => {
    const { id } = useCurrentCompany();
    const { templates, createTemplate } = useTemplates(id?.toString());

    const [searchString, setSearchString] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState<Position>(templates[0]);

    const createFromTemplate = () => {
        if (selectedTemplate?.id) {
            analytics.event('Add Position', 'Successfully created', selectedTemplate.name);
            createTemplate.mutate({ id: selectedTemplate.id });
        }
    };

    const filteredTemplates = templates.filter(
        (p) =>
            !searchString ||
            searchString
                .trim()
                .split(/[ ,]+/) // split by multiple spaces or commas
                .find((string) => p.name.toLowerCase().includes(string.toLowerCase())),
    );

    return (
        <Box h="100%">
            <Flex
                align="center"
                p="2"
                zIndex="popover"
                position="sticky"
                top="0"
                backgroundColor="background.main"
                borderBottom="1px solid"
                borderColor="gray.200"
            >
                <IconButton
                    aria-label="Back button"
                    icon={<CgArrowLeft size="20" />}
                    variant="ghost"
                    onClick={onBackClick}
                />
                <Heading fontSize="xl">Interview Templates</Heading>

                <Spacer />

                <InputGroup maxW={['xs', 'xs', 'xs', 'md']} bg="foreground.main">
                    <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon color="gray.600" />}
                    />
                    <ChakraInput
                        ref={initialRef}
                        placeholder="Search"
                        onChange={(e) => setSearchString(e.target.value)}
                    />
                </InputGroup>

                <Spacer />

                <Button
                    marginRight="2%"
                    onClick={createFromTemplate}
                    isDisabled={!selectedTemplate}
                    isLoading={createTemplate.isLoading}
                >
                    USE THIS TEMPLATE
                </Button>
            </Flex>

            <Flex h="100%">
                <Box
                    w="20%"
                    minW="3xs"
                    maxW="sm"
                    h="100%"
                    backgroundColor="foreground.main"
                    overflow="auto"
                >
                    {filteredTemplates.map((p: Position) => (
                        <Box
                            p="2"
                            px="10%"
                            cursor="pointer"
                            backgroundColor={
                                p === selectedTemplate ? 'primary.main' : 'foreground.main'
                            }
                            color={p === selectedTemplate ? 'onPrimary.main' : 'onForeground.main'}
                            onClick={() => setSelectedTemplate(p)}
                        >
                            {p.name}
                        </Box>
                    ))}
                </Box>

                {!!selectedTemplate ? (
                    <Box flex="1" px="3%" pt="6" pb="20" h="100%" overflow="auto">
                        <Heading mb="16" color="gray.600" maxW="lg">
                            {selectedTemplate?.name}
                        </Heading>

                        <Flex>
                            <Flex flex="1" pr="20">
                                <StyledRichTextInput
                                    readOnly
                                    value={selectedTemplate?.jobDescription}
                                />
                            </Flex>
                            <Flex flex="1" flexDir="column">
                                {selectedTemplate?.questions.map(QuestionCard)}
                            </Flex>
                        </Flex>
                    </Box>
                ) : (
                    <Flex flex="1" align="center" justify="center" mb="20">
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={'Select a template from the side menu'}
                        />
                    </Flex>
                )}
            </Flex>
        </Box>
    );
};

const TemplatesMobile = ({ onBackClick, initialRef }: any) => {
    const {
        isOpen: isPreviewOpen,
        onOpen: onPreviewOpen,
        onClose: onPreviewClose,
    } = useDisclosure(); // Only used in mobile

    const { id } = useCurrentCompany();

    const { templates, createTemplate, isLoading } = useTemplates(id?.toString());

    const [searchString, setSearchString] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState<Position>();

    const createFromTemplate = () => {
        if (selectedTemplate?.id) {
            analytics.event('Add Position', 'Successfully created', selectedTemplate.name);
            createTemplate.mutate({ id: selectedTemplate.id });
        }
    };

    const filteredTemplates = templates.filter(
        (p) =>
            !searchString ||
            searchString
                .trim()
                .split(/[ ,]+/) // split by multiple spaces or commas
                .find((string) => p.name.toLowerCase().includes(string.toLowerCase())),
    );

    return (
        <>
            <Box h="100%">
                <Box position="sticky" top="0" bg="background.main">
                    <Flex align="center" p="2" borderBottom="1px solid" borderColor="gray.200">
                        <IconButton
                            aria-label="Back button"
                            icon={<CgArrowLeft size="20" />}
                            variant="ghost"
                            onClick={onBackClick}
                        />
                        <Heading fontSize="xl">Interview Templates</Heading>
                    </Flex>

                    <Box p="4">
                        <InputGroup bg="foreground.main">
                            <InputLeftElement
                                pointerEvents="none"
                                children={<SearchIcon color="gray.600" />}
                            />
                            <ChakraInput
                                ref={initialRef}
                                placeholder="Search"
                                onChange={(e) => setSearchString(e.target.value)}
                            />
                        </InputGroup>
                    </Box>
                </Box>

                <Box flex="1" backgroundColor="foreground.main" overflow="auto">
                    {filteredTemplates.map((p) => (
                        <Box
                            p="4"
                            cursor="pointer"
                            backgroundColor={
                                p === selectedTemplate ? 'primary.main' : 'foreground.main'
                            }
                            color={p === selectedTemplate ? 'onPrimary.main' : 'onForeground.main'}
                            onClick={() => {
                                setSelectedTemplate(p);
                                onPreviewOpen();
                            }}
                        >
                            {p.name}
                        </Box>
                    ))}
                </Box>
            </Box>

            <Modal
                size="full"
                isOpen={isPreviewOpen}
                onClose={onPreviewOpen}
                initialFocusRef={initialRef}
            >
                <ModalOverlay />
                <ModalContent>
                    <Box position="sticky" top="0" zIndex="popover">
                        <Flex
                            bg="background.main"
                            align="center"
                            p="2"
                            borderBottom="1px solid"
                            borderColor="gray.200"
                        >
                            <IconButton
                                aria-label="Back button"
                                icon={<CgArrowLeft size="20" />}
                                variant="ghost"
                                onClick={onPreviewClose}
                            />
                            <Heading fontSize="xl">Interview Templates</Heading>
                        </Flex>

                        <Flex bg="foreground.main" p="2" align="center">
                            <Heading color="gray.600" maxW="lg" pl="2" fontSize="2xl">
                                {selectedTemplate?.name}
                            </Heading>

                            <Spacer />

                            <Button
                                onClick={createFromTemplate}
                                isLoading={createTemplate.isLoading}
                            >
                                USE TEMPLATE
                            </Button>
                        </Flex>
                    </Box>

                    <StyledRichTextInput
                        readOnly
                        value={selectedTemplate?.jobDescription}
                        style={{ margin: '5%' }}
                    />

                    <Divider my="4" />

                    <Box m="4%" ml="3%">
                        {selectedTemplate?.questions.map(QuestionCard)}
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
};

const AddPosition = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isTemplatesOpen,
        onOpen: onTemplatesOpen,
        onClose: onTemplatesClose,
    } = useDisclosure();
    const {
        isOpen: isCreateOwnOpen,
        onOpen: onCreateOwnOpen,
        onClose: onCreateOwnClose,
    } = useDisclosure();
    const initialRef = useRef(null);

    const [name, setName] = useState<string>('');

    const { companyId } = useParams();
    const { create, errors, setErrors } = useManyPositions(companyId);
    const { mutate: createPosition, isLoading } = create;
    const isMobile = useIsMobile();

    const addPosition = () => {
        analytics.event('Add Position', 'Successfully created', name);
        createPosition({ name });
    };

    return (
        <>
            <Button
                className="new-position-button" // for react-joyride
                onClick={() => {
                    analytics.event('Add Position', 'Modal opened', 'Button clicked');
                    onOpen();
                }}
            >
                NEW POSITION
            </Button>

            <Modal size="xl" isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
                <ModalOverlay />

                <ModalContent mt={[0, 20]}>
                    <Box pb="16">
                        <ModalCloseButton />
                    </Box>

                    <ModalBody px={[2, 4, 6]} pb="10">
                        <Box
                            m="2"
                            mb="8"
                            cursor="pointer"
                            border="2px solid"
                            borderColor="gray.200"
                            _hover={{ borderColor: 'gray.400' }}
                            borderRadius="2xl"
                            overflow="hidden"
                            onClick={() => {
                                onClose();
                                onTemplatesOpen();
                            }}
                        >
                            <Flex align="center">
                                <Box p="6" flex="1">
                                    <Heading fontSize="xl">Choose from 50+ templates</Heading>
                                    <Flex align="flex-end">
                                        <Box fontSize="md" mt="4" maxW="2xs">
                                            Get preset Job Description and Questions
                                        </Box>
                                        {/* <ArrowForwardIcon boxSize="8" color="primary.main" mb="2" /> */}
                                    </Flex>
                                </Box>

                                <ArrowForwardIcon mr="4" boxSize="14" color="primary.main" />
                            </Flex>
                        </Box>

                        {/* <HStack my="10" fontWeight="bold" color="gray.400" align="center">
                            <Divider />
                            <Box>*</Box>
                            <Divider />
                        </HStack> */}

                        <Box
                            m="2"
                            cursor="pointer"
                            border="2px solid"
                            borderColor="gray.200"
                            _hover={{ borderColor: 'gray.400' }}
                            borderRadius="2xl"
                            overflow="hidden"
                            onClick={() => {
                                onClose();
                                onCreateOwnOpen();
                            }}
                        >
                            <Flex align="center">
                                <Box p="6" flex="1">
                                    <Heading fontSize="xl">Or create your own</Heading>
                                    <Flex align="flex-end">
                                        <Box fontSize="md" mt="4" maxW="2xs">
                                            Start with a blank template
                                        </Box>
                                    </Flex>
                                </Box>

                                <PlusSquareIcon mr="4" boxSize="14" color="gray.200" />
                            </Flex>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal
                size="lg"
                isOpen={isCreateOwnOpen}
                onClose={onCreateOwnClose}
                initialFocusRef={initialRef}
            >
                <ModalOverlay />

                <ModalContent mt={[0, 20]}>
                    <Box flexDir="column">
                        <Flex align="center" p="3" borderBottom="1px solid" borderColor="gray.200">
                            <IconButton
                                aria-label="Back button"
                                icon={<CgArrowLeft size="20" />}
                                variant="ghost"
                                onClick={() => {
                                    onCreateOwnClose();
                                    onOpen();
                                }}
                            />
                            <Heading fontSize="xl">Create your own position</Heading>
                        </Flex>

                        <Box flex="1" px="4%" pt="6%" pb="8%">
                            <Input
                                ref={initialRef}
                                placeholder="Enter position name"
                                error={errors?.name}
                                onChange={(e: any) => setName(e.target.value)}
                            />
                            <Flex justify="center" mt="4">
                                <Button
                                    onClick={addPosition}
                                    isDisabled={!name}
                                    isLoading={isLoading}
                                    loadingText="CREATING"
                                >
                                    CREATE
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                </ModalContent>
            </Modal>

            <Modal
                size="full"
                isOpen={isTemplatesOpen}
                onClose={onTemplatesClose}
                initialFocusRef={initialRef}
            >
                <ModalOverlay />
                {!isMobile ? (
                    <ModalContent mx="4%" my="2%" h="90%" overflow="hidden">
                        <TemplatesWidescreen
                            initialRef={initialRef}
                            onBackClick={() => {
                                onTemplatesClose();
                                onOpen();
                            }}
                        />
                    </ModalContent>
                ) : (
                    <ModalContent h="100%">
                        <TemplatesMobile
                            initialRef={initialRef}
                            onBackClick={() => {
                                onTemplatesClose();
                                onOpen();
                            }}
                        />
                    </ModalContent>
                )}
            </Modal>
        </>
    );
};

export default AddPosition;
