import { AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { apiAuth, apiRefresh } from '.';

const useAxios = () => {
    const refresh = async (): Promise<string | null> => {
        const { data } = await apiRefresh.get('/auth/refresh');
        return data.accessToken;
    };

    const {
        data: token,
        isLoading,
        refetch,
        remove,
    } = useQuery('access-token', refresh, {
        refetchInterval: 15 * 60 * 1000,
        retry: 1,
        retryDelay: 500,
        refetchOnMount: false,
    });

    const isValid = (): boolean => {
        try {
            if (typeof token !== 'string') throw 'Invalid token type';

            const decodedToken: any = jwtDecode(token);
            const expireyDate = moment((decodedToken.exp as number) * 1000);
            const now = moment.now() + 10 * 1000; // 10 secs from now

            return expireyDate.isAfter(now);
        } catch (error) {
            console.log('Token error: ', error);
            return false;
        }
    };

    const requestInterceptor = async (config: AxiosRequestConfig) => {
        if (!isValid()) refetch();

        if (!config.headers) {
            config.headers = { Authorization: `Bearer ${token}` };
        } else if (!config.headers['Authorization']) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    };

    const responseErrorInterceptor = async (error: any) => {
        const prevRequest = error?.config;

        /** Resend request oncemore with fresh accessToken just in case */
        if (error?.response.status === 403 && !prevRequest?.sent) {
            prevRequest.sent = true;
            const newAccessToken = await refresh();

            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return apiAuth(prevRequest);
        }

        return Promise.reject(error);
    };

    useEffect(() => {
        const requestIntercept = apiAuth.interceptors.request.use(
            requestInterceptor,
            (error: any) => Promise.reject(error),
        );

        const responseIntercept = apiAuth.interceptors.response.use(
            (response) => response,
            responseErrorInterceptor,
        );

        return () => {
            apiAuth.interceptors.response.eject(responseIntercept);
            apiAuth.interceptors.request.eject(requestIntercept);
        };
    }, [token]);

    return {
        api: apiAuth,
        isReady: !isLoading,
        clearToken: remove,
        refetchToken: refetch,
        isAuthenticated: !isLoading && !!token,
        isValid: isValid,
    };
};

export default useAxios;
