import { Button, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import dimensions from '../styles/dimensions';

export default () => {
    return (
        <Flex h={dimensions.navBarHeight} align="center" p="0em 4% 0em 4%">
            <Flex flex="1" />
            <Link to="/login" target="_blank">
                <Button variant="ghost" fontWeight="600" borderRadius="24">
                    Sign in
                </Button>
            </Link>
            <Link to="/register" target="_blank">
                <Button colorScheme="primary" bg="primary.main" borderRadius="24">
                    Get Sieve for Free
                </Button>
            </Link>
        </Flex>
    );
};
