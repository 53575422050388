import styled from 'styled-components';
import dimensions from '../../styles/dimensions';
import fonts from '../../styles/fonts';

const Form = styled.form`
    ${fonts.body}

    display: flex;
    flex-direction: column;
`;

export default Form;
