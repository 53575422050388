import { useEffect, useState } from 'react';
import '@reach/dialog/styles.css';
import FlexView from 'react-flexview/lib';
import { useParams } from 'react-router-dom';

import {
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';

import Button from '../../components/Button';
import { BodyLight } from '../../components/Fonts';
import Input from '../../components/Form/Input';
import Editable from '../../components/Form/Editable';
import Select from '../../components/Form/Select';

import { ROLES, ROLE_OPTIONS } from '../../utils/constants';
import useWorkspace from '../../api/hooks/useWorkspace';
import ErrorString from '../../components/ErrorString';
import analytics from '../../utils/analytics';
import { useManyPositions } from '../../api/hooks/usePositions';
import { Position, User } from '../../types/schema';
import { AiFillEdit } from 'react-icons/ai';

const EditMember: React.FC<{
    permission: {
        user: User;
        id: number;
        role: string;
        jobTitle: string;
        permittedPositions: Array<any>;
    };
}> = ({ permission }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { companyId } = useParams();

    const [email, setEmail] = useState<string>(permission.user.email);
    const [role, setRole] = useState<string>(permission.role);
    const [jobTitle, setJobTitle] = useState<string>(permission.jobTitle);
    const [permittedPositions, setPermittedPositions] = useState<Array<number>>(
        permission.permittedPositions?.length
            ? permission.permittedPositions.map((p) => p.positionId)
            : [-1],
    );

    const closeModal = () => {
        setErrors({});
        onClose();
    };

    const { changeRole, errors, setErrors } = useWorkspace(companyId);
    const { mutate, isSuccess, isLoading, reset } = changeRole;

    const { positions } = useManyPositions(companyId);
    const positionOptions = [
        { label: 'All current and future positions', value: -1 },
        ...positions.map((p: Position) => ({
            label: p.name,
            value: p.id,
        })),
    ];

    useEffect(() => {
        if (
            permittedPositions.length > 1 &&
            permittedPositions[permittedPositions.length - 1] === -1 // if last selected is all option, remove everything else
        )
            setPermittedPositions([-1]);

        if (
            permittedPositions.find((p) => p === -1) &&
            permittedPositions[permittedPositions.length - 1] !== -1 // else if all was present and something else is selected, remove all
        )
            setPermittedPositions(permittedPositions.filter((p) => p !== -1));
    }, [permittedPositions]);

    useEffect(() => {
        if (isSuccess) {
            reset();
            closeModal();
        }
    }, [isSuccess]);

    const createMember = () => {
        analytics.event('Edit Member', 'Permission Edited', 'Button clicked');
        mutate({
            id: permission.id,
            jobTitle: jobTitle as string,
            role: role as string,
            positions: permittedPositions.length
                ? permittedPositions.includes(-1)
                    ? []
                    : permittedPositions
                : null,
        });
    };

    const isMyself = permission.id === permission.user?.id;
    const isOwner = permission.role === ROLES.OWNER;

    if (!companyId) return <div>No companyId</div>;

    console.log('D_3', ROLE_OPTIONS, permission);

    return (
        <>
            <Tooltip label="Edit Member">
                <IconButton
                    disabled={isMyself || isOwner}
                    title={
                        isMyself ? 'Cannot remove yourself' : isOwner ? 'Cannot remove owner' : ''
                    }
                    colorScheme="red"
                    variant="ghost"
                    aria-label="Remove member"
                    icon={<AiFillEdit size="20" />}
                    onClick={() => {
                        analytics.event('Delete Member', 'Modal opened', 'Button clicked');
                        onOpen();
                    }}
                />
            </Tooltip>

            <Modal isCentered onClose={closeModal} isOpen={isOpen} motionPreset="slideInBottom">
                <ModalOverlay />
                <ModalContent margin="1.5em">
                    <ModalHeader>
                        Edit Member
                        <BodyLight>They'll receive an update once permission is changed</BodyLight>
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <FlexView column>
                            <Input
                                disabled
                                name="email"
                                type="email"
                                value={email}
                                label="Email Address"
                                placeholder="Email Address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <ErrorString error={errors.email} />

                            <Input
                                name="jobTitle"
                                label="Job Title"
                                placeholder="Recruiter"
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                            />
                            <ErrorString error={errors.jobTitle} />

                            <Select
                                label="Permission"
                                placeholder="Select access level"
                                value={ROLE_OPTIONS.find((o) => o.value === role)}
                                options={ROLE_OPTIONS.filter((o) => o.value !== 'OWNER')}
                                onChange={(r: typeof ROLE_OPTIONS[0]) => {
                                    setRole(r.value);
                                    // setPermittedPositions(positionOptions.map((o: any) => o.value));
                                }}
                            />
                            <ErrorString error={errors.role} />

                            {['MEMBER', 'REVIEWER'].includes(role) && (
                                <>
                                    <Select
                                        isMulti
                                        label="Allowed Positions"
                                        placeholder="Select positions"
                                        options={positionOptions}
                                        value={positionOptions.filter((o) =>
                                            permittedPositions.includes(o.value),
                                        )}
                                        onChange={(arr: any) => {
                                            setPermittedPositions(arr.map((o: any) => o.value));
                                        }}
                                    />
                                    <ErrorString error={errors.positions} />
                                </>
                            )}
                        </FlexView>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={createMember}
                            isLoading={isLoading}
                            loadingText="SENDING"
                            marginTop="1em"
                        >
                            EDIT PERMISSION
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditMember;
