import { Center, Flex, Box, Image } from '@chakra-ui/react';

import CandidateAddMultiple from './CandidateAddMutliple';
import CandidateAddSingle from './CandidateAddSingle';
import { BodyText, SubHeading } from '../../components/Fonts';
import { useIsReviewer } from '../../utils/hooks';

export default () => {
    const isReviewer = useIsReviewer();

    return (
        <Center flexWrap="wrap" height="100%">
            <Center maxW="xs" textAlign="center" flexDir="column">
                <SubHeading>Candidates</SubHeading>
                <BodyText marginTop="0.5rem">
                    You currently have no candidates invited for an interview. Change it now
                </BodyText>

                {!isReviewer && (
                    <Flex mt="16">
                        <CandidateAddSingle />
                        <CandidateAddMultiple />
                    </Flex>
                )}
            </Center>

            <Image src="/4.svg" maxH="18rem" ml="20" />
        </Center>
    );
};
