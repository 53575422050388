import { useState } from 'react';
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { apiAuth } from '.';
import { ErrorType } from '../../types/global';
import { Permission } from '../../types/schema';
import useAxios from './useAxios';

interface UserRegisterParams {
    name: string;
    password: string;
}

const usePermissions = ({
    permissionId,
    slug,
}: {
    permissionId: string | undefined;
    slug: string | undefined;
}) => {
    const [errors, setErrors] = useState<ErrorType>({});
    const { api } = useAxios();

    const getPermissionInvite = async ({
        queryKey: [_, id, slug],
    }: QueryFunctionContext): Promise<{ permission: Permission; isRegistered: boolean }> => {
        const { data } = await apiAuth.get(`/permissions/getInvite`, {
            params: { id, slug },
        });
        return data;
    };

    const { data: response, isLoading } = useQuery({
        queryKey: ['permission-invite', permissionId, slug],
        queryFn: getPermissionInvite,
        enabled: !!permissionId && !!slug,
        onError: (error: any) => setErrors(error.response.data),
    });

    const responsePermission = async (status: string): Promise<string> => {
        const { data } = await api.post(`/permissions/response`, {
            id: permissionId,
            status,
            slug,
        });
        return data.status;
    };

    const queryClient = useQueryClient();

    const permission = response?.permission;
    const isRegistered = response?.isRegistered;

    const respond = useMutation(responsePermission, {
        onMutate: () => setErrors({}),
        onSuccess: () => {
            queryClient.invalidateQueries(['permission-invite', permissionId, slug]);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    return {
        permission,
        isRegistered,
        isLoading,
        errors,
        accept: () => respond.mutate('ACTIVE'),
        deny: () => respond.mutate('REJECTED'),
    };
};

export default usePermissions;
