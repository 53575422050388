import { useDisclosure, Flex, Button as ChakraButton } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { useAuthState } from '../../context/auth';
import { INTERVIEW_STATUSES } from '../../utils/constants';
import useInterviewEvaluation from '../../api/hooks/useInterviewEvaluation';
import { useEffect, useState } from 'react';
import analytics from '../../utils/analytics';

export default ({ interview }: { interview: any }) => {
    const { companyId, interviewId, publicSlug } = useParams();
    const { user } = useAuthState();

    const [verdict, setVerdict] = useState(interview?.verdict);

    const { setVerdict: verdictHook } = useInterviewEvaluation({
        companyId,
        interviewId,
        publicSlug,
    });
    const { mutate, isSuccess, reset } = verdictHook;

    const uploadVerdict = (value: string) => {
        mutate(value);
    };

    useEffect(() => {
        if (isSuccess) {
            reset();
        }
    }, [isSuccess]);

    const notReadyTypes = [INTERVIEW_STATUSES.INVITED.value, INTERVIEW_STATUSES.OPENED.value];
    const notReady = notReadyTypes.includes(interview.status);
    // const notAssigned = user?.id !== interview.assignedToId;
    const notAssigned = false; // Anyone can set verdict now

    return (
        <>
            <Flex
                flexDir="column"
                className="verdict-buttons" // for react-joyride
            >
                <ChakraButton
                    title={
                        notReady
                            ? 'No response has been submitted'
                            : notAssigned
                            ? 'Only assigned manager can set verdict'
                            : undefined
                    }
                    disabled={notReady || notAssigned}
                    onClick={() => {
                        analytics.event('Verdict', 'Set', 'ACCEPTED');
                        setVerdict('ACCEPTED');
                        uploadVerdict('ACCEPTED');
                    }}
                    variant={verdict === 'ACCEPTED' ? 'solid' : !verdict ? 'outline' : 'ghost'}
                    borderRadius="full"
                    colorScheme="green"
                    marginBottom="0px"
                >
                    {verdict === 'ACCEPTED' ? 'ACCEPTED' : 'ACCEPT'}
                </ChakraButton>

                <ChakraButton
                    title={
                        notReady
                            ? 'No response has been submitted'
                            : notAssigned
                            ? 'Only assigned manager can set verdict'
                            : undefined
                    }
                    disabled={notReady || notAssigned}
                    onClick={() => {
                        analytics.event('Verdict', 'Set', 'REJECTED');
                        setVerdict('REJECTED');
                        uploadVerdict('REJECTED');
                    }}
                    variant={verdict === 'REJECTED' ? 'solid' : 'ghost'}
                    borderRadius="full"
                    colorScheme="red"
                    marginBottom="0px"
                >
                    {verdict === 'REJECTED' ? 'REJECTED' : 'REJECT'}
                </ChakraButton>
            </Flex>
        </>
    );
};
