import styled from 'styled-components';
import { Image } from '@chakra-ui/react';

import AppLogo from '../../components/AppLogo';
import { useColors } from '../../styles/colors';
import dimensions from '../../styles/dimensions';

const ArtContainer = styled.div`
    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        display: none;
    }

    height: 100%;
    width: 10%;
    min-width: 160px;
    max-width: 300px;

    background-color: ${({ theme }) => theme.secondary};

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default () => {
    const colors = useColors();

    return (
        <ArtContainer>
            <AppLogo onlyText color={colors.foregroundDark} size="lg" m="4" />
            <Image src="/rings.png" flex="1" fit="cover" mt="4" />
        </ArtContainer>
    );
};
