import '@reach/dialog/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    useDisclosure,
} from '@chakra-ui/react';

import React, { useEffect } from 'react';
import { usePosition } from '../../api/hooks/usePositions';
import analytics from '../../utils/analytics';

export default ({
    isOpen,
    onClose,
    position,
}: {
    position?: { [key: string]: any };
    isOpen: boolean;
    onClose: any;
}) => {
    const { companyId } = useParams();
    const { archive } = usePosition({ companyId, positionId: position?.id });

    const cancelRef = React.useRef<any>();
    const navigate = useNavigate();

    const archivePosition = () => {
        archive.mutate();
    };

    useEffect(() => {
        if (archive.isSuccess) {
            analytics.event('Archive Position', 'Successfully archived', 'Button clicked');
            archive.reset();
            navigate(`/positions/${companyId}`);
            onClose();
        }
    }, [archive.isSuccess]);

    if (!companyId) return <div>No companyId</div>;

    return (
        <>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {position?.name}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure, you want to archive this position?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                CANCEL
                            </Button>
                            <Button colorScheme="red" onClick={archivePosition} ml={3}>
                                ARCHIVE
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};
