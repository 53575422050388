const colors = {
    primary: {
        main: '#836DFF',
        50: '#e8e7ff',
        100: '#bcbbf9',
        200: '#8d8df1',
        300: '#6a60ea',
        400: '#4d34e4',
        500: '#401aca',
        600: '#3a149e',
        700: '#2f0e72',
        800: '#1e0646',
        900: '#0e021c',
    },
    primaryDark: { main: '#5843CF' },
    primaryLight: {
        main: '#AFA1FF',
        50: '#f4efff',
        100: '#d7d1e5',
        200: '#bbb4cd',
        300: '#9d96b7',
        400: '#7f78a1',
        500: '#655e87',
        600: '#51496a',
        700: '#3b344d',
        800: '#261e30',
        900: '#100817',
    },
    onPrimary: { main: '#FFFFFF' },

    secondary: { main: '#3B4248' },
    secondaryDark: { main: '#2A3136' },
    secondaryLight: { main: '#586671' },
    onSecondary: { main: '#FFFFFF' },

    background: {
        main: '#FFFFFF',
        50: '#eceff9',
        100: '#d3d5df',
        200: '#b7bac6',
        300: '#9a9faf',
        400: '#7e8599',
        500: '#646c7f',
        600: '#4e5564',
        700: '#383d47',
        800: '#20252c',
        900: '#090b14',
    },
    backgroundDark: { main: '#F1F1F1' },
    onBackground: { main: '#3B4248' },
    onBackgroundLight: { main: '#A1A1A1' },

    foreground: {
        main: '#F5F5F5',
        50: '#f2f2f2',
        100: '#e8e8e8',
        200: '#e2e2e2',
        300: '#bfbfbf',
        400: '#a6a6a6',
        500: '#737373',
        600: '#595959',
        700: '#404040',
        800: '#262626',
        900: '#0d0d0d',
    },
    foregroundDark: { main: '#D9D9D9' },
    onForeground: { main: '#3B4248' },

    blue: {
        main: '#10567e',
        50: '#e1f3ff',
        100: '#b6d8fc',
        200: '#89bef4',
        300: '#5da4ee',
        400: '#338ae9',
        500: '#1a71cf',
        600: '#1158a2',
        700: '#073f75',
        800: '#002648',
        900: '#000e1d',
    },
    lightblue: { main: '#9CCBE7' },

    green: {
        main: '#337065',
        '50': '#ECF8F2',
        '100': '#CAECDA',
        '200': '#A8E0C3',
        '300': '#86D4AB',
        '400': '#65C894',
        '500': '#43BC7C',
        '600': '#359763',
        '700': '#28714A',
        '800': '#1B4B32',
        '900': '#0D2619',
    },
    lightgreen: { main: '#ABD1BE' },

    orange: {
        main: '#F79D1E',
        50: '#FEF4E6',
        100: '#FDE1BA',
        200: '#FBCE8E',
        300: '#F9BA61',
        400: '#F8A735',
        500: '#F69409',
        600: '#C57607',
        700: '#945905',
        800: '#623B04',
        900: '#311E02',
    },
    lightorange: { main: '#EFD48D' },

    red: {
        main: '#E35541',
        50: '#ffebe4',
        100: '#fac8b9',
        200: '#f1a28f',
        300: '#e97862',
        400: '#e24c37',
        500: '#c83e1d',
        600: '#9d3816',
        700: '#702e0e',
        800: '#461f06',
        900: '#1e0c00',
    },
    lightred: { main: '#f1a28f' },

    pink: {
        main: '#EC6D9E',
        50: '#ffe5ef',
        100: '#f9b9ce',
        200: '#f18db1',
        300: '#ea6196',
        400: '#e4366c',
        500: '#ca1d48',
        600: '#9e152f',
        700: '#720d1a',
        800: '#46050a',
        900: '#1d0001',
    },
    lightpink: { main: '#FAB8C4' },
};

export default colors;
