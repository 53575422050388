import { CommentType } from './global';

export enum QuestionType {
    TEXT = 'TEXT',
    MCQ = 'MCQ',
}

export interface MCQOption {
    label: string;
    value: string;
}

export interface Question {
    id: number;
    companyId: number;
    createdAt: string;
    createdById: number;
    description?: string;
    idealResponseTime: number;
    mcqOptions?: MCQOption[];
    orderNumber: number;
    positionId: number;
    title?: string;
    type: string;
}

export interface Position {
    companyId: string;
    createdAt: string;
    createdById: number;
    id: number;
    jobDescription: string;
    name: string;
    questions: Array<Question>;
    requirements: string;
    responsibilities: string;
    updatedAt: string;
    updatedById: number;
}

export enum Role {
    OWNER = 'OWNER',
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER',
}

export interface Permission {
    id: number;
    user: User;
    jobTitle: string;

    role: string;
    status: string;
    slug?: string;

    companyId: number;
    company: Company;

    createdBy: User;
    createdAt: Date;
    themeColors: Array<string>;

    permittedPositions: Array<any>;
}

export interface Company {
    id?: number;
    name?: string;
    logos?: Array<string>;
    theme?: Array<string>;
    themeColors?: stringArray<string>;
}

export interface User {
    id: number;
    name: string;
    email: string;
    picture: Array<string>;
    registerMethod: string;
    permissions: Array<Permission>;
    isVerified: boolean;
    hasPassword: boolean;
    createdAt?: string;
}

export enum InterviewStatus {
    INVITED = 'INVITED',
    OPENED = 'OPENED',
    UNDERWAY = 'UNDERWAY',
    REVIEW_PENDING = 'REVIEW_PENDING',
    // COMPLETED = 'COMPLETED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    // ARCHIVED = 'ARCHIVED',
}

export interface InterviewInvite {
    assignedTo: User;
    assignedToId: number;

    candidate: User;
    candidateId: number;

    companyId: number;
    createdAt: string;
    deadline: string;

    id: number;
    position: Position;
    positionId: number;
    publicSlug: string;
    status: string;

    comments?: Array<CommentType>;
}

export interface InterviewCandidate {
    id: number;
    positionId: number;
    companyId: number;

    company: {
        id: number;
        name: string;
        logos: Array<string>;
        themeColors: Array<string>;
        createdAt: string;
        updatedAt: string;
    };

    status: string;
    slug: string;
    publicSlug: string;
    progress: number;

    candidateId: number;
    candidate: User;

    assignedToId: number;
    assignedTo: User;

    responses: Array<
        | {
              id: number;
              questionId: number;
              displayQuestion: any;
              orderNumber: number;
              storageStatus: string;
              file?: Blob;
              error?: any;
          }
        | {
              id?: number;
              questionId: number;
              displayQuestion: any;
              orderNumber: number;
              storageStatus: string;
              file?: Blob;
              error?: any;
          }
    >;

    position: {
        id: number;
        name: string;
        jobDescription: string;
        requirements: string;
        responsibilities: string;
        questions: Array<Question>;
    };

    createdAt: Date;
    deadline: Date;
}

export interface Response {
    id: int;
    displayQuestion: Question;
    uploadUrl: string;

    storageStatus: string;
    storagePath: string;

    videoLink?: string;
    extraInput?: string;
    orderNumber?: number;

    interviewId?: string;
    questionId?: string;
}
