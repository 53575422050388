import axios from 'axios';
import { __DEV__ } from '../../utils/constants';

const BASE_URL =
    process.env.REACT_APP_LOCAL_SERVER !== 'false' && __DEV__
        ? 'http://localhost:5000/api/v1'
        : window.location.toString().includes('app.sievetalent.com') ||
          window.location.toString().includes('sieve.nablasol.com')
        ? 'https://sieveserver.nablasol.com/api/v1'
        : 'https://dev.sievetalent.com/api/v1';

export const apiRefresh = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
});

export const apiAuth = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
});
