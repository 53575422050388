import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Position, Question } from '../../types/schema';
import { showChangesSavedToast } from '../../utils';
import useAxios from './useAxios';
import { usePosition } from './usePositions';

const moveArrayElement = (array: any[], from: number, to: number) => {
    if (to === from) return array;
    if (!Array.isArray(array)) return array;

    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k != to; k += increment) {
        array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
};

const findRequiredUpdates = (newQuestions: Question[], oldQuestions: Question[]): Question[] => {
    return (
        newQuestions
            /** Assign new order numbers based on rearrangement */
            .map((q, index) => ({ ...q, orderNumber: index }))

            /** Filter questions that don;t change order */
            .filter((newQuestion) => {
                const oldQuestion = oldQuestions?.find(({ id }) => id === newQuestion.id);
                if (oldQuestion?.orderNumber !== newQuestion.orderNumber) {
                    return true;
                }
                return false;
            })
    );
};

const useQuestions = ({
    companyId,
    positionId,
}: {
    companyId: string | undefined;
    positionId: string | undefined;
}) => {
    const { api } = useAxios();

    const { position, isLoading } = usePosition({ companyId, positionId });

    const questions = position?.questions.sort((a: Question, b: Question) => {
        return a.orderNumber - b.orderNumber;
    });

    const moveAndUpdate = async (from: number, to: number) => {
        if (!questions) return questions;

        /** Move the question to new position */
        let newLocalQuestions: Question[] = moveArrayElement(questions, from, to);

        /** Find Questions that need to be updated */
        const requiredUpdates = findRequiredUpdates(newLocalQuestions, questions);

        /** If required update length is 0, no action is required */
        if (!requiredUpdates.length) return;

        /** Perform update operation */
        await updateMany.mutate(requiredUpdates);
    };

    const createQuestion = async (): Promise<Question> => {
        const createData = {
            companyId: parseInt(companyId as string),
            positionId: parseInt(positionId as string),
            orderNumber: questions?.length,
        };

        const { data } = await api.post('/questions/create', createData);
        return data.question;
    };

    const updateQuestion = async (updateData: Question): Promise<Question> => {
        const { data } = await api.put('/questions/update', updateData);
        return data.question;
    };

    const deleteAndUpdate = async (
        deleteQuestion: Question,
    ): Promise<{ deletedQuestion: Question; updatedQuestions: Array<Question> }> => {
        if (questions) {
            /** Find Questions that need to be updated */
            const updateQuestions = findRequiredUpdates(
                questions.filter(({ id }) => id !== deleteQuestion.id),
                questions,
            );

            /** insert data for delete and update operations */
            await api.put('/questions/delete', {
                companyId: parseInt(companyId as string),
                toDelete: deleteQuestion,
                toUpdate: updateQuestions,
            });

            return { deletedQuestion: deleteQuestion, updatedQuestions: updateQuestions };
        }

        throw 'Error';
    };

    const updateManyQuestions = async (questions: Array<Question>): Promise<Array<Question>> => {
        const { data } = await api.put('/questions/updateMany', {
            companyId: parseInt(companyId as string),
            questions,
        });

        return data.updatedQuestions;
    };

    const queryClient = useQueryClient();

    const create = useMutation(createQuestion, {
        onSuccess: (createdQuestion) => {
            queryClient.setQueryData(['positions', companyId], (data: any) => {
                const positions = data.positions.map((pos: Position) => {
                    /**
                     * For the position id where question IS NOT created
                     * Return the position
                     *  */
                    if (pos.id !== createdQuestion.positionId) return pos;

                    /**
                     * For the position id where question IS created
                     * add the question to the questions array
                     *  */
                    return { ...pos, questions: [...pos.questions, createdQuestion] };
                });

                return { ...data, positions };
            });

            showChangesSavedToast();
        },
    });

    const update = useMutation(updateQuestion, {
        onSuccess: (updatedQuestion) => {
            queryClient.setQueryData(['positions', companyId], (data: any) => {
                const positions = data.positions.map((pos: Position) => {
                    /**
                     * For the position id where question IS NOT updated
                     * Return the position
                     *  */
                    if (pos.id !== updatedQuestion.positionId) return pos;

                    /**
                     * For the position id where question IS updated
                     * find the question to replace by updated value
                     *  */
                    const questions = pos.questions.map((qus: Question) => {
                        if (qus.id !== updatedQuestion.id) return qus;
                        return updatedQuestion;
                    });

                    return { ...pos, questions };
                });

                return { ...data, positions };
            });

            showChangesSavedToast();
        },
    });

    const deleteQuestion = useMutation(deleteAndUpdate, {
        onSuccess: ({ deletedQuestion, updatedQuestions }) => {
            queryClient.setQueryData(['positions', companyId], (data: any) => {
                const positions = data.positions.map((pos: Position) => {
                    /**
                     * For the position id where question IS NOT deleted
                     * Return the position
                     *  */
                    if (pos.id !== deletedQuestion.positionId) return pos;

                    /**
                     * For the position id where question IS deleted
                     *  */
                    const questions = pos.questions
                        .filter((qus: Question) => {
                            /** Remove the deleted question */
                            return qus.id !== deletedQuestion.id;
                        })
                        .map((qus: Question) => {
                            /** update the remaining question */
                            const updatedQuestion = updatedQuestions.find(
                                ({ id }) => id === qus.id,
                            );

                            if (updatedQuestion) return updatedQuestion;
                            return qus;
                        });

                    return { ...pos, questions };
                });

                return { ...data, positions };
            });

            showChangesSavedToast();
        },
    });

    const updateMany = useMutation(updateManyQuestions, {
        onSuccess: (updatedQuestions) => {
            queryClient.setQueryData(['positions', companyId], (data: any) => {
                /** Find the relevant position id */
                const positionId = updatedQuestions[0].positionId;

                const positions = data.positions.map((pos: Position) => {
                    /**
                     * For the position id where questions ARE NOT updated
                     * Return the position
                     *  */
                    if (pos.id !== positionId) return pos;

                    /**
                     * For the position id where question ARE updated
                     *  */
                    const questions = pos.questions.map((qus: Question) => {
                        /** update the question */
                        const updatedQuestion = updatedQuestions.find(({ id }) => id === qus.id);

                        if (updatedQuestion) return updatedQuestion;
                        return qus;
                    });

                    return { ...pos, questions };
                });

                return { ...data, positions };
            });

            showChangesSavedToast();
        },
    });

    return {
        isLoading,
        questions: questions || [],
        moveAndUpdate: moveAndUpdate,
        create,
        update,
        deleteQuestion,
    };
};

export { useQuestions };
