import { createContext, useContext, useReducer, useState } from 'react';
import { ErrorType } from '../types/global';

type State = {
    progress: { [key: string]: { questionId: number; speed: number; percent: number } }; // speed is in kbps
};

interface UPDATE_PROGRESS {
    type: 'UPDATE_PROGRESS';
    payload: {
        questionId: number;
        progress: { questionId: number; speed: number; percent: number };
    };
}

type Action = UPDATE_PROGRESS;
type ActionTypes = UPDATE_PROGRESS['type'];

type Dispatch = (type: ActionTypes, payload: any) => void;

const AppReducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'UPDATE_PROGRESS':
            const { questionId } = action.payload;

            console.log('CONTEXT UPLOADING: ==> ', action.payload);
            return {
                ...state,
                progress: { ...state.progress, [questionId]: action.payload },
            };

        default:
            return state;
    }
};

const initialState: State = {
    progress: {},
};

const AppStateContext = createContext<State>({} as State);
const AppDispatchContext = createContext<Dispatch>({} as Dispatch);

export const AppProvider: React.FC = ({ children }) => {
    const [state, defaultDispatch] = useReducer(AppReducer, initialState);

    const dispatch = (type: ActionTypes, payload: any) => defaultDispatch({ type, payload });
    const [errors, setErrors] = useState<ErrorType>({});

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

export const useAppState = () => useContext(AppStateContext);
export const useAppDispatch = () => useContext(AppDispatchContext);
