import styled from 'styled-components';
import dimensions from '../styles/dimensions';

const Card = styled.div<{ variant?: string; flex?: boolean }>`
    background-color: ${(props) => props.variant !== 'outline' && props.theme.foreground};
    border: ${(props) => (props.variant === 'outline' ? '1px' : '0px')} solid
        ${({ theme }) => theme.foregroundDark};
    border-bottom: ${(props) => (props.variant === 'outline' ? '6px' : '0px')} solid
        ${({ theme }) => theme.primaryLight};
    border-radius: ${dimensions.borderRadius};

    margin-right: 1em;
    margin-bottom: 1em;
    padding: 1.5em;

    flex: ${(props) => props.flex && '1'};
`;

export default Card;
