import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import useUser from '../../api/hooks/useUser';
import {
    CheckCircleOutlined,
    LikeOutlined,
    LoadingOutlined,
    LockOutlined,
    MehOutlined,
} from '@ant-design/icons';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import AppLogo from '../../components/AppLogo';
import { BodyDark, BodyText } from '../../components/Fonts';
import moment from 'moment';
import analytics from '../../utils/analytics';

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: #ddd;
`;

const Container = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NavContainer = styled.div`
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5%;
    padding-right: 5%;
    border-bottom: 1px solid #ddd;
`;

export default () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const { userId, slug } = useParams();
    const { verify, sendVerificationLink, errors, setErrors } = useUser();

    const [timeLeft, setTimeLeft] = useState(0);
    const { isLoading, isSuccess, mutate, reset } = sendVerificationLink;

    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    useEffect(() => {
        if (isSuccess) {
            setTimeLeft(30);
            reset();
        }
    }, [isSuccess]);

    const verifyUser = () => {
        if (!userId || !slug) {
            setErrors({ message: 'Invalid verification URL' });
            return;
        }

        verify.mutate({ slug, userId: parseInt(userId) });
    };

    useEffect(() => {
        analytics.event('Verification', 'Successfully verified', undefined);
        verifyUser();
    }, []);

    if (verify.isLoading) {
        return (
            <Main>
                <NavContainer>
                    <AppLogo color="black" />
                </NavContainer>
                <Main>
                    <Container>
                        <LoadingOutlined spin style={{ fontSize: 40, marginBottom: 5 }} />
                        <BodyDark>{`Verifying User...`}</BodyDark>
                        <Line />
                        <BodyText textAlign="center">{`Hold on...`}</BodyText>
                    </Container>
                </Main>
            </Main>
        );
    }

    if (verify.isSuccess) {
        return (
            <Main>
                <NavContainer>
                    <AppLogo color="black" />
                </NavContainer>
                <Main>
                    <Container>
                        <LikeOutlined style={{ fontSize: 40, marginBottom: 5 }} />
                        <BodyDark>{`Verification Completed`}</BodyDark>
                        <Line />
                        <BodyText textAlign="center">{`Your account has successfully verified. No more action is required, you can close this window`}</BodyText>
                    </Container>
                </Main>
            </Main>
        );
    }

    return (
        <Main>
            <NavContainer>
                <AppLogo color="black" />
            </NavContainer>
            <Main>
                <Container>
                    <MehOutlined style={{ fontSize: 40, marginBottom: 5 }} />
                    <BodyDark>{`Something went wrong`}</BodyDark>
                    <Line />
                    <BodyText textAlign="center">{errors.message}</BodyText>
                    <BodyText textAlign="center">
                        {'Click below to recieve the verification link again'}
                    </BodyText>
                    {isLoading ? (
                        <strong>Sending link...</strong>
                    ) : timeLeft === 0 ? (
                        <Box color="primary.main" textAlign="center" onClick={() => mutate()}>
                            Get Verification Link
                        </Box>
                    ) : (
                        <span>
                            <strong>Link sent successfully</strong>
                            {` `}Retry in {timeLeft} secs
                        </span>
                    )}
                </Container>
            </Main>
        </Main>
    );
};
