import { Image } from '@chakra-ui/react';
import { Company } from '../../types/schema';

const CompanyLogo = ({ company, ...otherProps }: any) => {
    const logo = company?.logos?.[0];

    return logo ? (
        <Image
            src={logo}
            alt="Company Logo"
            h="14"
            maxW="32"
            m="4"
            mt="2"
            fit="contain"
            {...otherProps}
        />
    ) : (
        <div />
    );
};

export default CompanyLogo;
