import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Button,
    Tooltip,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineDelete } from 'react-icons/ai';

import useWorkspace from '../../api/hooks/useWorkspace';
import Loading from '../../components/Loading';
import { User } from '../../types/schema';
import MessageBox from '../../components/MessageBox';
import analytics from '../../utils/analytics';
import { useAuthState } from '../../context/auth';
import { useCurrentPermission } from '../../utils/hooks';
import { ROLES } from '../../utils/constants';

const DeleteMember: React.FC<{ permission: { user: User; id: number; role: string } }> = ({
    permission,
}) => {
    const { companyId } = useParams();
    const { removeMember, errors, setErrors } = useWorkspace(companyId);

    const { user } = useAuthState();
    const currentPermission = useCurrentPermission();
    const isMyself = permission.id === user?.id;
    const isOwner = permission.role === ROLES.OWNER;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { mutate, isSuccess, reset } = removeMember;
    const confirmRemove = () => {
        analytics.event('Delete Member', 'Successfully deleted', 'Button clicked');
        mutate(permission?.id);
    };

    useEffect(() => {
        reset();
        setErrors({});
        onClose();
    }, [isSuccess]);

    if (!companyId) return <Loading />;

    return (
        <>
            <Tooltip label="Remove Member">
                <IconButton
                    disabled={isMyself || isOwner}
                    title={
                        isMyself ? 'Cannot remove yourself' : isOwner ? 'Cannot remove owner' : ''
                    }
                    colorScheme="red"
                    variant="ghost"
                    aria-label="Remove member"
                    icon={<AiOutlineDelete size="20" />}
                    onClick={() => {
                        analytics.event('Delete Member', 'Modal opened', 'Button clicked');
                        onOpen();
                    }}
                />
            </Tooltip>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete Member</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure, you want to remove {permission?.user.name} as member?
                        {errors.message && <MessageBox description={errors.message} />}
                    </ModalBody>

                    <ModalFooter>
                        <Button mr={3} onClick={onClose}>
                            CANCEL
                        </Button>
                        <Button onClick={confirmRemove} colorScheme={'red'}>
                            YES, REMOVE
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DeleteMember;
