import styled from 'styled-components';

const SIZE = 16;

type CircleProps = {
    size?: number;
    color?: string;
};

const Circle = styled.div<CircleProps>`
    height: ${(props) => `${props.size || SIZE}px`};
    width: ${(props) => `${props.size || SIZE}px`};
    min-width: ${(props) => `${props.size || SIZE}px`}; // is squished without this in flex divs
    border-radius: ${(props) => `${props.size || SIZE}px`};
    background-color: ${(props) => props.color || props.theme.secondary};
`;

export default Circle;
