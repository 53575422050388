// @ts-nocheck

import { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import styled from 'styled-components';
import fonts from '../styles/fonts';

const StyledTable = styled.table<{ headerTop?: string }>`
    border-spacing: 0;
    width: 100%;

    thead {
        position: sticky;
        top: ${(props) => props.headerTop || 0};
        z-index: 1;

        background-color: ${(props) => props.theme.background};
        // Disappears on scroll
        /* border-bottom: ${({ theme }) => `solid 1px ${theme.onBackgroundLight}`}; */

        box-shadow: inset 0 -1px 0 ${(props) => props.theme.foregroundDark};
    }

    th {
        ${fonts.bodyDark};
        text-align: start;
        padding: 1em 1.5em;

        @media only screen and (max-width: 1200px) {
            padding: 0.7em 1em;
        }
    }

    td {
        ${fonts.body};
        border-bottom: ${({ theme }) => `solid 1.5px ${theme.foreground}`};
        padding: 0.5em 1.5em;
        text-align: start;

        @media only screen and (max-width: 1200px) {
            padding: 0.5em 1em;
        }
    }
`;

const StyledRow = styled.tr<{ onClick?: Function; bg?: string }>`
    cursor: ${(props) => props.onClick && 'pointer'};
    background-color: ${(props) => props.bg};
`;

const Table: React.FC<{
    columns: any;
    data: any;
    onRowClick?: (data: any) => void;
    headerTop?: string;
}> = ({ columns, data, onRowClick, headerTop }) => {
    const memoColumns = useMemo(() => columns, []);
    const memoData = useMemo(() => data, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
    );

    return (
        <StyledTable {...getTableProps()} headerTop={headerTop}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {column.sortable !== false && (
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' ↓'
                                                : ' ↑'
                                            : ' ↕'}
                                    </span>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                        <StyledRow
                            {...row.getRowProps()}
                            onClick={onRowClick && (() => onRowClick(row.original))}
                            bg={index % 2 ? '#fbfbfb' : '#fff'}
                        >
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                            })}
                        </StyledRow>
                    );
                })}
            </tbody>
        </StyledTable>
    );
};

export default Table;
