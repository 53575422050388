import { AxiosError, AxiosRequestConfig } from 'axios';
import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import {
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    useQueryClient,
} from 'react-query';
import { useNavigate } from 'react-router-dom';
import { apiRefresh } from '../api/hooks';
import useAxios from '../api/hooks/useAxios';
import { ErrorType } from '../types/global';
import { Permission, User } from '../types/schema';

const LINKEDIN_CLIENT_ID = '77xr2ue29nn0k5';

interface LoginSuccessResponse {
    user: User;
    accessToken: string;
}

interface Credentails {
    email: string;
    password: string;
}

interface RegisterData {
    name: string;
    email: string;
    password: string;
}

type State = {
    authenticated: boolean;
    loading: boolean;

    user: User | null; // User data and profile info
    permissions: Array<Permission>; // Permissions associated with this user
};

interface Functions {
    defaultLogin: UseMutationResult<LoginSuccessResponse, any, Credentails, void>;
    googleLogin: UseMutationResult<LoginSuccessResponse, any, string, void>;
    register: UseMutationResult<LoginSuccessResponse, any, RegisterData, void>;
    linkedInLogin: () => void;
    errors: ErrorType;
    setErrors: (err: ErrorType) => void;
    logout: UseMutationResult<any, any, void, void>;
}

interface LOGIN {
    type: 'LOGIN';
    payload: { [key: string]: any };
}

interface LOGOUT {
    type: 'LOGOUT';
}

type Action = LOGIN | LOGOUT;
type ActionTypes = LOGIN['type'] | LOGOUT['type'];

const AuthReducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'LOGIN':
            const { success, message, user } = action.payload;
            const { permissions, ...userData } = user;

            return {
                ...state,
                authenticated: true,
                loading: false,
                user: userData,
                permissions,
            };

        case 'LOGOUT':
            return {
                ...state,
                authenticated: false,
                loading: false,
                user: null,
                permissions: [],
            };

        default:
            return state;
    }
};

const initialState: State = {
    loading: true,
    authenticated: false,
    user: null,
    permissions: [],
};

const AuthStateContext = createContext<State>({} as State);
const AuthFunctionsContext = createContext<Functions>({} as Functions);

export const AuthProvider: React.FC = ({ children }) => {
    const [state, defaultDispatch] = useReducer(AuthReducer, initialState);

    const dispatch = (type: ActionTypes, payload: any) => defaultDispatch({ type, payload });
    const [errors, setErrors] = useState<ErrorType>({});

    const { api, clearToken, refetchToken, isReady, isAuthenticated } = useAxios();

    const registerUser = async (userData: RegisterData): Promise<LoginSuccessResponse> => {
        const { data } = await apiRefresh.post('/auth/register', userData);
        return data;
    };

    const combo = async (credentials: Credentails): Promise<LoginSuccessResponse> => {
        const { data } = await apiRefresh.post('/auth/login', credentials);
        return data;
    };

    const google = async (token: string): Promise<LoginSuccessResponse> => {
        const { data } = await apiRefresh.post('/auth/google', { token });
        return data;
    };

    const linkedIn = async (token: string): Promise<LoginSuccessResponse> => {
        const { data } = await apiRefresh.post('/auth/linkedin', { token });
        return data;
    };

    const logoutUser = async () => {
        const { data } = await api.get('/auth/logout', { withCredentials: true });
        return data;
    };

    const defaultLogin = useMutation(combo, {
        onMutate: () => setErrors({}),
        onSuccess: (data) => {
            queryClient.setQueryData('access-token', data.accessToken);
            queryClient.invalidateQueries(['permission-invite']);
            dispatch('LOGIN', data);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const queryClient = useQueryClient();

    const register = useMutation(registerUser, {
        onMutate: () => setErrors({}),
        onSuccess: (data) => {
            queryClient.setQueryData('access-token', data.accessToken);
            queryClient.invalidateQueries(['permission-invite']);
            dispatch('LOGIN', data);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const googleLogin = useMutation(google, {
        onMutate: () => setErrors({}),
        onSuccess: (data) => {
            queryClient.setQueryData('access-token', data.accessToken);
            queryClient.invalidateQueries(['permission-invite']);
            dispatch('LOGIN', data);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const linkeInLoginCallback = useMutation(linkedIn, {
        onMutate: () => setErrors({}),
        onSuccess: (data) => {
            queryClient.setQueryData('access-token', data.accessToken);
            queryClient.invalidateQueries(['permission-invite']);
            dispatch('LOGIN', data);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const navigate = useNavigate();
    const logout = useMutation(logoutUser, {
        onMutate: () => setErrors({}),
        onSettled: () => {
            /** Invalidate all queries */
            queryClient.setQueryData('access-token', undefined);
            dispatch('LOGOUT', null);
            navigate(`/`);
            queryClient.invalidateQueries();
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const { linkedInLogin } = useLinkedIn({
        clientId: LINKEDIN_CLIENT_ID,
        scope: 'r_liteprofile r_emailaddress',
        redirectUri: `${window.location.origin}/callback/linkedin`,
        onSuccess: (token) => {
            linkeInLoginCallback.mutate(token);
        },
        onError: (error) => {
            console.log('D_12', error);
        },
    });

    const me = async (): Promise<LoginSuccessResponse> => {
        const { data } = await api.get('/auth/me');
        return data;
    };

    useQuery({
        queryKey: 'me',
        queryFn: me,
        enabled: isAuthenticated,
        onSuccess: (data) => dispatch('LOGIN', data),
        onError: (error: any) => setErrors(error.response.data),
        retry: 1,
        retryDelay: 500,
        refetchOnMount: false,
    });

    useEffect(() => {
        if (isReady && !isAuthenticated) dispatch('LOGOUT', null);
    }, [isReady]);

    const authFunctions = {
        defaultLogin,
        googleLogin,
        register,
        logout,
        linkedInLogin,
        errors,
        setErrors,
    };

    return (
        <AuthFunctionsContext.Provider value={authFunctions}>
            <AuthStateContext.Provider value={state}>{children}</AuthStateContext.Provider>
        </AuthFunctionsContext.Provider>
    );
};

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthFunctions = () => useContext(AuthFunctionsContext);
