import { Flex } from '@chakra-ui/react';
import FlexView from 'react-flexview/lib';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthState } from '../context/auth';
import { useColors } from '../styles/colors';
import { Role } from '../types/schema';
import { useCurrentRole, useIsAdmin } from '../utils/hooks';

import { ButtonLight, ButtonText, SubHeading, SubTitle } from './Fonts';
import Icon from './Icon';

const BottomBarContainer = styled.div`
    width: 100%;

    background-color: ${(props) => props.theme.secondary};

    display: flex;
    align-items: center;
`;

const ModuleLinkContainer = styled(Link)<{ selected?: boolean }>`
    cursor: pointer;
    text-decoration: none;

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: ${(props) => props.selected && props.theme.secondaryLight};
    padding: 0.4em;
    padding-top: 0.2em;
`;

const ModuleLink = ({ selected, name, logo, to }: any) => {
    const colors = useColors();

    return (
        <ModuleLinkContainer selected={selected} to={to}>
            <Icon size={24} color="white" name={logo} />
            <ButtonLight color={colors.onSecondary} marginTop="0em">
                {name}
            </ButtonLight>
        </ModuleLinkContainer>
    );
};

export default () => {
    const { companyId } = useParams();
    const { pathname = '' } = useLocation();

    const isAdmin = useIsAdmin();

    return (
        <BottomBarContainer>
            {!!isAdmin && (
                <ModuleLink
                    name="Positions"
                    logo="briefcase"
                    to={`positions/${companyId}`}
                    selected={pathname.includes('position')}
                />
            )}
            <Flex
                w="100%"
                className="candidates-module-link" // for react-joyride
            >
                <ModuleLink
                    name="Candidates"
                    logo="user"
                    to={`candidates/${companyId}`}
                    selected={pathname.includes('candidate')}
                />
            </Flex>

            <FlexView grow />

            <ModuleLink
                name="Settings"
                logo="settings"
                to={`settings/${companyId}`}
                selected={pathname.includes('settings')}
            />
        </BottomBarContainer>
    );
};
