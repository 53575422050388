import { useEffect } from 'react';
import {
    useDisclosure,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Modal,
    Box,
} from '@chakra-ui/react';

import { useLocalStorage } from '../utils/hooks';
import YoutubeEmbed from './YoutubeEmbed';

const WELCOME_STORAGE_KEY = 'welcome_done';

const Welcome = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [welcomeDone, setWelcomeDone] = useLocalStorage(WELCOME_STORAGE_KEY);

    useEffect(() => {
        if (!welcomeDone) {
            setWelcomeDone(true);
            onOpen();
        }
    }, []);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Welcome!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb="6">
                        <Box mb="4">Watch this short tutorial video to get started.</Box>
                        <YoutubeEmbed embedId="rEmRRxEcmwo" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Welcome;
