import React from 'react';
import {
    useDisclosure,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    Button,
    Drawer,
    Input,
    IconButton,
    Heading,
    Divider,
} from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import YoutubeEmbed from './YoutubeEmbed';

export const HelpDrawer = ({ isOpen, onOpen, onClose }: any) => {
    return (
        <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>{/* Help */}</DrawerHeader>

                <DrawerBody>
                    <Heading size="sm" mb="4">
                        Watch this short clip to learn how to use Sieve
                    </Heading>
                    <YoutubeEmbed embedId="rEmRRxEcmwo" />

                    <Divider my="8" />

                    <Heading size="sm" mb="4">
                        Or if you need additional help
                    </Heading>
                    <a href="javascript:void(Tawk_API.toggle())">
                        <Button colorScheme="primaryLight">Chat with us</Button>
                    </a>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export const HelpDrawerIconButton = (props: any) => {
    const control = useDisclosure();

    return (
        <>
            <IconButton
                variant="ghost"
                color="gray.500"
                aria-label="Toggle help"
                icon={<QuestionOutlineIcon boxSize="6" />}
                onClick={control.onOpen}
                {...props}
            />
            <HelpDrawer {...control} />
        </>
    );
};

export default HelpDrawer;
