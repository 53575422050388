import { useParams } from 'react-router-dom';
import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    Box,
    Button,
    Switch,
    Tooltip,
    useClipboard,
} from '@chakra-ui/react';

import { CopyIcon, LinkIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { toast } from 'react-toastify';

import { useAuthState } from '../../context/auth';
import { useColors } from '../../styles/colors';
import useInterviewEvaluation from '../../api/hooks/useInterviewEvaluation';
import { SubHeading } from '../../components/Fonts';
import analytics from '../../utils/analytics';

const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
};

const PublicLink: React.FC = () => {
    const { companyId, interviewId, publicSlug } = useParams();
    const { authenticated } = useAuthState();

    const link = window.location.href;

    const { interview, setIsPublic } = useInterviewEvaluation({
        companyId,
        interviewId,
        publicSlug,
    });
    const isPublic = interview?.isPublic;

    const { hasCopied, onCopy } = useClipboard(link);
    const copyLink = () => {
        onCopy();
        toast.success('Link copied to clipboard!');
    };

    /** Option not available in public view */
    if (!authenticated) return <></>;

    return (
        <Flex
            align="center"
            m="2"
            mt="8"
            className="public-link-container" // for react-joyride
        >
            <SubHeading>Public Link</SubHeading>
            <Tooltip label={`Get a shareable public link for this interview`}>
                <InfoOutlineIcon ml="2" />
            </Tooltip>

            <Switch
                ml="6"
                // size="lg"
                colorScheme="gray"
                isChecked={isPublic}
                onChange={(e: any) => {
                    setIsPublic.mutate(e.target.checked);
                    e.target.checked && copyLink();
                }}
            />

            {!!isPublic && (
                <Button
                    onClick={() => {
                        analytics.event('Public Link', 'Link Copied', 'Button clicked');
                        onCopy();
                    }}
                    ml="4"
                    size="xs"
                    borderRadius="md"
                    colorScheme="purple"
                >
                    {hasCopied ? 'COPIED' : 'COPY'}
                </Button>
            )}
        </Flex>
    );
};

export default PublicLink;
