import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import usePermissions from '../../api/hooks/usePermissions';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import analytics from '../../utils/analytics';

const AcceptInvite = () => {
    const { permissionId, slug } = useParams();
    const { permission, accept, deny, isLoading } = usePermissions({ permissionId, slug });

    const navigate = useNavigate();
    useEffect(() => {
        if (permission?.status === 'ACTIVE') {
            if (permission?.role === 'ADMIN') {
                navigate(`/positions/${permission?.companyId}`);
            } else {
                navigate(`/candidates/${permission?.companyId}`);
            }
        }
    }, [permission]);

    if (isLoading) return <Loading />;

    return (
        <Box maxW="sm" mt="8">
            <Button
                style={{ width: '100%', margin: 0 }}
                onClick={() => {
                    analytics.event('Member Invite', 'Already Registered', 'Accepted');
                    accept();
                }}
            >
                ACCEPT INVITATION
            </Button>
            <Button
                style={{ width: '100%', margin: 0, marginTop: 15 }}
                onClick={() => {
                    analytics.event('Member Invite', 'Already Registered', 'Denied');
                    deny();
                }}
                color="foreground"
            >
                DENY
            </Button>
        </Box>
    );
};

export default AcceptInvite;
