import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    Navigate,
    Outlet,
    useLocation,
    RouteProps,
    Link as RouterLink,
} from 'react-router-dom';
import FlexView from 'react-flexview';
import { Box, Center, ChakraProvider, Heading, Text, Link, Flex } from '@chakra-ui/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';

import Positions from './pages/Positions';
import PositionsPromo from './pages/Positions/PositionsPromo';
import PositionOverview from './pages/Positions/PositionOverview';
import Candidates from './pages/Candidates';
import CandidatesPromo from './pages/Candidates/CandidatesPromo';
import Outro from './pages/CandidateEnd/Outro';

import Login from './pages/Login';
import Register from './pages/Register';

import Settings from './pages/Settings';
import MembersPromo from './pages/Settings/MembersPromo';
import Members from './pages/Settings/Members';

import SideBar from './components/SideBar';
import SideBarPromo from './components/SideBarPromo';
import BottomBar from './components/BottomBar';
import BottomBarPromo from './components/BottomBarPromo';

import {
    useCurrentPermission,
    useIsAdmin,
    useIsMobile,
    useIsReviewer,
    useLocalStorage,
    useWindowSize,
} from './utils/hooks';
import { ThemeProvider } from 'styled-components';
import themes from './styles/colors';
import { AuthProvider, useAuthState } from './context/auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import Onboarding from './pages/Onboarding';
import CandidateEvaluation from './pages/Candidates/CandidateEvaluation';
import Intro from './pages/CandidateEnd/Intro';
import HardwareTest from './pages/CandidateEnd/HardwareTest';
import Countdown from './pages/CandidateEnd/Countdown';
import Question from './pages/CandidateEnd/Question';
import {
    QUERY_REFETCH_TRIES,
    QUERY_RETRY_INTERVAL,
    QUERY_STALE_TIME,
    __DEV__,
} from './utils/constants';
import MemberInvite from './pages/MemberInvite';
import chakraTheme from './styles/ChakraTheme';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { ReactQueryDevtools } from 'react-query/devtools';
import Loading from './components/Loading';
import UserVerification from './pages/UserVerification';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/Login/ForgotPassword';
import { useEffect } from 'react';
import analytics from './utils/analytics';
import { AppProvider } from './context/app';
import { UploadSpeedWarning } from './pages/CandidateEnd/components';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: QUERY_STALE_TIME,
            retry: QUERY_REFETCH_TRIES,
            retryDelay: QUERY_RETRY_INTERVAL,
            refetchInterval: 60 * 10 * 1000,
            retryOnMount: false,
        },
    },
});

const NoPermission = () => {
    return (
        <Center height="100%" flexDir="column">
            <Flex align="center">
                <Heading fontSize="lg">Error</Heading>
                <Heading fontSize="2xl" mx="2">
                    |
                </Heading>
                <Text>You don't have permission to view this page</Text>
            </Flex>

            <Link as={RouterLink} to="/" color="purple.600" mt="8">
                Go to home page
            </Link>
        </Center>
    );
};

const NoMatch = () => {
    return (
        <Center height="100%" flexDir="column">
            <Flex align="center">
                <Heading fontSize="lg">404</Heading>
                <Heading fontSize="2xl" mx="2">
                    |
                </Heading>
                <Text>There is nothing here</Text>
            </Flex>

            <Link as={RouterLink} to="/" color="purple.600" mt="8">
                Go to home page
            </Link>
        </Center>
    );
};

const HomeLayout = () => {
    const isMobile = useIsMobile();
    // viewport height changes on mobile browsers when address bar hides
    const { height } = useWindowSize();

    return isMobile ? (
        <FlexView column height={height}>
            <Outlet />
            <FlexView grow />
            <BottomBar />
        </FlexView>
    ) : (
        <FlexView width="100%">
            <SideBar />
            <FlexView column grow height="100vh">
                <Outlet />
            </FlexView>
        </FlexView>
    );
};

const PromotionalLayout = () => {
    const isMobile = useIsMobile();

    // viewport height changes on mobile browsers when address bar hides
    const { height } = useWindowSize();

    return isMobile ? (
        <FlexView column height={height}>
            <Outlet />
            <FlexView grow />
            <BottomBarPromo />
        </FlexView>
    ) : (
        <FlexView width="100%">
            <SideBarPromo />
            <FlexView column grow height="100vh">
                <Outlet />
            </FlexView>
        </FlexView>
    );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const { authenticated, loading } = useAuthState();
    let location = useLocation();

    const { companyId } = useParams();
    const [lastOpenedCompanyId, setLastOpenedCompanyId] = useLocalStorage('LAST_OPENED_COMPANY_ID');

    useEffect(() => {
        companyId && setLastOpenedCompanyId(companyId);
    }, [companyId]);

    if (loading) return null;

    if (!authenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

const RequireOnboarding = ({ children }: { children: JSX.Element }) => {
    const { permissions, loading } = useAuthState();
    let location = useLocation();

    if (loading) return null;

    if (!permissions.length) {
        return <Navigate to={`/onboarding`} state={{ from: location }} replace />;
    }

    return children;
};

const RequireAdminPermission = ({ children }: { children: JSX.Element }) => {
    const { permissions, loading } = useAuthState();
    let location = useLocation();
    const isAdmin = useIsAdmin();

    if (loading) return null;

    if (!isAdmin) {
        return <Navigate to="/noop" state={{ from: location }} replace />;
    }

    return children;
};

const InitNavigation = () => {
    const permission = useCurrentPermission();
    const isAdmin = useIsAdmin();
    const companyId = permission?.companyId;

    if (permission?.status === 'INVITED')
        return <Navigate to={`/invite/${permission.id}/${permission.slug}`} replace />;
    else if (isAdmin) return <Navigate to={`/positions/${companyId}`} replace />;
    else return <Navigate to={`/candidates/${companyId}`} replace />;
};

const LayoutSelector = () => {
    const isReviewer = useIsReviewer();
    return isReviewer ? <PromotionalLayout /> : <HomeLayout />;
};

const Navigation = () => {
    const { authenticated, loading } = useAuthState();

    if (loading) return <Loading />;

    return (
        <Routes>
            <Route path="/" element={<InitNavigation />} />
            <Route path="/:companyId" element={<InitNavigation />} />

            <Route path="/login" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/callback/linkedin" element={<LinkedInCallback />} />
            <Route path="/register" element={<Register />} />

            <Route
                path="/onboarding"
                element={
                    <RequireAuth>
                        <Onboarding />
                    </RequireAuth>
                }
            />

            <Route
                element={
                    <RequireAuth>
                        <RequireOnboarding>
                            <LayoutSelector />
                        </RequireOnboarding>
                    </RequireAuth>
                }
            >
                <Route path="/positions/:companyId" element={<Positions />} />
                <Route
                    path="/positions/overview/:positionId/:companyId"
                    element={<PositionOverview />}
                />
                {/* <Route
                    path="/positions/questions/:positionId/:companyId"
                    element={<PositionQuestions />}
                /> */}

                <Route path="/candidates/:companyId" element={<Candidates />} />

                <Route path="/settings/:companyId" element={<Settings />} />
                <Route path="/settings/members/:companyId" element={<Members />} />

                <Route path="/requiredVerification" element={<UserVerification />} />
            </Route>

            <Route element={authenticated ? <LayoutSelector /> : <PromotionalLayout />}>
                <Route
                    path="/candidates/evaluation/:interviewId/:companyId/:publicSlug"
                    element={<CandidateEvaluation />}
                />
            </Route>

            <Route element={<PromotionalLayout />}>
                <Route path="/positions/promo" element={<PositionsPromo />} />
                <Route path="/candidates/promo" element={<CandidatesPromo />} />
                <Route path="/members/promo" element={<MembersPromo />} />
            </Route>

            <Route path="/interviews/:interviewId/:slug" element={<Intro />} />
            <Route path="/interviews/hardware/:interviewId/:slug" element={<HardwareTest />} />
            <Route path="/interviews/countdown/:interviewId/:slug" element={<Countdown />} />
            <Route path="/interviews/underway/:interviewId/:slug" element={<Question />} />
            <Route path="/interviews/outro/:interviewId/:slug" element={<Outro />} />

            <Route path="/invite/:permissionId/:slug" element={<MemberInvite />} />

            <Route path="/verification/:userId/:slug" element={<UserVerification />} />
            <Route path="/resetPassword/:userId/:slug" element={<ResetPassword />} />

            <Route path="/noop" element={<NoPermission />} />
            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
};

const App = () => {
    return (
        <ChakraProvider theme={chakraTheme}>
            <ThemeProvider theme={themes.light}>
                <BrowserRouter>
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <AppProvider>
                                <Navigation />
                            </AppProvider>
                        </AuthProvider>

                        <ReactQueryDevtools initialIsOpen={__DEV__} />
                    </QueryClientProvider>
                </BrowserRouter>

                {!!window.location.href.includes('interviews') && <UploadSpeedWarning />}

                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    // hideProgressBar
                    newestOnTop
                    closeOnClick
                    // rtl={false}
                    // pauseOnVisibilityChange
                    // draggable
                    // pauseOnHover
                    // style={{ zIndex: 10000 }} // GlobalModal has 9999
                />
            </ThemeProvider>
        </ChakraProvider>
    );
};

export default App;
