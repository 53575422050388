import { Avatar, Box, Flex, Button } from '@chakra-ui/react';
import { useState } from 'react';
import FlexView from 'react-flexview/lib';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useColors } from '../styles/colors';
import { useCurrentCompany, useIsReviewer } from '../utils/hooks';

import AppLogo from './AppLogo';
import { BodyLight, BodyText, ButtonText, SubHeading, SubTitle } from './Fonts';
import Icon from './Icon';

const SideBarContainer = styled.div`
    height: 100vh;
    width: 13%;
    min-width: 12em;

    background-color: ${(props) => props.theme.secondary};

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 1.8em;
    /* padding-bottom: 1em; */
`;

const ModuleLinkContainer = styled.div<{ selected?: boolean }>`
    cursor: pointer;
    text-decoration: none;

    width: 100%;

    display: flex;
    align-items: center;

    background-color: ${(props) => props.selected && props.theme.secondaryDark};
    padding: 0.5em 1em;
`;

const ModuleLink = ({ selected, name, logo, to, external }: any) => {
    const colors = useColors();

    const LinkComp = (props: any) => (external ? <a {...props} /> : <Link {...props} />);

    return (
        <LinkComp href={external && to} to={!external && to} target={external ? '_blank' : ''}>
            <ModuleLinkContainer selected={selected}>
                <Icon size={24} color="white" name={logo} />
                <SubHeading
                    color={colors.onSecondary}
                    marginLeft="0.5em"
                    style={{ fontWeight: 500 }}
                >
                    {name}
                </SubHeading>
            </ModuleLinkContainer>
        </LinkComp>
    );
};

export default () => {
    const { pathname = '' } = useLocation();
    const [evalLink, setEvalLink] = useState(pathname);

    const company = useCurrentCompany();
    const companyId = company.id;
    const isReviewer = useIsReviewer();

    return (
        <SideBarContainer>
            <a href="https://sievetalent.com/" target="_blank">
                <AppLogo color="white" size="md" ml="4" />
            </a>

            <FlexView grow column width="100%" marginTop="6em" marginBottom="2em">
                <ModuleLink
                    name="Positions"
                    logo="briefcase"
                    to={`positions/promo`}
                    selected={pathname.includes('position')}
                />

                <ModuleLink
                    name="Candidates"
                    logo="user"
                    to={isReviewer ? `candidates/${companyId}` : `candidates/promo`}
                    selected={pathname.includes('candidate') && !pathname.includes('evaluation')}
                />

                {!isReviewer && (
                    <ModuleLink
                        name="Evaluation"
                        logo="edit"
                        to={evalLink}
                        selected={pathname.includes('evaluation')}
                    />
                )}

                <ModuleLink
                    name={isReviewer ? 'Settings' : 'Members'}
                    logo="settings"
                    to={isReviewer ? `settings/${companyId}` : `members/promo`}
                    selected={pathname.includes('members') || pathname.includes('settings')}
                />

                <Flex flexGrow="1" />

                <Box m="4" mb="2" color="onSecondary.main" opacity={0.7}>
                    Learn more
                </Box>

                <ModuleLink
                    name="Get Started"
                    logo="play"
                    to={`https://sievetalent.com/`}
                    external
                />

                <ModuleLink
                    name="Features"
                    logo="star"
                    to={`https://sievetalent.com/features`}
                    external
                />

                <ModuleLink
                    name="About Us"
                    logo="heart"
                    to={`https://sievetalent.com/about`}
                    external
                />

                <ModuleLink
                    name="Schedule Demo"
                    logo="envelope"
                    to={`https://calendly.com/sievetalent/productdemo`}
                    external
                />
            </FlexView>
        </SideBarContainer>
    );
};
