import styled from 'styled-components';
import * as XLSX from 'xlsx';
import dimensions from '../../styles/dimensions';
import fonts from '../../styles/fonts';

export enum FileTypes {
    file = 'file',
    image = 'image',
    spreadsheet = 'spreadsheet',
}

type FilePickerProps = {
    type?: FileTypes;
    onChange?: any;
};

type InputProps = {
    minWidth?: string;
};

const StyledInput = styled.input<InputProps>`
    ${fonts.body}

    /* min-width:${(props) => props.minWidth || '5em'}; */
    /* flex-grow: 1; */

    color: ${(props) => props.theme.onBackground};
    ::placeholder {
        color: ${(props) => props.theme.onBackgroundLight};
    }

    background: ${(props) => props.theme.foreground};
    border: none;
    border-radius: ${dimensions.borderRadius};

    &:hover {
        background: ${(props) => props.theme.backgroundDark};
    }

    &:focus {
        background: ${(props) => props.theme.foregroundDark};
        outline: none;
    }

    padding: 1em;
    margin: 0.5em;
`;

const FilePicker = ({ type, onChange }: FilePickerProps) => {
    const isImage = type === 'image';
    const isSpreadsheet = type === 'spreadsheet';

    const readAsDataURL = async (file: any) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
        });
    };

    const readAsBinaryString = async (file: any) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = () => {
                resolve(reader.result);
            };
        });
    };

    const readFile = async (file: any) => {
        const result = isImage ? await readAsDataURL(file) : await readAsBinaryString(file);
        if (isSpreadsheet) {
            const workBook = XLSX.read(result, { type: 'binary', cellDates: true });
            const workSheet = workBook.Sheets[workBook.SheetNames[0]];
            return XLSX.utils.sheet_to_json(workSheet, { header: 1 });
        }
        return result;
    };

    const fileChosen = async (event: any) => {
        let input = event.target;
        let fileArray = [];

        if (input.files.length) {
            for (let i = 0; i < input.files.length; i++) {
                fileArray.push(await readFile(input.files[i]));
            }

            onChange && onChange(fileArray[0]);
        }
    };

    return (
        <StyledInput type={'file'} accept={isImage ? 'image/*' : undefined} onChange={fileChosen} />
    );
};

export default FilePicker;
