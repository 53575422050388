import { Avatar, Box } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import FlexView from 'react-flexview/lib';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Card from '../../components/Card';
import { BodyText, ButtonText, SubHeading } from '../../components/Fonts';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';
import ModuleScrollView from '../../components/ModuleScrollView';
import { Permission } from '../../types/schema';
import { DEMO_USER } from '../../utils/demoData';
import { useIsAdmin, useIsMobile, useIsReviewer } from '../../utils/hooks';
import Table from '../../components/Table';
import NavBar from '../../components/NavBar';
import DeleteMember from './DeleteMember';
import AddMember from './AddMember';
import useWorkspace from '../../api/hooks/useWorkspace';
import { Empty } from 'antd';
import Loading from '../../components/Loading';
import analytics from '../../utils/analytics';
import Tutorial from '../../components/Tutorial';
import { useAuthState } from '../../context/auth';
import EditMember from './EditMember';
import { MembersPromoCard } from './MembersPromo';

const MEMBERS_MAX_WIDTH = 1000;

const StyledMemberCard = styled(Card)`
    cursor: pointer;
    display: flex;
    background-color: ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.foregroundDark};
    /* border-bottom: 4px solid ${({ theme }) => theme.foreground}; */
`;

const MemberMobileCard = ({ name, email, role, permission, onClick }: any) => {
    const isAdmin = useIsAdmin();
    return (
        <StyledMemberCard onClick={onClick}>
            <Avatar name={name} />

            <FlexView column marginLeft="1em">
                <SubHeading marginBottom="0.2em">{name}</SubHeading>
                <BodyText marginBottom="0.2em">{email}</BodyText>
            </FlexView>

            <FlexView column grow vAlignContent="top" hAlignContent="right">
                <ButtonText size={12} marginLeft="0.5em" marginRight="0.5em" marginBottom="0.5em">
                    {role.toUpperCase()}
                </ButtonText>
                {isAdmin && <ActionsCell value={permission} />}
            </FlexView>
        </StyledMemberCard>
    );
};

const CandidateNameCell = ({ value }: { value: string }) => {
    return (
        <FlexView vAlignContent="center">
            <Avatar size="sm" name={value} />
            <ButtonText marginLeft="0.5em">{value?.toUpperCase()}</ButtonText>
        </FlexView>
    );
};

const ActionsCell: React.FC<{ value: Permission }> = ({ value }) => {
    return (
        <FlexView vAlignContent="center" onClick={(e) => e.stopPropagation()}>
            <EditMember permission={value} />
            <DeleteMember permission={value} />
        </FlexView>
    );
};

export default () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const { companyId } = useParams<{ companyId: string }>();
    const isMobile = useIsMobile();
    let navigate = useNavigate();

    const isAdmin = useIsAdmin();
    const isReviewer = useIsReviewer();

    const { permissions, isLoading } = useWorkspace(companyId);
    const members = permissions.map((p: Permission) => {
        return {
            id: p.id,
            name: p.user.name,
            role: p.role,
            status: p.status,
            email: p.user.email,
            createdAt: moment(p.createdAt).format('lll'),
            permission: p,
        };
    });

    const column_data = [
        { Header: 'Name', accessor: 'name', Cell: CandidateNameCell },
        { Header: 'Role', accessor: 'role' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Created Date', accessor: 'createdAt' },
        { Header: 'Status', accessor: 'status' },
    ];
    const columns = useMemo(
        () =>
            isAdmin
                ? [
                      ...column_data,
                      {
                          Header: 'Actions',
                          accessor: 'permission',
                          Cell: ActionsCell,
                          sortable: false,
                      },
                  ]
                : column_data,
        [],
    );

    return (
        <>
            <NavBar
                moduleName="Settings"
                tabs={[
                    {
                        name: 'General',
                        active: false,
                        link: `/settings/${companyId}`,
                    },
                    {
                        name: 'Members',
                        active: true,
                        link: `/settings/members/${companyId}`,
                    },
                ]}
            />

            <ModuleScrollView padding="0.5em">
                <ModuleMaxWidthContainer maxWidth={MEMBERS_MAX_WIDTH}>
                    {isLoading ? (
                        <Loading m="8" />
                    ) : !members?.length ? (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={'No members found'}
                            style={{ margin: '8em' }}
                        >
                            {isAdmin ? <AddMember /> : <></>}
                        </Empty>
                    ) : (
                        <FlexView
                            marginTop="0.5em"
                            marginBottom="2em"
                            marginRight="1em"
                            hAlignContent="right"
                        >
                            {isAdmin ? <AddMember /> : <></>}
                        </FlexView>
                    )}

                    {!isReviewer &&
                        (isMobile
                            ? members &&
                              members.map((member: any) => <MemberMobileCard {...member} />)
                            : !!members?.length && <Table columns={columns} data={members} />)}

                    {!!isReviewer && (
                        <Box w={[null, null, null, null, '120%']} mt="20" mb="60">
                            <MembersPromoCard />
                        </Box>
                    )}
                </ModuleMaxWidthContainer>
            </ModuleScrollView>

            <Tutorial
                delay={1000}
                tutorialKey={'MEMBERS_TUTORIAL_DONE'}
                steps={[
                    {
                        disableBeacon: true,
                        target: '.new-member-button',
                        content:
                            'Invite other people from your organisation and grant them appropriate access level.',
                    },
                ]}
            />
        </>
    );
};
