import GoogleLogin from 'react-google-login';
import { Button, Center, Text } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';

import { useAuthFunctions } from '../../context/auth';
import analytics from '../../utils/analytics';

const GOOGLE_CLIENT_ID = '432608589779-204isu4kvsmeg6ujjv0ue6oq9nd7o5cq.apps.googleusercontent.com';

export default ({ isRegistering }: any) => {
    const { googleLogin } = useAuthFunctions();

    return (
        <GoogleLogin
            render={(renderProps) => (
                <Button
                    // w={'full'}
                    maxW={'md'}
                    m="2"
                    borderRadius="sm"
                    fontSize="md"
                    variant={'outline'}
                    leftIcon={<FcGoogle />}
                    onClick={() => {
                        analytics.event('Login', 'Google', 'Button clicked');
                        renderProps.onClick();
                    }}
                >
                    <Center>
                        <Text>
                            {isRegistering ? 'Continue with Google' : 'Sign in with Google'}
                        </Text>
                    </Center>
                </Button>
            )}
            clientId={GOOGLE_CLIENT_ID}
            onSuccess={(res: any) => googleLogin.mutate(res?.tokenId)}
        />
    );
};
