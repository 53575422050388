import { Avatar, Box, Flex, Spacer, Wrap, Button as ChakraButton, Heading } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Empty } from 'antd';

import Button from '../../components/Button';
import Card from '../../components/Card';

import { BodyDark, BodyLight, BodyText, ButtonText, SubHeading } from '../../components/Fonts';
import CheckBox from '../../components/Form/CheckBox';
import Editable from '../../components/Form/Editable';
import Select from '../../components/Form/Select';
import Toggle from '../../components/Form/Toggle';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';
import ModuleScrollView from '../../components/ModuleScrollView';
import NavBar, { Tabs } from '../../components/NavBar';
import NavBarPromo from '../../components/NavBarPromo';
import VideoPlayer from '../../components/VideoPlayer';
import { useAuthState } from '../../context/auth';
import { useColors } from '../../styles/colors';
import dimensions from '../../styles/dimensions';
import { useCurrentRole, useIsMobile, useIsPublicView, useLocalStorage } from '../../utils/hooks';
import Loading from '../../components/Loading';
import Circle from '../../components/Circle';

import PublicLink from './PublicLink';
import SetVerdict from './SetVerdict';
import Rating, { CurrentRating, getColorForRating } from './Rating';
import { INTERVIEW_STATUSES, VERDICT_OPTIONS } from '../../utils/constants';
import { CommentType } from '../../types/global';
import AssignManager from './AssignManager';
import DeleteComment from './DeleteComment';
import useInterviewEvaluation from '../../api/hooks/useInterviewEvaluation';
import { InterviewStatus } from '../../types/schema';
import { GiRoundStar } from 'react-icons/gi';
import PageError from './PageError';
import RichTextInput from '../../components/Form/RichTextInput';
import analytics from '../../utils/analytics';
import Tutorial from '../../components/Tutorial';

const AUTOPLAY_STORAGE_KEY = 'autoplay_responses';

const EVALUATION_MAX_WIDTH = 1200;

const PublicModeCard = () => {
    return (
        <Box
            w="100%"
            bg="secondary.main"
            color="onSecondary.main"
            p="6"
            px="5%"
            mb="12"
            borderRadius="lg"
        >
            <Flex mx={[0, 0, 0, 0, 4]} mb="8">
                <Heading
                    color="onSecondary.main"
                    fontSize={['xl', 'xl', 'xl', 'xl']}
                    style={{ fontWeight: 500, flex: 1 }}
                >
                    Shortlist the
                    <br />
                    right fit candidate, faster
                </Heading>
                <Link to="/register" target="_blank">
                    <ChakraButton
                        size="md"
                        p="4"
                        // bg="primary.main"
                        colorScheme="green"
                        borderRadius="24"
                    >
                        Create Account
                    </ChakraButton>
                </Link>
            </Flex>
            You are viewing this interview in public mode. If you want to edit more things{' '}
            <Link to="/login">
                <Box as="span" color="primary.100" fontWeight="500">
                    login here.
                </Box>
            </Link>
        </Box>
    );
};

const HalfScreenWidth = styled.div`
    height: calc(100vh - ${dimensions.navBarHeight});
    width: 50%;
    overflow: auto;

    padding: 1em;
`;

const QuestionsContainer = styled.div`
    overflow: auto;

    margin-top: 0.5em;
`;

const ResponseContainer = styled.div<{ open?: boolean }>`
    cursor: pointer;

    background-color: ${(props) => props.open && props.theme.foreground};
    border-radius: 12px;
    padding: 1.2em;
`;

const typeOptions: any = [
    { label: 'Text', value: 'TEXT' },
    { label: 'Multiple Choice', value: 'MCQ' },
    // { label: 'Code', value: 'CODE' },
];

const ResponseCard = ({
    response,
    open,
    setOpen,
    autoplay,
    playerState,
    setPlayerState,
    speed,
}: any) => {
    const ref = useRef<null | HTMLDivElement>(null);
    const question = response.displayQuestion;

    const TitleFont = open ? BodyDark : BodyDark;
    const DescriptionFont = open ? BodyText : BodyLight;

    const mcqResponse = response?.mcqResponse || [];

    useEffect(() => {
        open && ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [open]);

    return (
        <ResponseContainer key={response.id} ref={ref} onClick={setOpen} open={open}>
            <FlexView marginBottom={open && '1em'}>
                <BodyDark>{`Q ${response.orderNumber + 1}`}</BodyDark>

                <FlexView column width="90%" marginLeft="0.5em">
                    <TitleFont>{question.title}</TitleFont>

                    <RichTextInput
                        readOnly
                        value={question.description}
                        style={{ marginTop: '0.5em' }}
                    />

                    {question.type === 'MCQ' &&
                        question?.mcqOptions?.map((o: any) => (
                            <CheckBox
                                readOnly
                                isChecked={mcqResponse.find((r: any) => r.label === o.label)}
                                value={o.value}
                                iconSize={40}
                                colorScheme="purple"
                                size="sm"
                                spacing="0.6rem"
                                marginTop="0.6rem"
                            >
                                {o.label}
                            </CheckBox>
                        ))}
                </FlexView>
            </FlexView>
        </ResponseContainer>
    );
};

const Responses: React.FC = () => {
    const { companyId, interviewId, publicSlug } = useParams();
    const { interview } = useInterviewEvaluation({ companyId, interviewId, publicSlug });
    const canEvaluate = !useIsPublicView();
    const isMobile = useIsMobile();

    const [openResponseIndex, setOpenResponseIndex] = useState(0);
    const [autoplay, setAutoplay] = useLocalStorage(AUTOPLAY_STORAGE_KEY, true);

    const [playerState, setPlayerState] = useState<any>({});
    const [speed, setSpeed] = useState(1);

    const responses = interview?.responses;
    const openResponse = responses[openResponseIndex];

    const playPrev = () => openResponseIndex > 0 && setOpenResponseIndex(openResponseIndex - 1);
    const playNext = () =>
        openResponseIndex < responses?.length - 1 && setOpenResponseIndex(openResponseIndex + 1);

    useEffect(() => {
        if (autoplay && playerState.ended) {
            playNext();
            setSpeed(playerState.playbackRate);
        }
    }, [playerState]);

    if (!interview) return <Loading />;

    return (
        <Flex h="100%" flexDir="column">
            <Flex mb="1rem">
                <SubHeading>Responses</SubHeading>
                <FlexView grow />
                <Toggle
                    label={'Autoplay'}
                    value={autoplay}
                    onChange={(e: any) => setAutoplay(e.target.checked)}
                />
            </Flex>

            <VideoPlayer
                url={openResponse?.viewUrl}
                autoplay={autoplay}
                playerState={playerState}
                setPlayerState={setPlayerState}
                speed={speed}
            />

            <Flex flexDir="column" overflow="auto" mt="2" maxH="60%">
                {responses?.map((response: any, index: number) => (
                    <ResponseCard
                        response={response}
                        open={index === openResponseIndex}
                        setOpen={() => setOpenResponseIndex(index)}
                        autoplay={autoplay}
                        speed={speed}
                        playerState={playerState}
                        setPlayerState={setPlayerState}
                    />
                ))}

                <Box mt="8" />

                {!!isMobile && !canEvaluate && <PublicModeCard />}
            </Flex>
        </Flex>
    );
};

const CandidateDetails = ({
    candidate,
    status,
    canEvaluate,
}: {
    candidate: any;
    status: InterviewStatus;
    canEvaluate: boolean;
}) => {
    const positionStatus = INTERVIEW_STATUSES[status];

    return (
        <Wrap align="center" spacing="1em">
            <Avatar size={'md'} name={candidate?.name} />

            <FlexView column>
                <SubHeading>{candidate?.name}</SubHeading>
                {!!canEvaluate && <BodyText>{candidate?.email}</BodyText>}

                <Flex align="center" mt="2">
                    <ButtonText size={12} marginLeft="0em" marginRight="0.5em">
                        {positionStatus?.label?.toUpperCase()}
                    </ButtonText>

                    <Circle color={positionStatus?.color} />
                </Flex>
            </FlexView>
        </Wrap>
    );
};

// const Card = styled.div`
//     background-color: ${({ theme }) => theme.background};
//     border: 1px solid ${({ theme }) => theme.foregroundDark};
//     border-bottom: 6px solid ${({ theme }) => theme.primaryLight};

//     border-radius: ${dimensions.borderRadius};

//     margin-right: 1em;
//     margin-bottom: 1em;
//     padding: 1.5em;
// `;

const InterviewTimeline = ({ interview }: any) => {
    return (
        <Card variant="outline" flex>
            <BodyText>Position</BodyText>
            <SubHeading>{interview?.position?.name}</SubHeading>

            <Flex minWidth="13em" width="100%" mt="1em">
                <BodyText>Sent</BodyText>
                <FlexView grow />
                <BodyText>{moment(interview?.createdAt).format('ll')}</BodyText>
            </Flex>

            <Flex minWidth="13em" width="100%">
                <BodyText>Deadline</BodyText>
                <FlexView grow />
                <BodyText>
                    {interview?.deadline ? moment(interview?.deadline).format('ll') : 'None'}
                </BodyText>
            </Flex>

            <Flex minWidth="13em" width="100%">
                <BodyText>Completed</BodyText>
                <FlexView grow />
                <BodyText>
                    {interview?.completedAt ? moment(interview?.completedAt).format('ll') : 'NA'}
                </BodyText>
            </Flex>
        </Card>
    );
};

const ManagerDetails = ({ interview }: any) => {
    const { assignedTo, verdict } = interview;
    const role = useCurrentRole();
    const status = INTERVIEW_STATUSES[verdict as InterviewStatus];

    return (
        <Card variant="outline" flex>
            <BodyText>Hiring Manager</BodyText>

            <FlexView vAlignContent="center">
                {/* <Avatar size="xs" name={user?.name} /> */}
                <SubHeading>{assignedTo?.name || 'No manager found'}</SubHeading>
                {(role === 'OWNER' || role === 'ADMIN') && (
                    <AssignManager variant="link" interview={interview} />
                )}
            </FlexView>

            <BodyText marginTop="1em">Verdict</BodyText>
            <SubHeading
            // color={verdict ? status.color : undefined}
            >
                {verdict || 'Under Review'}
            </SubHeading>
        </Card>
    );
};

const CommentDecoration = styled.div`
    width: 2px;
    display: flex;
    flex-grow: 1;
    background-color: ${(props) => props.theme.foregroundDark};
`;

const Comment: React.FC<{ comment: CommentType; isLast: boolean }> = ({ comment, isLast }) => {
    const { user } = useAuthState();

    switch (comment.type) {
        case undefined:
        case 'TEXT':
            return (
                <FlexView>
                    <FlexView column hAlignContent="center">
                        <Avatar size={'sm'} name={comment.user.name} />
                        {!isLast && <CommentDecoration />}
                    </FlexView>

                    <FlexView column marginLeft="1em" marginBottom="2em" grow>
                        <Flex align="flex-end">
                            <Box>
                                <BodyDark>{comment.user.name}</BodyDark>

                                <BodyLight marginTop="0.2em" color="gray">
                                    {moment(comment.createdAt).format('lll')}
                                </BodyLight>
                            </Box>

                            {/* <Spacer /> */}
                            {comment.user.id === user?.id && <DeleteComment comment={comment} />}
                        </Flex>

                        <BodyText marginTop="0.5em" whiteSpace="pre-wrap">
                            {comment.text}
                        </BodyText>
                    </FlexView>
                    {/* Current just a button, add better button and a confirmation dialogue */}
                    {/* <button onClick={deleteComment}>DEL</button> */}
                </FlexView>
            );

        case 'ASSIGNMENT':
            return (
                <FlexView>
                    <FlexView column hAlignContent="center">
                        <Avatar size={'sm'} name={comment.user.name} />
                        {!isLast && <CommentDecoration />}
                    </FlexView>
                    <FlexView column marginLeft="1em" marginBottom="2em" grow>
                        <BodyDark>{comment.user.name}</BodyDark>
                        <BodyLight marginTop="0.2em">
                            {moment(comment.createdAt).format('lll')}
                        </BodyLight>
                        <FlexView marginTop="0.5em">
                            <BodyText>
                                {`Changed Hiring Manager ${
                                    comment.assignedFrom?.name ? 'from' : ''
                                }`}
                            </BodyText>
                            {comment.assignedFrom && (
                                <BodyDark style={{ paddingLeft: 3, paddingRight: 3 }}>
                                    {comment.assignedFrom?.name}
                                </BodyDark>
                            )}
                            <BodyText style={{ paddingLeft: 3, paddingRight: 3 }}>{`to`}</BodyText>
                            <BodyDark style={{ paddingLeft: 3, paddingRight: 3 }}>
                                {comment.assignedTo?.name}
                            </BodyDark>
                        </FlexView>
                    </FlexView>
                </FlexView>
            );

        case 'VERDICT':
            return (
                <FlexView>
                    <FlexView column hAlignContent="center">
                        <Avatar size={'sm'} name={comment.user.name} />
                        {!isLast && <CommentDecoration />}
                    </FlexView>
                    <FlexView column marginLeft="1em" marginBottom="2em" grow>
                        <BodyDark>{comment.user.name}</BodyDark>
                        <BodyLight marginTop="0.2em">
                            {moment(comment.createdAt).format('lll')}
                        </BodyLight>
                        <FlexView marginTop="0.5em">
                            <BodyText>{`Marked the interview verdict as`}</BodyText>
                            <BodyDark style={{ paddingLeft: 3 }}>{comment.text}</BodyDark>
                        </FlexView>
                    </FlexView>
                </FlexView>
            );

        case 'RATING':
            const color = getColorForRating(comment.rating);
            return (
                <FlexView>
                    <FlexView column hAlignContent="center">
                        <Avatar size={'sm'} name={comment.user.name} />
                        {!isLast && <CommentDecoration />}
                    </FlexView>
                    <FlexView column marginLeft="1em" marginBottom="2em" grow>
                        <BodyDark>{comment.user.name}</BodyDark>
                        <BodyLight marginTop="0.2em">
                            {moment(comment.createdAt).format('lll')}
                        </BodyLight>
                        <FlexView marginTop="0.5em" vAlignContent="center">
                            <BodyText>{`Gave this interview a rating of `}</BodyText>
                            <BodyDark style={{ paddingLeft: 3, paddingRight: 2 }} color={color}>
                                {comment.rating}
                            </BodyDark>
                            <GiRoundStar size={14} color={color} />
                        </FlexView>
                    </FlexView>
                </FlexView>
            );
    }
};

const Separator = styled.div`
    height: 0.1em;
    width: 100%;

    background-color: ${(props) => props.theme.foregroundDark};
`;

const Comments = () => {
    const { companyId, interviewId, publicSlug } = useParams();
    const [commentText, setCommentText] = useState<string | undefined>();

    const { interview } = useInterviewEvaluation({
        companyId,
        interviewId,
        publicSlug,
    });

    const { addText } = useInterviewEvaluation({
        companyId,
        interviewId,
        publicSlug,
    });

    const createComment = () => {
        addText.mutate(commentText as string);
    };

    useEffect(() => {
        if (addText.isSuccess) {
            setCommentText(undefined);
            addText.reset();
        }
    }, [addText.isSuccess]);

    const { authenticated } = useAuthState();

    if (!interview) return <>Loading</>;

    return (
        <FlexView column marginTop="3em" marginLeft="0em">
            <SubHeading marginBottom="0.5em" marginLeft="0.5em">
                Comments
            </SubHeading>

            {authenticated && (
                <Editable
                    className="comment-input" // for react-joyride
                    name="comment"
                    value={commentText || ''}
                    onChange={(e: any) => setCommentText(e.target.value)}
                    minRows={3}
                    placeholder="Add a comment..."
                />
            )}

            {commentText && (
                <Flex justify="flex-end">
                    <Button color="secondary" onClick={createComment}>
                        POST COMMENT
                    </Button>
                </Flex>
            )}

            <FlexView column marginTop="2em">
                {!interview.comments?.length && (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={'No comments yet'}
                        style={{ margin: '4em' }}
                    />
                )}
                {interview.comments?.map((c: CommentType, index: number) => (
                    <Comment
                        key={c.id}
                        comment={c}
                        isLast={index === interview.comments.length - 1}
                    />
                ))}
            </FlexView>
        </FlexView>
    );
};

const Info: React.FC = () => {
    const { companyId, interviewId, publicSlug } = useParams();
    const { interview } = useInterviewEvaluation({ companyId, interviewId, publicSlug });

    const canEvaluate = !useIsPublicView();

    if (!interview) return <Loading />;

    return (
        <>
            {!canEvaluate && <PublicModeCard />}

            <Flex align="center" ml="2">
                <CandidateDetails
                    candidate={interview.candidate}
                    status={interview.status}
                    canEvaluate={canEvaluate}
                />

                <FlexView grow />

                <FlexView column>
                    {!canEvaluate ? (
                        <Flex m="0.5em">
                            <Spacer />
                            <CurrentRating
                                rating={interview.averageRating}
                                count={interview.totalRatings}
                            />
                        </Flex>
                    ) : (
                        <SetVerdict interview={interview} />
                    )}
                </FlexView>
            </Flex>

            <FlexView wrap marginTop="2em" marginLeft="0.5em">
                <InterviewTimeline interview={interview} />
                <ManagerDetails interview={interview} />
            </FlexView>

            <PublicLink />

            {canEvaluate && <Rating interview={interview} />}

            <Comments />
        </>
    );
};

export const CandidateEvaluation: React.FC = () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const { companyId, interviewId, publicSlug } = useParams();
    const isMobile = useIsMobile();

    const [searchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'responses');
    const isInfoActive = activeTab === 'info';

    const { interview, errors } = useInterviewEvaluation({ companyId, interviewId, publicSlug });

    const isPublicView = useIsPublicView();

    if (!interview && !Object.keys(errors).length) return <Loading />;

    if (Object.keys(errors).length) {
        if (errors.notPublic) return <PageError />;
        return <div>{errors.notPublic}</div>;
    }

    const tabs = [
        {
            name: 'Responses',
            active: !isInfoActive,
            onClick: () => setActiveTab('responses'),
        },
        {
            name: 'Review',
            active: isInfoActive,
            onClick: () => setActiveTab('info'),
        },
    ];

    return (
        <>
            {isPublicView ? (
                <>
                    {!isMobile ? (
                        <NavBarPromo />
                    ) : (
                        <Box className="evaluation-tabs" mt="8" borderBottom="1px solid lightgray">
                            <Tabs tabs={tabs} />
                        </Box>
                    )}
                </>
            ) : (
                <NavBar
                    moduleName="Evaluate"
                    tabs={isMobile ? tabs : []}
                    hideHelp={isMobile}
                    hideProfile={isMobile}
                />
            )}

            <ModuleScrollView padding="0px">
                <ModuleMaxWidthContainer height="100%" width="100%" maxWidth={EVALUATION_MAX_WIDTH}>
                    {isMobile ? (
                        <Box px="4" pt="6" h="100%">
                            {isInfoActive ? <Info /> : <Responses />}
                        </Box>
                    ) : (
                        <FlexView>
                            <HalfScreenWidth>
                                <Info />
                            </HalfScreenWidth>

                            <HalfScreenWidth>
                                <Responses />
                            </HalfScreenWidth>
                        </FlexView>
                    )}
                </ModuleMaxWidthContainer>
            </ModuleScrollView>

            <Tutorial
                delay={1000}
                tutorialKey={'PUBLIC_LINK_TUTORIAL_DONE'}
                locale={{ last: 'Ok' }}
                steps={[
                    {
                        disableBeacon: true,
                        target: '.evaluation-tabs',
                        content: 'Switch between Responses and Review from here.',
                    },
                ]}
            />

            <Tutorial
                delay={1000}
                tutorialKey={'EVALUATION_TUTORIAL_DONE'}
                steps={[
                    {
                        disableBeacon: true,
                        target: '.rating-input',
                        content:
                            'Rate a candidate from 1 to 5, based on how well they performed in the interview.',
                    },
                    {
                        target: '.comment-input',
                        content:
                            'And leave a comment about what you liked or disliked. You can also see comments by other evaluators here.',
                    },
                    {
                        target: '.verdict-buttons',
                        content:
                            'Finally accept or reject a candidate. This can only be done by the hiring manager for this candidate.',
                    },
                    {
                        target: '.public-link-container',
                        content:
                            'You can also get a shareable public link for this interview from here.',
                    },
                ]}
            />
        </>
    );
};

export default CandidateEvaluation;
