import { Center, Flex, Box, Image } from '@chakra-ui/react';

import AddPosition from './AddPosition';
import { BodyText, SubHeading } from '../../components/Fonts';

export default () => {
    return (
        <Center flexWrap="wrap" height="100%" m="16">
            <Center maxW="sm" textAlign="center" flexDir="column">
                <SubHeading>Job Positions</SubHeading>
                <BodyText marginTop="0.5rem">
                    You currently have no positions open in your company. Add one to create an
                    interview format.
                </BodyText>

                <Flex mt="16">
                    <AddPosition />
                </Flex>
            </Center>

            <Image src="/5.svg" maxH="12rem" ml="16" />
        </Center>
    );
};
