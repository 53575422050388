import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useParams } from 'react-router-dom';
import { Box, Flex, Stack, VStack, Wrap } from '@chakra-ui/react';
import styled from 'styled-components';

import Button from '../../components/Button';
import { SubHeading } from '../../components/Fonts';
import Input from '../../components/Form/Input';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';
import ModuleScrollView from '../../components/ModuleScrollView';
import NavBar from '../../components/NavBar';
import ImageUpload from '../../components/Form/ImageUpload';
import { useCurrentCompany, useCurrentPermission } from '../../utils/hooks';
import { useAuthState } from '../../context/auth';
import useUser from '../../api/hooks/useUser';
import ColorPicker from '../../components/Form/ColorPicker';
import useCompany from '../../api/hooks/useCompany';
import ErrorString from '../../components/ErrorString';
import MessageBox from '../../components/MessageBox';
import analytics from '../../utils/analytics';

const PROFILE_MAX_WIDTH = 900;

const SectionContainer = styled(Flex)`
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    /* min-width: 20rem; */
    max-width: 28rem;
`;

const SectionHeading = styled(SubHeading)`
    margin: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-grow: 1;
`;

const Profile = () => {
    const { user } = useAuthState();
    const { uploadProfilePicture, updateData } = useUser();

    const image = user?.picture?.[0] || '';
    const [name, setName] = useState(user?.name);

    const saveUpdates = () => {
        updateData.mutate({ name });
    };

    return (
        <SectionContainer>
            <SectionHeading>Profile</SectionHeading>

            <ImageUpload
                isAvatar
                value={image}
                onChange={uploadProfilePicture.mutate}
                isLoading={uploadProfilePicture.isLoading}
            />

            <Input name="email" value={user?.email} placeholder="Email" label="Email" disabled />

            <Input
                name="name"
                value={name}
                placeholder="Name"
                label="Name"
                onChange={(e: any) => setName(e.target.value)}
            />

            <Button
                marginTop="1em"
                disabled={user?.name === name}
                isLoading={updateData.isLoading}
                loadingText="SAVING"
                onClick={saveUpdates}
            >
                SAVE CHANGES
            </Button>
        </SectionContainer>
    );
};

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState<string>();
    const [newPassword, setNewPassword] = useState<string>();

    const { user } = useAuthState();
    const [confirmPassword, setConfirmPassword] = useState<string>();

    const { changePassword, setPassword, errors, setErrors } = useUser();

    const changeUserPassword = () => {
        if (newPassword !== confirmPassword) {
            setErrors({ confirmPassword: 'Passwords do not match' });
            return;
        }

        changePassword.mutate({ newPassword, oldPassword });
    };

    const setUserPassword = () => {
        if (newPassword !== confirmPassword) {
            setErrors({ confirmPassword: 'Passwords do not match' });
            return;
        }

        setPassword.mutate(newPassword);
    };

    useEffect(() => {
        if (changePassword.isSuccess) {
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            changePassword.reset();
        }
    }, [changePassword.isSuccess]);

    useEffect(() => {
        if (setPassword.isSuccess) {
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setPassword.reset();
        }
    }, [setPassword.isSuccess]);

    if (!user) return <></>;

    return user.hasPassword ? (
        <SectionContainer>
            <SectionHeading>Change Password</SectionHeading>

            {!errors.message ? (
                <MessageBox
                    status="info"
                    description={`Upon changing password, you'll be logged out of all other devices except this.`}
                    title=""
                />
            ) : (
                <MessageBox status="error" description={errors.message} />
            )}

            <Input
                name="oldPassword"
                type="password"
                value={oldPassword}
                placeholder="Current Password"
                label="Current Password"
                onChange={(e: any) => setOldPassword(e.target.value)}
                error={errors.oldPassword}
            />

            <Input
                name="newPassword"
                type="password"
                value={newPassword}
                placeholder="New Password"
                label="New Password"
                onChange={(e: any) => setNewPassword(e.target.value)}
                error={errors.newPassword}
            />

            <Input
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                placeholder="Confirm Password"
                label="Confirm Password"
                onChange={(e: any) => setConfirmPassword(e.target.value)}
                error={errors.confirmPassword}
            />

            <Flex flexDir="column" mt="1em">
                <Button
                    disabled={!newPassword}
                    isLoading={changePassword.isLoading}
                    loadingText="UPDATING"
                    onClick={changeUserPassword}
                >
                    UPDATE PASSWORD
                </Button>
            </Flex>
        </SectionContainer>
    ) : (
        <SectionContainer>
            <SectionHeading>Set Password</SectionHeading>

            <MessageBox
                status="info"
                description={`You've logged in through ${user.registerMethod}, but you can still set a password. Don't worry, you'll still be able to access from ${user.registerMethod}`}
            />

            <Input
                name="newPassword"
                type="password"
                value={newPassword}
                placeholder="New Password"
                onChange={(e: any) => setNewPassword(e.target.value)}
                error={errors.newPassword}
            />

            <Input
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                placeholder="Confirm Password"
                onChange={(e: any) => setConfirmPassword(e.target.value)}
                error={errors.confirmPassword}
            />

            <Flex flexDir="column" mt="1em">
                {errors.message && <MessageBox status="error" description={errors.message} />}
                <Button
                    flex="1"
                    disabled={!newPassword}
                    isLoading={setPassword.isLoading}
                    loadingText="UPDATING"
                    onClick={setUserPassword}
                >
                    SET PASSWORD
                </Button>
            </Flex>
        </SectionContainer>
    );
};

const Company = () => {
    const permission = useCurrentPermission();

    const defaultValues = {
        jobTitle: permission?.jobTitle,
        name: permission?.company.name || '',
        themeColor: permission?.company.themeColors?.[0] || '',
    };

    const [jobTitle, setJobTitle] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [themeColor, setThemeColor] = useState<string>('');

    useEffect(() => {
        setJobTitle(defaultValues.jobTitle);
        setName(defaultValues.name);
        setThemeColor(defaultValues.themeColor);
    }, [permission]);

    const { update, errors, uploadLogo, setErrors } = useCompany();

    const { mutate, isSuccess, isLoading, reset } = update;

    const { companyId } = useParams();
    const updateCompany = () => {
        analytics.event('Company Data', 'Settings Page', undefined);
        mutate({ name, jobTitle, themeColor, companyId: parseInt(companyId as string) });
    };

    useEffect(() => {
        if (isSuccess) {
            reset();
        }
    }, [isSuccess]);

    const image = permission?.company.logos?.[0] || '';
    const uploadCompanyLogo = (file: any) => {
        setErrors({});
        analytics.event('Company Logo', 'Settings Page', 'Image Uploaded');
        uploadLogo.mutate({ file, companyId: parseInt(companyId as string) });
    };

    return (
        <SectionContainer>
            <SectionHeading>Company Details</SectionHeading>

            <ImageUpload
                value={image}
                onChange={uploadCompanyLogo}
                isLoading={uploadLogo.isLoading}
                label="Logo (min 320x180)"
            />

            <Input
                name="name"
                placeholder="Company Name"
                label="Company Name"
                value={name}
                onChange={(e) => {
                    setErrors({});
                    setName(e.target.value);
                }}
            />
            <ErrorString error={errors.name} />

            <Input
                name="position"
                placeholder="Your Position"
                label="Your Position"
                value={jobTitle}
                onChange={(e) => {
                    setErrors({});
                    setJobTitle(e.target.value);
                }}
            />
            <ErrorString error={errors.jobTitle} />

            <ColorPicker
                label={'Brand Color'}
                value={themeColor}
                onChange={(color: string) => {
                    setErrors({});
                    setThemeColor(color);
                }}
            />
            {errors.themeColor ? (
                <ErrorString error={errors.themeColor} />
            ) : (
                <Box ml="2" mb="4" color="gray">
                    Input the primary color of your brand
                </Box>
            )}

            <Button
                disabled={
                    name === defaultValues.name &&
                    themeColor === defaultValues.themeColor &&
                    jobTitle === defaultValues.jobTitle
                }
                isLoading={isLoading}
                loadingText={'UPDATING'}
                onClick={updateCompany}
                marginTop="1em"
            >
                UPDATE
            </Button>
        </SectionContainer>
    );
};

export default () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const permission = useCurrentPermission();
    const { companyId, role } = permission;

    const generalTab = {
        name: 'General',
        active: true,
        link: `/settings/${companyId}`,
    };

    const memberTab = {
        name: 'Members',
        active: false,
        link: `/settings/members/${companyId}`,
    };

    return (
        <>
            <NavBar moduleName="Settings" tabs={[generalTab, memberTab]} />

            <ModuleScrollView padding="0.5em">
                <ModuleMaxWidthContainer maxWidth={PROFILE_MAX_WIDTH}>
                    <Stack
                        spacing="6rem"
                        flex="1"
                        alignItems="center"
                        paddingTop={'3em'}
                        paddingBottom={'10em'}
                    >
                        <Profile />
                        {role === 'OWNER' && <Company />}
                        <ChangePassword />
                    </Stack>
                </ModuleMaxWidthContainer>
            </ModuleScrollView>
        </>
    );
};
