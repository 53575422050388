import {
    Box,
    Flex,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Spacer,
} from '@chakra-ui/react';
import { ChromePicker } from 'react-color';

import dimensions from '../../styles/dimensions';
import FormField from './FormField';
import { useColors } from '../../styles/colors';
import { fontSizes } from '../../styles/fonts';

const ColorPicker = ({ label, value, error, placeholder, onChange, ...otherProps }: any) => {
    const colors = useColors();

    return (
        <Popover>
            <FormField label={label} error={error}>
                <PopoverTrigger>
                    <Flex
                        bg="foreground.main"
                        borderRadius={dimensions.borderRadius}
                        overflow="hidden"
                        cursor="pointer"
                    >
                        <Box
                            p="0.8em"
                            color={(!value && colors.onBackgroundLight) || undefined}
                            fontSize={fontSizes.md}
                        >
                            {value || placeholder || 'Select'}
                        </Box>
                        <Spacer />
                        <Spacer
                            maxW="4rem"
                            ml="0.5em"
                            bg={value}
                            borderWidth="1px"
                            borderColor="foregroundDark.main"
                        />
                    </Flex>
                </PopoverTrigger>
            </FormField>

            <PopoverContent boxSize={-1}>
                <PopoverArrow />
                <ChromePicker
                    {...otherProps}
                    color={value}
                    onChangeComplete={(c: any) => onChange(c.hex)}
                />
            </PopoverContent>
        </Popover>
    );
};

export default ColorPicker;
