import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import Button from './components/button';

const chakraTheme = extendTheme({
    config: {
        initialColorMode: 'light',
    },

    styles: {
        global: (props: any) => ({
            body: {
                bg: 'background.main',
            },

            h1: {
                fontSize: '2xl',
            },
            h2: {
                fontSize: 'xl',
            },
            h3: {
                fontSize: 'lg',
            },
            h4: {
                fontSize: 'md',
            },
        }),
    },

    colors,

    fonts: {
        heading: 'Inter, sans-serif',
        body: 'Inter, sans-serif',
    },

    components: {
        Button,
    },
});

export default chakraTheme;
