import styled, { css } from 'styled-components';
import fonts, { fontSize } from '../../styles/fonts';

interface FontDivProps {
    height?: string;
    width?: string;

    margin?: string;
    marginLeft?: string;
    marginRight?: string;
    marginTop?: string;
    marginBottom?: string;

    color?: string;
    size?: number;
    weight?: string;

    textAlign?: string;
    whiteSpace?: string;
}

const baseFontCss = css<FontDivProps>`
    height: ${({ height }) => height};
    width: ${({ width }) => width};

    margin: ${({ margin }) => margin};
    margin-left: ${({ marginLeft }) => marginLeft};
    margin-right: ${({ marginRight }) => marginRight};
    margin-top: ${({ marginTop }) => marginTop};
    margin-bottom: ${({ marginBottom }) => marginBottom};

    color: ${(props) => props.color};
    font-size: ${(props) => props.size && fontSize(props.size)};
    font-weight: ${(props) => props.weight};

    text-align: ${(props) => props.textAlign};
    white-space: ${(props) => props.whiteSpace};
`;

export const Title = styled.div<FontDivProps>`
    ${fonts.title}
    ${baseFontCss}
`;

export const SubTitle = styled.div<FontDivProps>`
    ${fonts.subTitle}
    ${baseFontCss}
`;

export const Heading = styled.div<FontDivProps>`
    ${fonts.heading}
    ${baseFontCss}
`;

export const SubHeading = styled.div<FontDivProps>`
    ${fonts.subHeading}
    ${baseFontCss}
`;

export const BodyText = styled.div<FontDivProps>`
    ${fonts.body}
    ${baseFontCss}
`;

export const BodyLight = styled.div<FontDivProps>`
    ${fonts.bodyLight}
    ${baseFontCss}
`;

export const BodyDark = styled.div<FontDivProps>`
    ${fonts.bodyDark}
    ${baseFontCss}
`;

export const ButtonText = styled.div<FontDivProps>`
    ${fonts.button}
    ${baseFontCss}
`;

export const ButtonLight = styled.div<FontDivProps>`
    ${fonts.buttonLight}
    ${baseFontCss}
`;
