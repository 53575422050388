import FlexView from 'react-flexview/lib';
import styled from 'styled-components';

import AppLogo from '../../components/AppLogo';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';
import ModuleScrollView from '../../components/ModuleScrollView';
import dimensions from '../../styles/dimensions';

import { useParams, useNavigate } from 'react-router-dom';
import PermissionDetails from './InvitationDetails';
import RegisterMember from './RegisterMember';
import AcceptInvite from './AcceptInvite';
import Loading from '../../components/Loading';
import usePermissions from '../../api/hooks/usePermissions';
import { useEffect, useState } from 'react';
import { useCurrentPermission } from '../../utils/hooks';
import { useAuthFunctions } from '../../context/auth';
import analytics from '../../utils/analytics';
import LoginArt from '../Login/LoginArt';
import { Image } from '@chakra-ui/react';

const NAVBAR_MAX_WIDTH = 700;
const NAVBAR_HEIGHT = '5rem';

const NavBarContainer = styled.div`
    height: ${NAVBAR_HEIGHT};

    background-color: ${({ theme }) => theme.background};

    border-bottom: 1px solid ${({ theme }) => theme.foregroundDark};

    padding: 0.5em 1em 1em 1em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const OnboardingNavBar = () => {
    return (
        <NavBarContainer>
            <Image boxSize={['1.4rem', '2rem', '2.2rem']} src="/logo_sq_dark.svg" />
        </NavBarContainer>
    );
};

const OnboardingScrollView = styled(ModuleScrollView)`
    height: calc(100% - ${NAVBAR_HEIGHT});
    display: flex;
    flex-direction: column;
`;

export default () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const { permissionId, slug } = useParams();
    const { permission, isRegistered, isLoading, errors } = usePermissions({ permissionId, slug });
    const { logout } = useAuthFunctions();

    const currentPermission = useCurrentPermission();
    const [logoutError, setLogoutError] = useState<boolean>(false);

    useEffect(() => {
        if (currentPermission && permission) {
            if (currentPermission.id !== permission.id) {
                setLogoutError(true);
            }
        }
    }, [currentPermission, permission]);

    if (isLoading) return <Loading />;

    return (
        <FlexView height="100%" width="100%">
            <LoginArt />

            <FlexView column width="100%">
                <OnboardingNavBar />

                {permission ? (
                    <OnboardingScrollView>
                        <PermissionDetails />
                        {isRegistered ? <AcceptInvite /> : <RegisterMember />}
                    </OnboardingScrollView>
                ) : errors.message ? (
                    <div>{errors.message}</div>
                ) : logoutError ? (
                    <>
                        <div>{'Permission Conflict, Logout to fix'}</div>
                        <div onClick={() => logout.mutate()} style={{ cursor: 'pointer' }}>
                            LOGOUT
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </FlexView>
        </FlexView>
    );
};
