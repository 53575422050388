import { useEffect, useState } from 'react';
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ErrorType } from '../../types/global';
import { InterviewCandidate, Position } from '../../types/schema';
import { showChangesSavedToast } from '../../utils';
import useAxios from './useAxios';

interface PositionUpdateParams {
    id: number;
    name: string;
    jobDescription?: string;
    [key: string]: any;
}

interface PositionCreateArgs {
    name: string;
}

const useManyPositions = (companyId: string | undefined) => {
    const [errors, setErrors] = useState<ErrorType>();

    const { api, isAuthenticated } = useAxios();

    const getManyPositions = async ({
        queryKey: [_, companyId],
    }: QueryFunctionContext): Promise<{ positions: Array<Position>; stats: any[] }> => {
        const { data } = await api.get('/positions/getMany', { params: { companyId } });
        return data;
    };

    const { data: responseData, isLoading } = useQuery({
        queryKey: ['positions', companyId],
        queryFn: getManyPositions,
        enabled: !!companyId && isAuthenticated,
        onError: (error: any) => setErrors(error.response.data),
    });

    const createPosition = async (createData: PositionCreateArgs): Promise<Position> => {
        const { data } = await api.post('/positions/create', {
            companyId: parseInt(companyId as string),
            ...createData,
        });
        return data.createdPosition;
    };

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const create = useMutation(createPosition, {
        onMutate: () => setErrors({}),
        onSuccess: (createdPosition) => {
            queryClient.setQueryData(['positions', companyId], (data: any) => {
                const positions = [...data.positions, createdPosition];
                return { ...data, positions };
            });

            navigate(`/positions/overview/${createdPosition.id}/${companyId}`);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const positions = responseData?.positions;
    const stats = responseData?.stats;

    return {
        positions: positions || [],
        create,
        errors,
        setErrors,
        isLoading,
        stats,
    };
};

const usePosition = ({
    companyId,
    positionId,
}: {
    companyId: string | undefined;
    positionId: string | undefined;
}) => {
    const [errors, setErrors] = useState<ErrorType>();

    const { api } = useAxios();
    const { positions, isLoading } = useManyPositions(companyId);

    const updatePosition = async (updateData: any): Promise<Position> => {
        const { data } = await api.put('/positions/update', {
            companyId: parseInt(companyId as string),
            ...updateData,
        });
        return data.updatedPosition;
    };

    const archivePosition = async (): Promise<Position> => {
        const { data } = await api.put('/positions/archive', {
            companyId: parseInt(companyId as string),
            positionId: parseInt(positionId as string),
        });
        return data.updatedPosition;
    };

    const queryClient = useQueryClient();
    const update = useMutation(updatePosition, {
        onMutate: () => setErrors({}),
        onSuccess: (updatedPosition) => {
            queryClient.setQueryData(['positions', companyId], (data: any) => {
                console.log('D_422', data.positions);
                const positions = data.positions.map((pos: Position) => {
                    if (updatedPosition.id !== pos.id) return pos;
                    return { ...pos, ...updatedPosition };
                });
                console.log('D_422', positions);

                return { ...data, positions };
            });
            showChangesSavedToast();
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const archive = useMutation(archivePosition, {
        onMutate: () => setErrors({}),
        onSuccess: (updatedPosition) => {
            queryClient.setQueryData(['positions', companyId], (data: any) => {
                const positions = data.positions.filter((pos: Position) => {
                    return updatedPosition.id !== pos.id;
                });
                return { ...data, positions };
            });
            toast.success(`${updatedPosition.name} position archived`);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    return {
        isLoading,
        position: positionId ? positions?.find((p) => p.id === parseInt(positionId)) : undefined,
        update,
        archive,
        errors,
    };
};

export { useManyPositions, usePosition };
