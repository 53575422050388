import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FlexView from 'react-flexview';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import {
    Box,
    Flex,
    IconButton,
    Spacer,
    Center,
    Spinner,
    Text,
    Button as ChakraButton,
    DarkMode,
    Tooltip,
} from '@chakra-ui/react';
import { AddIcon, InfoOutlineIcon, SmallCloseIcon, TimeIcon } from '@chakra-ui/icons';
import { MdDeleteForever } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';

import { Question } from '../../types/schema';
import { useColors } from '../../styles/colors';
import dimensions from '../../styles/dimensions';
import { BodyDark, BodyText, SubHeading } from '../../components/Fonts';
import fonts from '../../styles/fonts';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import Editable from '../../components/Form/Editable';
import RichTextInput from '../../components/Form/RichTextInput';
import Form from '../../components/Form/FormContainer';
import Button from '../../components/Button';
import { useParams } from 'react-router-dom';
import { useQuestions } from '../../api/hooks/useQuestions';
import CheckBox from '../../components/Form/CheckBox';
import { useOnClickOutside } from '../../utils/hooks';
import Loading from '../../components/Loading';
import analytics from '../../utils/analytics';
import { showChangesSavedToast } from '../../utils';

const PAGE_MAX_WIDTH = 700;

const typeOptions: any = [
    { label: 'Text', value: 'TEXT' },
    { label: 'Multiple Choice', value: 'MCQ' },
    // { label: 'Code', value: 'CODE' },
];

const QuestionContainer = styled(FlexView)<{ active?: boolean }>`
    background-color: ${(props) => props.theme.background};
    border: 1px solid
        ${({ active, theme }) => (active ? theme.onBackgroundLight : theme.foregroundDark)};
    border-radius: 10px;

    margin: 1em;
    padding: 1em 2em;

    @media only screen and (max-width: 1100px) {
        padding: 0.5em 1em 1em 1em;
    }

    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        margin: 1em 0.2em;
        padding: 0.5em 1em;
    }

    cursor: ${(props) => props.onClick && 'pointer'};
`;

const DragHandle = SortableHandle(() => <span className="cursor-move">{':::'}</span>);

const SortableItem = SortableElement(({ children }: any) => <li>{children}</li>);

const SortableList = SortableContainer(({ children }: any) => {
    return <ul style={{ listStyle: 'none' }}>{children}</ul>;
});

const timeOptions = [
    { label: '15 sec', value: 15 },
    { label: '30 sec', value: 30 },
    { label: '45 sec', value: 45 },
    { label: '60 sec', value: 60 },
    { label: '90 sec', value: 90 },
    { label: '2 min', value: 120 },
    { label: '3 min', value: 180 },
    { label: '5 min', value: 300 },
    { label: '10 min', value: 600 },
    { label: '15 min', value: 900 },
    { label: '30 min', value: 1800 },
];

const McqOptions = ({ value: options = [], onChange, onBlur }: any) => {
    const colors = useColors();

    const blankOption = { label: '', value: false };
    const localOptions = [...(options || [])];
    const optionsWithNewRow = [...localOptions, blankOption];

    const deleteMcqOption = (index: number) => {
        const newOptions = options.filter((o: any, i: number) => i !== index);
        onChange(newOptions);
        onBlur(newOptions);
    };

    const updateLocalOptions = (index: number, option: any) => {
        localOptions[index] = option;
    };

    const trueFalseOptions = [
        { label: 'True', value: true },
        { label: 'False', value: false },
    ];

    const OptionRow = (option: any, index: number) => {
        return (
            <FlexView grow vAlignContent="center">
                <CheckBox
                    isChecked={option.value}
                    iconSize={40}
                    size="lg"
                    marginLeft="1rem"
                    marginRight="1rem"
                    onChange={(e) => {
                        updateLocalOptions(index, { ...option, value: e.target.checked });
                        onChange(localOptions);
                        onBlur(localOptions);
                    }}
                />

                <FlexView grow>
                    <Input
                        value={option.label}
                        placeholder="Add option"
                        onChange={(e: any) => {
                            updateLocalOptions(index, { ...option, label: e.target.value });
                            onChange(localOptions);
                        }}
                        onBlur={() => onBlur(localOptions)}
                    />
                </FlexView>

                <IconButton
                    aria-label="Delete option"
                    icon={<SmallCloseIcon />}
                    variant="ghost"
                    size="lg"
                    color="gray.600"
                    isDisabled={!option.label}
                    minWidth={'2em'}
                    onClick={() => deleteMcqOption(index)}
                />
            </FlexView>
        );
    };

    return (
        <>
            <Flex mt="2em">
                <Text
                    ml="1em"
                    fontWeight="500"
                    fontSize="sm"
                    color={!options?.length ? colors.error : 'primary.500'}
                >
                    {!options?.length ? 'Enter atleast one option' : 'Tick the correct option(s)'}
                </Text>
            </Flex>

            {optionsWithNewRow.map(OptionRow)}
        </>
    );
};

const QuestionCardExpanded: React.FC<{ question: Question }> = ({ question: activeQuestion }) => {
    const colors = useColors();
    const { positionId, companyId } = useParams();
    const { deleteQuestion, update } = useQuestions({ positionId, companyId });

    const [question, setQuestion] = useState<Question | undefined>(activeQuestion);

    const setValue = (key: string, value: any) => {
        if (question) {
            setQuestion({ ...question, [key]: value });
        }
    };

    const updateQuestion = () => {
        if (question) update.mutate(question);
    };

    if (!question) return <>Loading</>;

    return (
        <QuestionContainer active column>
            <FlexView grow>
                <SubHeading margin="0.4em" color={colors.onBackgroundLight}>
                    <DragHandle />
                </SubHeading>
                <SubHeading margin="0.5em" marginRight="1em">
                    {`Q${question.orderNumber + 1}`}
                </SubHeading>

                <FlexView grow />

                <IconButton
                    aria-label="Delete question"
                    icon={<RiDeleteBin2Line size={20} />}
                    variant="ghost"
                    colorScheme="red"
                    size="md"
                    isLoading={deleteQuestion.isLoading}
                    onClick={() => {
                        analytics.event('Question', 'Delete', 'Button clicked');
                        deleteQuestion.mutate(question);
                    }}
                />
            </FlexView>

            <FlexView column grow>
                <FlexView grow wrap>
                    <Select
                        name="type"
                        placeholder="Type"
                        label="Type"
                        labelRight={
                            <Tooltip
                                label={`Multiple choice questions allow the candidate to tick multiple answers.`}
                            >
                                <InfoOutlineIcon ml="1" boxSize="0.8rem" />
                            </Tooltip>
                        }
                        options={typeOptions}
                        value={typeOptions.find(({ value }: any) => value === question.type)}
                        onChange={(option: any) => {
                            analytics.event('Question', 'Set Type', option.label);
                            setValue('type', option.value);
                        }}
                        onBlur={updateQuestion}
                    />
                    <Select
                        placeholder="Response Time"
                        label="Ideal Response Time"
                        labelRight={
                            <Tooltip
                                label={`Candidate is prompted to move to the next question after this time. This isn't a hard time limit.`}
                            >
                                <InfoOutlineIcon ml="1" boxSize="0.8rem" />
                            </Tooltip>
                        }
                        options={timeOptions}
                        value={timeOptions.find(
                            ({ value }: any) => value === question.idealResponseTime,
                        )}
                        onChange={(option: any) => {
                            analytics.event('Question', 'Response Time', `${option.value}`);
                            setValue('idealResponseTime', option.value);
                        }}
                        onBlur={updateQuestion}
                    />
                </FlexView>

                <Editable
                    name="title"
                    placeholder="Enter a question"
                    label="Question"
                    minRows={1}
                    value={question.title}
                    onChange={(e) => setValue('title', e.target.value)}
                    onBlur={updateQuestion}
                    font={question.title && fonts.bodyDark}
                    containerStyle={{ marginTop: '2em' }}
                />
                <RichTextInput
                    bottomToolbar
                    name="description"
                    placeholder="Description"
                    label="Description"
                    value={question.description}
                    onChange={(value: any) => setValue('description', value)}
                    onBlur={updateQuestion}
                    modules={{
                        toolbar: [
                            ['bold', 'italic', 'underline'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            ['code-block'],
                        ],
                    }}
                />

                {question.type === 'MCQ' && (
                    <McqOptions
                        value={question.mcqOptions}
                        onChange={(o: []) => {
                            setValue('mcqOptions', o);
                        }}
                        onBlur={(o: any) =>
                            // cant use updateQuestion as state might not be upto date
                            update.mutate({ ...question, mcqOptions: o })
                        }
                    />
                )}
            </FlexView>
        </QuestionContainer>
    );
};

const QuestionCardMin: React.FC<{
    question: Question;
    setActive: () => void;
}> = ({ question, setActive }) => {
    const { title, description, orderNumber, idealResponseTime } = question;
    const colors = useColors();

    return (
        <QuestionContainer onClick={() => setActive()}>
            <SubHeading margin="0.4em" color={colors.onBackgroundLight}>
                <DragHandle />
            </SubHeading>

            <SubHeading margin="0.5em" marginRight="1em">{`Q${orderNumber + 1}`}</SubHeading>

            <FlexView column grow>
                <Flex align="flex-start">
                    <BodyDark
                        whiteSpace="pre-wrap"
                        margin="0.5em"
                        color={!title ? colors.error : undefined}
                    >
                        {title || 'Please enter a question'}
                    </BodyDark>

                    <Spacer />

                    <Flex align="center" mt="0.5em">
                        <TimeIcon color={colors.lightgreen} mx="0.3rem" />
                        <BodyDark color={colors.green} style={{ whiteSpace: 'nowrap' }}>
                            {timeOptions.find((o) => o.value === idealResponseTime)?.label}
                        </BodyDark>
                    </Flex>
                </Flex>

                {description && (
                    <RichTextInput readOnly value={description} style={{ margin: '0.5em' }} />
                )}

                {question.type === 'MCQ' && (
                    <FlexView column marginLeft={'1em'}>
                        {question?.mcqOptions?.map((o: any) => (
                            <CheckBox
                                isChecked={o.value}
                                value={o.value}
                                iconSize={40}
                                colorScheme="purple"
                                size="sm"
                                spacing="0.6rem"
                                marginTop="0.6rem"
                            >
                                {o.label}
                            </CheckBox>
                        ))}
                    </FlexView>
                )}
            </FlexView>
        </QuestionContainer>
    );
};

const AddQuestionButton: React.FC<{ setLastQuestionActive: () => void }> = ({
    setLastQuestionActive,
}) => {
    const { positionId, companyId } = useParams();
    const { create } = useQuestions({ positionId, companyId });
    const { mutate: addNewQuestion, isLoading: isCreating, isSuccess } = create;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isCreating && !loading) setLoading(true);
        if (!isCreating && loading) setTimeout(() => setLoading(false), 500);
    }, [isCreating]);

    const createQuestion = async () => {
        addNewQuestion();
        setLastQuestionActive();
    };

    // Normal button causes rich text editor's onBlur events to not fire
    return (
        <Center
            className="add-question-button" // for react-joyride
            m="6"
            cursor="pointer"
            color="primary.main"
            alignItems="center"
            onClick={loading ? undefined : createQuestion}
            opacity={loading ? 0.3 : 1}
        >
            {loading ? <Spinner m="2" mt="1" /> : <AddIcon m="2" mt="1.5" />}
            <BodyDark>ADD QUESTION</BodyDark>
        </Center>
    );
};

export default () => {
    const { positionId, companyId } = useParams();
    const [activeIndex, setActiveIndex] = useState<number>();
    const { questions, moveAndUpdate, isLoading } = useQuestions({
        positionId,
        companyId,
    });

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        analytics.event('Questions', 'Sorted', 'Drag');
        moveAndUpdate(oldIndex, newIndex);
    };

    // Close question card on clicking outside
    const ref = useRef<any>();

    // onBlur events were not being called on expanded cards without a delay here
    useOnClickOutside(ref, () => setTimeout(() => setActiveIndex(-1), 200));

    if (isLoading) return <Loading />;

    return (
        <Box h="100%" overflow="auto" ref={ref}>
            <SortableList onSortEnd={onSortEnd} useDragHandle>
                <SubHeading marginLeft="1em">Questions</SubHeading>

                {questions?.map((question, index) => (
                    <SortableItem key={question.id} index={index}>
                        {activeIndex === index ? (
                            <QuestionCardExpanded question={question} />
                        ) : (
                            <QuestionCardMin
                                question={question}
                                setActive={() => setActiveIndex(index)}
                            />
                        )}
                    </SortableItem>
                ))}
            </SortableList>
            <AddQuestionButton setLastQuestionActive={() => setActiveIndex(questions.length)} />

            {/* Padding below add question button */}
            <Box h="16" />
        </Box>
    );
};
