import { Alert, AlertIcon, AlertTitle, AlertDescription, styled, Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const MessageBox = ({
    title,
    description,
    status = 'error',
    onAction,
    actionText,
}: {
    title?: string;
    description?: string;
    status?: 'error' | 'success' | 'info' | 'warning';
    onAction?: () => void;
    actionText?: string;
}) => {
    return (
        <Alert status={status} m="0.5em">
            <AlertIcon />
            <div>
                <AlertTitle>{title}</AlertTitle>
                <AlertDescription onClick={onAction} style={onAction ? { cursor: 'pointer' } : {}}>
                    {description || 'Please refresh the page'}
                </AlertDescription>
            </div>
        </Alert>
    );
};

export default MessageBox;
