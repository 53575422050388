import ReactGA from 'react-ga';

const pageView = () => {
    return ReactGA.pageview(window.location.pathname + window.location.search);
};

const event = (category: string, action: string, label: string | undefined) => {
    return ReactGA.event({ category, action, label });
};

export default {
    pageView,
    event,
};
