import { ComponentStyleConfig } from '@chakra-ui/react';

const Button: ComponentStyleConfig = {
    // The styles all button have in common
    baseStyle: {
        fontWeight: '500',
        borderRadius: 'lg', // <-- border radius is same for all variants and sizes
        // bg: 'primary.main',
        // color: 'white',
    },
    // Two sizes: sm and md
    sizes: {
        sm: {
            fontSize: 'md',
            px: 5, // <-- px is short for paddingLeft and paddingRight
            py: 3, // <-- py is short for paddingTop and paddingBottom
        },
        md: {
            fontSize: ['md'],
            px: 6, // <-- these values are tokens from the design system
            py: 4, // <-- these values are tokens from the design system
        },
        lg: {
            fontSize: ['lg'],
        },
    },
    // Two variants: outline and solid
    variants: {
        solid: {
            // textColor: 'white',
        },
        outline: {
            // border: '2px solid',
            // borderColor: 'primary.main',
            // color: 'primary.main',
        },
    },
    // The default size and variant values
    defaultProps: {
        size: 'md',
        variant: 'solid',
        // colorScheme: 'primary',
    },
};

export default Button;
