import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import styled from 'styled-components';
import { Navigate, useParams } from 'react-router-dom';
import { Box, Flex, Spacer } from '@chakra-ui/react';

import VideoRecorder, { RecorderInstance } from '../../components/VideoRecorder';
import { BodyDark, BodyText, Heading, SubHeading, Title } from '../../components/Fonts';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';

import { FullWidthContainer, HalfWidthContainer, Header, Footer } from './components';
import CheckBoxGroup from '../../components/Form/CheckBoxGroup';
import CheckBox from '../../components/Form/CheckBox';
import useInterviewCandidate from '../../api/hooks/useInterviewCandidate';
import useResponses from '../../api/hooks/useResponses';
import RichTextInput from '../../components/Form/RichTextInput';

import { useColors } from '../../styles/colors';
import { useHasCameraAndMicPermissions, useInterval } from '../../utils/hooks';
import CompanyLogo from '../../components/Company/CompanyLogo';
import CompanyButton from '../../components/Company/CompanyButton';
import { useAppState } from '../../context/app';

const MaxWidthContainer = styled(ModuleMaxWidthContainer)`
    padding-top: 10%;

    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export default () => {
    const colors = useColors();
    const { interviewId, slug } = useParams();

    const { createResponse } = useResponses({ interviewId, slug });
    const { mutate: startUploadResponse, isSuccess } = createResponse;

    const hasPermissions = useHasCameraAndMicPermissions();

    const [isLoading, setIsLoading] = useState(false);
    const [mcqError, setMcqError] = useState(false);
    const [file, setFile] = useState<Blob>();
    const [mcqResponse, setMcqResponse] = useState<any[]>([]); // Array of selected options

    const {
        initiate,
        isFinished,
        isFinalQuestion,
        interviewLength,
        interview,
        currentQuestion: question,
    } = useInterviewCandidate({ interviewId, slug });
    const company = interview?.company;

    useEffect(() => {
        initiate.mutate();
    }, []);

    useEffect(() => {
        if (file) {
            console.log('D_100', mcqResponse);
            startUploadResponse({ file, mcqResponse });
            setFile(undefined);
            setMcqResponse([]);
        }
    }, [file]);

    const [recordingSeconds, setRecordingSeconds] = useState(0);
    const hasExceededIdealTime =
        question?.idealResponseTime && recordingSeconds > question?.idealResponseTime;

    useInterval(() => {
        setRecordingSeconds(RecorderInstance?.getDuration());
    }, 2000);

    const resolveUserResponse = (o: any) => {
        setMcqError(false);
        const isSelected = mcqResponse?.find(({ label }) => label === o.label);

        /** If already selected, remove */
        if (isSelected) setMcqResponse(mcqResponse?.filter(({ label }) => label !== o.label));
        // Else add
        else setMcqResponse([...mcqResponse, o]);
    };

    const submitResponse = () => {
        if (question?.type === 'MCQ' && question?.mcqOptions?.length && mcqResponse.length === 0) {
            setMcqError(true);
            return;
        }

        // onRecordingAvailable is triggered when stop() is called
        RecorderInstance?._recording && RecorderInstance.stop();
        setMcqError(false);
        setIsLoading(true);
    };

    const onRecordingAvailable = (file: Blob) => {
        // blob is a video blob
        // becomes available after recording stop
        setFile(file);
    };

    useEffect(() => {
        if (isSuccess) {
            // better to get device and let it start recording when available than directly start
            !isFinalQuestion && RecorderInstance.getDevice();

            setTimeout(() => setIsLoading(false), 3000); // Wait a bit for the camera to start
        }
    }, [isSuccess]);

    if (isFinished) return <Navigate to={`/interviews/outro/${interviewId}/${slug}`} />;

    return (
        <Flex h="100%" flexDir="column" align="center">
            <Header company={company} />

            <FullWidthContainer>
                <VideoRecorder onRecordingAvailable={onRecordingAvailable} />

                <HalfWidthContainer>
                    <Flex>
                        <BodyDark style={{ color: colors.secondaryLight }}>
                            {`Question ${(question?.orderNumber || 0) + 1}/${interviewLength}`}
                        </BodyDark>

                        <Spacer />

                        {/* {hasPermissions && !!hasExceededIdealTime && (
                            <Box ml="2" color="pink.300" fontWeight="500">
                                Try to wrap up in 30 more seconds
                            </Box>
                        )} */}
                    </Flex>

                    <SubHeading marginTop="2em">{question?.title}</SubHeading>

                    {question?.description && (
                        <RichTextInput
                            readOnly
                            value={question?.description}
                            style={{ marginTop: '1em' }}
                        />
                    )}

                    {question?.type === 'MCQ' && (
                        <CheckBoxGroup colorScheme="purple" size="sm">
                            {question.mcqOptions?.map((o: any) => (
                                <CheckBox
                                    isChecked={
                                        !!mcqResponse?.find(({ label }) => label === o.label)
                                    }
                                    iconSize={40}
                                    spacing="0.8rem"
                                    marginTop="1rem"
                                    onChange={() => resolveUserResponse(o)}
                                >
                                    {o.label}
                                </CheckBox>
                            ))}
                        </CheckBoxGroup>
                    )}

                    <FlexView column grow vAlignContent="bottom" marginTop="4em">
                        {!hasPermissions ? (
                            <Box m="2" color="red.500">
                                Please grant camera and mic permissions
                            </Box>
                        ) : (
                            !!hasExceededIdealTime && (
                                <Box m="2" color="pink.300">
                                    Try to wrap up in 30 more seconds
                                </Box>
                            )
                        )}
                        {mcqError && (
                            <Box m="2" color="pink.300">
                                Select atleast one option before proceeding
                            </Box>
                        )}
                        <CompanyButton
                            company={company}
                            isLoading={isLoading}
                            disabled={isLoading || !hasPermissions || !RecorderInstance?._recording}
                            onClick={submitResponse}
                            type="submit"
                        >
                            {isFinalQuestion ? `COMPLETE INTERVIEW` : `NEXT QUESTION`}
                        </CompanyButton>
                    </FlexView>
                </HalfWidthContainer>
            </FullWidthContainer>

            <Spacer />

            <Footer />
        </Flex>
    );
};
