import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    IconButton,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';

import Select from '../../components/Form/Select';
import useWorkspace from '../../api/hooks/useWorkspace';
import { CommentType } from '../../types/global';
import { useAuthState } from '../../context/auth';
import { InterviewInvite, User } from '../../types/schema';
import useManyInterviews from '../../api/hooks/useManyInterviews';
import { useCurrentCompany } from '../../utils/hooks';
import analytics from '../../utils/analytics';

const AssignManager: React.FC<{
    interview: InterviewInvite;
    variant?: string;
}> = ({ interview, variant }) => {
    const company = useCurrentCompany();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef<any>();

    const { user } = useAuthState();
    const { companyId } = useParams();

    const { assignMember } = useManyInterviews(companyId);

    const { permissions } = useWorkspace(companyId);
    const [manager, setManager] = useState<User>(interview.assignedTo);
    const { mutate, isSuccess, isLoading, reset } = assignMember;

    useEffect(() => {
        if (isSuccess) {
            reset();
            onClose();
        }
    }, [isSuccess]);

    const assignManager = () => {
        analytics.event('Assign Manager', 'Successfully assigned', 'Button clicked');
        mutate({
            assignedToId: manager.id,
            interviewId: interview.id,
            publicSlug: interview.publicSlug,
        });
    };

    const managerOptions = permissions.map(({ user }) => {
        return { label: user.name, value: user };
    });

    return (
        <>
            <Tooltip label="Assign Manager">
                <IconButton
                    variant={variant || 'ghost'}
                    aria-label="Assign manager"
                    icon={<FiUser />}
                    onClick={() => {
                        analytics.event('Assign Manager', 'Modal opened', 'Button clicked');
                        onOpen();
                    }}
                />
            </Tooltip>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Assign Manager
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Select
                                value={managerOptions.find(({ value }) => value.id === manager?.id)}
                                placeholder="Manager"
                                options={managerOptions}
                                onChange={(p: typeof managerOptions[0]) => setManager(p.value)}
                            />
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                CANCEL
                            </Button>
                            <Button
                                ml="4"
                                colorScheme={'primary'}
                                onClick={assignManager}
                                isDisabled={manager?.id === interview.assignedToId}
                                isLoading={isLoading}
                                loadingText="ASSIGNING"
                            >
                                ASSIGN
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default AssignManager;
