import React, { useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Box, Image } from '@chakra-ui/react';
import ImgCrop from 'antd-img-crop';
import styled from 'styled-components';

const StyledUpload = styled(Upload)<{ noBorder?: boolean }>`
    .ant-upload-select-picture-card {
        background-color: ${(props) => (props.noBorder ? props.theme.background : undefined)};
        border-width: ${(props) => (props.noBorder ? 0 : undefined)};
    }
    .ant-upload-select-picture-card:hover {
        background-color: #fafafa;
        border: 1px dashed #ddd;
    }
`;

const beforeUpload = (file: any) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isLt2M;
};

const ImageUpload: React.FC<{
    value?: string;
    label?: string;
    isLoading?: boolean;
    isAvatar?: boolean;
    onChange?: (file: any) => void;
}> = ({ value, label, isLoading, isAvatar, onChange }) => {
    const Cropper = isAvatar ? ImgCrop : Box;

    const uploadImage = async ({ file, onSuccess }: any) => {
        onChange && (await onChange(file));
    };

    return (
        <Box m="0.5em">
            <Cropper shape="round">
                <StyledUpload
                    noBorder={!!value && !isLoading}
                    accept="image/png, image/jpeg"
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={uploadImage}
                    beforeUpload={beforeUpload}
                >
                    {value && !isLoading ? (
                        isAvatar ? (
                            <Avatar src={value} w="100%" h="100%" />
                        ) : (
                            <Image src={value} w="100%" h="100%" fit="contain" />
                        )
                    ) : (
                        <div>
                            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div style={{ marginTop: 8 }}>
                                {isLoading ? 'Uploading' : label || 'Profile Pic'}
                            </div>
                        </div>
                    )}
                </StyledUpload>
            </Cropper>
        </Box>
    );
};

export default ImageUpload;
