import { useEffect, useMemo } from 'react';
import FlexView from 'react-flexview/lib';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import Joyride from 'react-joyride';
import {
    Avatar,
    Box,
    Tag,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Flex,
    Wrap,
    Spacer,
    IconButton,
    Checkbox,
    Center,
} from '@chakra-ui/react';
import { Empty } from 'antd';
import { BiFilterAlt } from 'react-icons/bi';
import { RiFilterOffLine } from 'react-icons/ri';

import Button from '../../components/Button';
import { BodyText, ButtonText, SubHeading, ButtonLight, BodyLight } from '../../components/Fonts';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';
import ModuleScrollView from '../../components/ModuleScrollView';
import NavBar from '../../components/NavBar';
import Table from '../../components/Table';
import { DEMO_CANDIDATE, DEMO_INTERVIEW, DEMO_POSITION, DEMO_USER } from '../../utils/demoData';
import { InterviewCandidate, InterviewStatus, Permission, Position } from '../../types/schema';
import Circle from '../../components/Circle';
import {
    INTERVIEW_STATUSES,
    PENDING_INTERVIEW_STATUSES,
    VERDICT_OPTIONS,
} from '../../utils/constants';
import Card from '../../components/Card';
import DateRangePicker from '../../components/Form/DateRangePicker';
import ProfileCircle from '../../components/ProfileCircle';
import {
    useCurrentCompany,
    useCurrentRole,
    useIsAdmin,
    useIsMobile,
    useIsReviewer,
} from '../../utils/hooks';
import dimensions from '../../styles/dimensions';
import { useManyPositions } from '../../api/hooks/usePositions';
import { useColors } from '../../styles/colors';
import Loading from '../../components/Loading';
import Tutorial from '../../components/Tutorial';
import useManyInterviews from '../../api/hooks/useManyInterviews';
import useWorkspace from '../../api/hooks/useWorkspace';

import CandidateAddMultiple from './CandidateAddMutliple';
import CandidateAddSingle from './CandidateAddSingle';
import AddFirstCandidate from './AddFirstCandidate';
import ArchiveInterview from './ArchiveInterview';
import ResendInterview from './ResendInterview';
import AssignManager from './AssignManager';
import ExportInterviews from './ExportInterviews';
import { fontSizes } from '../../styles/fonts';
import { GiRoundStar } from 'react-icons/gi';
import { getColorForRating } from './Rating';
import Welcome from '../../components/Welcome';
import analytics from '../../utils/analytics';
import { searchParamsToObj } from '../../utils';
import { CandidatesPromoCard } from './CandidatesPromo';
import NewWorkspaceButton from '../../components/NewWorkspaceButton';

const CANDIDATES_MAX_WIDTH = 1200;
const BOTTOM_BAR_HEIGHT = 40;

const candidate = {
    status: DEMO_INTERVIEW.status.toUpperCase(),
    name: DEMO_CANDIDATE.name,
    position: DEMO_POSITION.name,
    email: DEMO_CANDIDATE.email,
    createdDate: moment(DEMO_INTERVIEW.date).format('lll'),
    hiringManager: DEMO_USER.name,
};

const StyledCandidateCard = styled(Card)`
    cursor: pointer;
    display: flex;
    background-color: ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.foregroundDark};
    /* border-bottom: 4px solid ${({ theme }) => theme.foregroundDark}; */
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);
`;

const NameCell = ({ value, row: { original } }: { value: string; row: any }) => {
    return (
        <div>
            <BodyText>{value}</BodyText>
            <BodyLight color="gray">{original.email}</BodyLight>
        </div>
    );
};

const DeadlineCell = ({ value, row: { original } }: { value: string; row: any }) => {
    const exceeded =
        PENDING_INTERVIEW_STATUSES.includes(original.status) && moment().isAfter(value);

    return value ? (
        <Box whiteSpace="nowrap">
            {moment(value).format('ll')}
            {exceeded && (
                <Box color="red.main" fontSize="xs">
                    (Expired)
                </Box>
            )}
        </Box>
    ) : (
        'No deadline'
    );
};

const StatusCell = ({ value, inverted }: { value: InterviewStatus; inverted?: boolean }) => {
    const positionStatus = INTERVIEW_STATUSES[value];

    return (
        <FlexView vAlignContent="center">
            {!inverted && <Circle color={positionStatus?.color} />}

            <ButtonText size={12} marginLeft="0.5em" marginRight="0.5em">
                {positionStatus?.label?.toUpperCase()}
            </ButtonText>

            {inverted && <Circle color={positionStatus?.color} />}
        </FlexView>
    );
};

const RatingCell = ({ row: { original } }: any) => {
    const color = getColorForRating(original?.averageRating);

    return original?.averageRating ? (
        <Flex align={'center'}>
            <Box fontWeight="bold" mr="1px" mt="2px" color={color}>
                {original?.averageRating}
            </Box>
            <GiRoundStar size={14} color={color} />
            <Box fontSize="xs" ml="2" color="gray.700">{`(${original?.totalRatings})`}</Box>
        </Flex>
    ) : (
        <Box fontSize="xs" fontWeight="500" color="gray.500">
            No&nbsp;Rating
        </Box>
    );
};

const CandidateMobileCard = ({ onClick, ...interview }: any) => {
    const { status, name, position } = interview;

    return (
        <StyledCandidateCard onClick={() => onClick(interview)}>
            {/* <ProfileCircle name={name} /> */}

            <Avatar name={name} />

            <FlexView column marginLeft="1em">
                <SubHeading marginBottom="0.2em">{name}</SubHeading>
                <BodyText marginBottom="0.2em">{position}</BodyText>
            </FlexView>

            <FlexView column grow vAlignContent="top" hAlignContent="right">
                <StatusCell inverted value={status} />
                <Box m="-3" mt="1.5">
                    <ActionsCell interview={interview} />
                </Box>
            </FlexView>
        </StyledCandidateCard>
    );
};

const FilterContainer = styled.div`
    position: sticky; // So z-index works as table header is also sticky
    /* top: 0; */
    /* left: 0; */
    /* right: 0.8em; */

    background-color: ${(props) => props.theme.background};
    /* border-radius: ${dimensions.borderRadius}; */
    z-index: 2;

    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */

    padding: 0.5em;
    padding-bottom: 1.5em;
`;

const Filters = ({ filterParams, alterFilterParams }: any) => {
    const colors = useColors();
    const { companyId } = useParams<{ companyId: string }>();

    const { positions } = useManyPositions(companyId);

    const positionOptions = positions?.map((p) => ({ label: p.name, value: p.id }));
    const positionTags = positionOptions.slice(0, 4);

    const isReviewer = useIsReviewer();
    const modifiedStatusOptions = isReviewer
        ? statusOptions.filter((o) => !PENDING_INTERVIEW_STATUSES.includes(o.value))
        : statusOptions;

    return (
        <>
            <Box maxW={['sm', 'xs']} mb={[8, 8, 0]}>
                <Select
                    isMulti
                    placeholder="Status"
                    options={modifiedStatusOptions}
                    value={modifiedStatusOptions.filter((o) =>
                        filterParams.status?.includes(o.value),
                    )}
                    onChange={(arr: any) => {
                        analytics.event(
                            'Candidate Filter',
                            'Status-Dropdown',
                            JSON.stringify(arr.map((o: any) => o.label)),
                        );
                        alterFilterParams({ status: arr.map((o: any) => o.value) });
                    }}
                />
                <FlexView wrap marginLeft="0.5em">
                    {modifiedStatusOptions.map((o) => (
                        <Tag
                            size={'sm'}
                            borderRadius="full"
                            margin="0.3em"
                            cursor="pointer"
                            key={o.value}
                            variant={filterParams.status?.includes(o.value) ? 'solid' : 'outline'}
                            color={filterParams.status?.includes(o.value) ? 'white' : 'gray.400'}
                            shadow={0}
                            style={{ border: `1px solid #CBD5E0` }}
                            // backgroundColor={
                            //     filterParams.status?.includes(o.value) &&
                            //     INTERVIEW_STATUSES[o.value]?.color
                            // }
                            onClick={() => {
                                analytics.event('Candidate Filter', 'Status-Tag', o.label);
                                const existingStatuses = !filterParams.status
                                    ? []
                                    : Array.isArray(filterParams.status)
                                    ? filterParams.status
                                    : [filterParams.status];

                                if (!existingStatuses.includes(o.value)) {
                                    alterFilterParams({
                                        status: [...existingStatuses, o.value],
                                    });
                                } else {
                                    alterFilterParams({
                                        status: existingStatuses.filter(
                                            (v: string) => v !== o.value,
                                        ),
                                    });
                                }
                            }}
                        >
                            {o.label}
                        </Tag>
                    ))}
                </FlexView>
            </Box>

            <Box maxW={['sm', 'xs']} mb={[8, 8, 0]}>
                <Select
                    isMulti
                    placeholder="Position"
                    options={positionOptions}
                    value={positionOptions.filter((o) =>
                        filterParams.positionId?.includes(o.value),
                    )}
                    onChange={(arr: any) => {
                        analytics.event(
                            'Candidate Filter',
                            'Position-Dropdown',
                            JSON.stringify(arr.map((o: any) => o.label)),
                        );
                        alterFilterParams({ positionId: arr.map((o: any) => o.value) });
                    }}
                />

                <FlexView wrap marginLeft="0.5em">
                    {positionTags.map((o) => (
                        <Tag
                            size={'sm'}
                            borderRadius="full"
                            margin="0.3em"
                            cursor="pointer"
                            key={o.value}
                            variant={
                                filterParams.positionId?.includes(o.value) ? 'solid' : 'outline'
                            }
                            color={
                                filterParams.positionId?.includes(o.value) ? 'white' : 'gray.400'
                            }
                            shadow={0}
                            style={{ border: `1px solid #CBD5E0` }}
                            onClick={() => {
                                analytics.event('Candidate Filter', 'Position-Tag', o.label);
                                const existingPositions = !filterParams.positionId
                                    ? []
                                    : Array.isArray(filterParams.positionId)
                                    ? filterParams.positionId
                                    : [filterParams.positionId];

                                if (!existingPositions.includes(o.value)) {
                                    alterFilterParams({
                                        positionId: [...existingPositions, o.value],
                                    });
                                } else {
                                    alterFilterParams({
                                        positionId: existingPositions.filter(
                                            (p: number) => p !== o.value,
                                        ),
                                    });
                                }
                            }}
                        >
                            {o.label}
                        </Tag>
                    ))}
                </FlexView>
            </Box>
        </>
    );
};

const AdditionalFilters = ({ filterParams, alterFilterParams }: any) => {
    const colors = useColors();
    const { companyId } = useParams<{ companyId: string }>();

    const { permissions, isLoading } = useWorkspace(companyId);
    const members = permissions.map((p: Permission) => ({ id: p.user.id, name: p.user.name }));
    const memberOptions = members.map((m) => ({ label: m.name, value: m.id }));

    const ratingFilterOptions = [
        { label: 'No rating', value: 0 },
        { label: '1 - 1.9', value: 1 },
        { label: '2 - 2.9', value: 2 },
        { label: '3 - 3.9', value: 3 },
        { label: '4 - 4.9', value: 4 },
        { label: '5', value: 5 },
    ];

    return (
        <>
            <Box maxW="2xs" mb={[8, 8, 0]}>
                <DateRangePicker
                    label="Created Date"
                    value={[
                        filterParams.createdAtStart && moment(filterParams.createdAtStart),
                        filterParams.createdAtEnd && moment(filterParams.createdAtEnd),
                    ]}
                    onChange={(e: any) => {
                        analytics.event(
                            'Candidate Filter',
                            'Created Date',
                            `${e?.[0]?.toISOString()} to ${e?.[1]?.toISOString()}`,
                        );
                        alterFilterParams({
                            createdAtStart: e?.[0]?.toISOString(),
                            createdAtEnd: e?.[1]?.toISOString(),
                        });
                    }}
                />
            </Box>

            <Box maxW={['sm', 'xs']} mb={[8, 8, 0]} flex="1">
                <Select
                    isMulti
                    label="Assigned Manager"
                    options={memberOptions}
                    value={memberOptions.filter((o) =>
                        filterParams.assignedToId?.includes(o.value),
                    )}
                    onChange={(arr: any) => {
                        analytics.event('Candidate Filter', 'Assigned Manager', undefined);
                        alterFilterParams({ assignedToId: arr.map((o: any) => o.value) });
                    }}
                />
            </Box>

            <Box maxW="2xs" mb={[8, 8, 0]}>
                <DateRangePicker
                    label="Deadline"
                    value={[
                        filterParams.deadlineStart && moment(filterParams.deadlineStart),
                        filterParams.deadlineEnd && moment(filterParams.deadlineEnd),
                    ]}
                    onChange={(e: any) => {
                        analytics.event(
                            'Candidate Filter',
                            'Deadline',
                            `${e?.[0]?.toISOString()} to ${e?.[1]?.toISOString()}`,
                        );
                        alterFilterParams({
                            deadlineStart: e?.[0]?.toISOString(),
                            deadlineEnd: e?.[1]?.toISOString(),
                        });
                    }}
                />
            </Box>

            <Box maxW={['sm', 'xs']} mb={[8, 8, 0]} flex="1">
                <Select
                    // isMulti
                    label="Rating"
                    options={ratingFilterOptions}
                    value={ratingFilterOptions.find((o) => filterParams.rating === o.value)}
                    onChange={(option: any) => {
                        analytics.event('Candidate Filter', 'Rating', option.label);
                        alterFilterParams({ rating: option.value });
                    }}
                />
            </Box>

            <Box maxW={['sm', 'xs']} mb={[8, 8, 0]} flex="1">
                <Select
                    isMulti
                    label="Verdict"
                    options={VERDICT_OPTIONS}
                    value={VERDICT_OPTIONS.filter((o) => filterParams.verdict?.includes(o.value))}
                    onChange={(arr: any) => {
                        analytics.event(
                            'Candidate Filter',
                            'Verdict',
                            JSON.stringify(arr.map((o: any) => o.label)),
                        );
                        alterFilterParams({ verdict: arr.map((o: any) => o.value) });
                    }}
                />
            </Box>

            <Flex maxW="2xs" mb={[8, 8, 0]} justify="center">
                <Checkbox
                    m="1em"
                    isChecked={filterParams.onlyArchived}
                    onChange={(e) => {
                        analytics.event('Candidate Filter', 'Only Archived', undefined);
                        alterFilterParams({ onlyArchived: e.target.checked });
                    }}
                >
                    Only archived
                </Checkbox>
            </Flex>

            <Flex maxW="2xs" mb={[8, 8, 0]} justify="center">
                <Checkbox
                    m="1em"
                    isChecked={filterParams.excludeExpired}
                    onChange={(e) => {
                        analytics.event('Candidate Filter', 'Only Archived', undefined);
                        alterFilterParams({ excludeExpired: e.target.checked });
                    }}
                >
                    Exclude expired
                </Checkbox>
            </Flex>
        </>
    );
};

const FiltersModal = ({
    filterParams,
    alterFilterParams,
    clearFilterParams,
    onlyAdditional,
}: any) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useIsMobile();

    return (
        <>
            <Flex align="center">
                <Button color="foreground" rightIcon={<BiFilterAlt size="18" />} onClick={onOpen}>
                    FILTERS
                </Button>
                {!!Object.keys(filterParams).length && (
                    <IconButton
                        colorScheme="red"
                        icon={<RiFilterOffLine size="18" />}
                        aria-label="Clear filters"
                        mr="0.5em"
                        onClick={clearFilterParams}
                    />
                )}
            </Flex>
            <Modal
                // isCentered // Causes problems when content is more than screen
                onClose={onClose}
                isOpen={isOpen}
                size={isMobile ? 'full' : '3xl'}
                motionPreset="slideInBottom"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Filter candidates</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Wrap spacing={[4, 6, 10]}>
                            {!onlyAdditional && (
                                <Filters
                                    filterParams={filterParams}
                                    alterFilterParams={alterFilterParams}
                                />
                            )}
                            <AdditionalFilters
                                filterParams={filterParams}
                                alterFilterParams={alterFilterParams}
                            />
                        </Wrap>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="foreground"
                            onClick={() => {
                                clearFilterParams();
                            }}
                        >
                            CLEAR FILTERS
                        </Button>
                        <Button
                            onClick={() => {
                                onClose();
                            }}
                        >
                            APPLY
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

const statusOptions = Object.keys(INTERVIEW_STATUSES).map((key: string) => {
    return INTERVIEW_STATUSES[key as InterviewStatus];
});

const ActionsCell = ({ interview }: any) => {
    const role = useCurrentRole();
    const isAdmin = useIsAdmin();
    const isReviewer = useIsReviewer();
    return (
        <Flex alignItems="center" justifyContent="flex-end" onClick={(e) => e.stopPropagation()}>
            {[INTERVIEW_STATUSES.INVITED.value, INTERVIEW_STATUSES.OPENED.value].includes(
                interview.status,
            ) && <ResendInterview interview={interview} />}
            {!!isAdmin && <AssignManager interview={interview} />}
            {!isReviewer && <ArchiveInterview interview={interview} />}
        </Flex>
    );
};

const Candidates = () => {
    const isMobile = useIsMobile();
    const isReviewer = useIsReviewer();
    const company = useCurrentCompany();
    let navigate = useNavigate();

    useEffect(() => {
        analytics.pageView();
    }, []);

    const { companyId } = useParams();
    const {
        interviews: interviewData,
        filterParams,
        setFilterParams,
        isLoading,
    } = useManyInterviews(companyId);

    const interviews = interviewData
        .filter((i) =>
            isReviewer ? !PENDING_INTERVIEW_STATUSES.includes(i.status as InterviewStatus) : true,
        )
        .map((interview: InterviewCandidate) => {
            return {
                ...interview,
                name: interview.candidate.name,
                position: interview.position.name,
                email: interview.candidate.email,
                createdAt: moment(interview.createdAt).format('lll'),
                hiringManager: interview.assignedTo?.name || 'N/A',
            };
        });

    const onRowClick = ({ id, publicSlug, status }: any) => {
        analytics.event('Candidate List', 'Table row clicked', status);
        navigate(`/candidates/evaluation/${id}/${companyId}/${publicSlug}`);
    };

    const columns = useMemo(
        () => [
            { Header: 'Status', accessor: 'status', Cell: StatusCell },
            { Header: 'Name', accessor: 'name', Cell: NameCell },
            { Header: 'Position', accessor: 'position' },
            // { Header: 'Email', accessor: 'email' },
            { Header: 'Created Date', accessor: 'createdAt' },
            { Header: 'Deadline', accessor: 'deadline', Cell: DeadlineCell },
            {
                Header: 'Rating',
                accessor: 'rating',
                Cell: RatingCell,
                sortType: (a: any, b: any) => {
                    return a?.original?.averageRating - b?.original?.averageRating;
                },
            },
            // { Header: 'Hiring Manager', accessor: 'hiringManager' },
            {
                Header: 'Actions',
                Cell: ({ row: { original } }: any) => <ActionsCell interview={original} />,
                sortable: false,
            },
        ],
        [],
    );

    const alterFilterParams = (data: any) => {
        setFilterParams({
            ...filterParams,
            ...data,
        });
    };
    const clearFilterParams = () => {
        analytics.event('Candidate Filter', 'Filters Cleared', undefined);
        setFilterParams({});
    };

    const isFiltered = !!Object.keys(filterParams)[0];
    const noExistingCandidates = !isLoading && !interviews.length && !isFiltered;

    // To update url with filters
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const searchObj = searchParamsToObj(searchParams);

        if (Object.keys(searchObj).find((k) => searchObj[k] !== filterParams[k]))
            setFilterParams(searchParamsToObj(searchParams));
    }, [searchParams]);

    useEffect(() => {
        // Delete empty filter keys
        Object.keys(filterParams)
            .filter((k) => !filterParams[k])
            .map((key) => delete filterParams[key]);

        setSearchParams(filterParams, { replace: true });
    }, [filterParams]);

    return (
        <>
            <NavBar
                tabs={[]}
                navBarRight={
                    isReviewer ? (
                        <>
                            <Box
                                mr="6"
                                textAlign="right"
                                display={['none', 'none', 'none', 'inherit']}
                            >
                                You have limited functionality in {company.name}'s workspace
                            </Box>
                            <NewWorkspaceButton />
                        </>
                    ) : !noExistingCandidates ? (
                        <FlexView>
                            <CandidateAddMultiple />
                            <CandidateAddSingle />
                        </FlexView>
                    ) : null
                }
            />

            {noExistingCandidates ? (
                !isReviewer ? (
                    <AddFirstCandidate />
                ) : (
                    <ModuleScrollView padding="0">
                        <ModuleMaxWidthContainer maxWidth={CANDIDATES_MAX_WIDTH}>
                            <Box m="5%" mt="10" mb="40">
                                You currently have no candidates to review.
                            </Box>
                            <CandidatesPromoCard />
                        </ModuleMaxWidthContainer>
                    </ModuleScrollView>
                )
            ) : (
                <ModuleScrollView padding="0">
                    <ModuleMaxWidthContainer maxWidth={CANDIDATES_MAX_WIDTH}>
                        <FilterContainer>
                            <FlexView column grow>
                                <Input
                                    name="search"
                                    // label={!isMobile && 'Search'}
                                    placeholder="Search by name or email"
                                    defaultValue={filterParams.searchText || filterParams.email}
                                    onBlur={(e) => {
                                        analytics.event(
                                            'Candidate Filter',
                                            'Search',
                                            e.target.value,
                                        );
                                        alterFilterParams({
                                            searchText: e.target.value,
                                            email: null,
                                        });
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            const { value } = e.target as HTMLInputElement;
                                            alterFilterParams({
                                                searchText: value,
                                                email: null,
                                            });
                                        }
                                    }}
                                />
                                <Spacer />
                            </FlexView>

                            {isMobile ? (
                                <Flex align="center">
                                    <FiltersModal
                                        filterParams={filterParams}
                                        alterFilterParams={alterFilterParams}
                                        clearFilterParams={clearFilterParams}
                                    />
                                    <ExportInterviews interviews={interviews} />
                                </Flex>
                            ) : (
                                <>
                                    <Filters
                                        filterParams={filterParams}
                                        alterFilterParams={alterFilterParams}
                                    />
                                    <Spacer />
                                    <Flex mt="0" flexDirection="column">
                                        <FiltersModal
                                            filterParams={filterParams}
                                            alterFilterParams={alterFilterParams}
                                            clearFilterParams={clearFilterParams}
                                        />
                                        <ExportInterviews interviews={interviews} />
                                    </Flex>
                                </>
                            )}
                        </FilterContainer>

                        {/* Don't move <Loading/> from here to one level above */}
                        {/* We still want filters to be shown when data is loading due to change in filterParams */}
                        {isLoading ? (
                            <Loading />
                        ) : !interviews.length ? (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={'No candidates found'}
                                style={{ margin: '6em' }}
                            >
                                <Button color="foreground" onClick={clearFilterParams}>
                                    CLEAR FILTERS
                                </Button>
                            </Empty>
                        ) : isMobile ? (
                            <Box
                                className="candidates-list" // for react-joyride
                                ml="2"
                            >
                                {interviews.map((interview: any) => (
                                    <CandidateMobileCard {...interview} onClick={onRowClick} />
                                ))}
                            </Box>
                        ) : (
                            <div
                                className="candidates-list" // for react-joyride
                            >
                                <Table
                                    // headerTop="150px"
                                    columns={columns}
                                    data={interviews}
                                    onRowClick={onRowClick}
                                />
                            </div>
                        )}

                        {!!interviews.length && (
                            <Flex
                                position="sticky"
                                bottom="0"
                                left="0"
                                right="0"
                                bg="background.main"
                                py="3"
                                px="8"
                                justify="flex-end"
                                fontSize={fontSizes.xs}
                            >
                                Showing {interviews.length} interviews.
                                {!!filterParams.excludeExpired ? (
                                    <Box color="red.500" ml="2">
                                        Excluding expired.
                                    </Box>
                                ) : (
                                    !!Object.keys(filterParams).length && ' Filtered.'
                                )}
                            </Flex>
                        )}

                        {!!isReviewer && (
                            <Box w="90%" mt="40">
                                <CandidatesPromoCard />
                            </Box>
                        )}
                    </ModuleMaxWidthContainer>
                </ModuleScrollView>
            )}
        </>
    );
};

export default () => {
    return (
        <>
            <Candidates />

            <Welcome />

            <Tutorial
                delay={1000}
                tutorialKey={'CANDIDATES_TUTORIAL_DONE'}
                steps={[
                    {
                        disableBeacon: true,
                        target: '.add-candidate-button',
                        content: 'Invite a single candidate by entering their name and email.',
                    },
                    {
                        target: '.import-excel-button',
                        content: 'Or invite multiple candidates using an excel sheet.',
                    },
                    {
                        // This will only show if there are any candidates
                        target: '.candidates-list',
                        content: 'Click on any candidate to open the evaluation screen.',
                    },
                ]}
            />
        </>
    );
};
