import axios from 'axios';
import { useState } from 'react';
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { apiRefresh } from '.';
import { useAppDispatch } from '../../context/app';
import { InterviewCandidate, Question, Response } from '../../types/schema';
import useInterviewCandidate from './useInterviewCandidate';

export type CreateResponseParams = {
    companyId: number;
    positionId: number;
    interviewId: number;
    questionId: number;
    displayQuestion: Question;
    orderNumber: number;
    mcqResponse: any[] | undefined;
    file: Blob;
};

const useResponses = ({
    interviewId,
    slug,
}: {
    interviewId: string | undefined;
    slug: string | undefined;
}) => {
    const appDispatch = useAppDispatch();
    const { interview, activeOrderNumber, currentQuestion, isLoading } = useInterviewCandidate({
        interviewId,
        slug,
    });

    const createResponseAndGetUploadUrl = async (createData: {
        file: Blob;
        mcqResponse: any[] | undefined;
    }): Promise<{
        createdResponse: Response;
        updatedInterview: InterviewCandidate;
        uploadUrl: string;
        file: Blob;
    }> => {
        /** file variable is just here because of how useMutation works */
        /** WARNING: Seperate file from uploading data */
        // console.log('D_100 CREATING RESPONSE', createData);

        if (!interview) throw { message: `can't find Interview` };
        if (!currentQuestion) throw { message: `can't find currentQuestion` };

        const postData = {
            companyId: interview.companyId,
            positionId: interview.positionId,
            interviewId: interview.id,
            questionId: currentQuestion.id,
            displayQuestion: currentQuestion,
            orderNumber: activeOrderNumber,
            mcqResponse: createData.mcqResponse,
            slug,
        };

        // console.log('D_100 MAKING API CALL', postData);
        const { data } = await apiRefresh.post('/responses/create', postData);

        /** WARNING: Return file after uploading base response */
        return { ...data, file: createData.file };
    };

    const uploadAndConfirmResponse = async (confirmData: {
        createdResponse: Response;
        uploadUrl: string;
        file: Blob;
    }): Promise<{
        updatedResponses: Array<Response>;
    }> => {
        let startTime = Date.now();
        let prevProgress = 0;

        /** Upload file to aws */
        // console.log('D_100 UPLOADING ON AWS', confirmData);
        await axios.put(confirmData.uploadUrl, confirmData.file, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent: ProgressEvent) => {
                const timeMs = Date.now() - startTime;
                const currentProgress = (progressEvent.loaded * 100) / progressEvent.total;

                const percentDiff = currentProgress - prevProgress;
                const chunk = (percentDiff * confirmData.file.size) / 100;
                const speed = (chunk / 1024 / (timeMs / 1000)).toFixed(2);
                console.log('$$$$$$speed', currentProgress, percentDiff, confirmData, chunk, speed);

                appDispatch('UPDATE_PROGRESS', {
                    questionId: confirmData.createdResponse.questionId,
                    percent: Math.round(currentProgress),
                    speed: speed, // kbps
                });

                startTime = Date.now();
                prevProgress = currentProgress;
            },
        });

        /** Confirm Upload */
        // console.log('D_100 UPLOADED ON AWS');
        // console.log('D_100 CONFIRMING RESPONSE', confirmData);
        const { data } = await apiRefresh.put('/responses/confirm', {
            id: confirmData.createdResponse.id,
            interviewId: interview?.id,
            slug,
        });

        return data.updatedResponses;
    };

    const queryClient = useQueryClient();
    const confirmResponse = useMutation(uploadAndConfirmResponse, {
        onSuccess: (updatedResponses) => {
            // console.log('D_100 RESPONSE CONFIRMED', updatedResponses);
            queryClient.invalidateQueries(['candidate-interview', interviewId, slug]);
        },
    });

    const createResponse = useMutation(createResponseAndGetUploadUrl, {
        onSuccess: ({ createdResponse, uploadUrl, file }) => {
            confirmResponse.mutate({ createdResponse, uploadUrl, file });
            queryClient.invalidateQueries(['candidate-interview', interviewId, slug]);
        },
        retry: 2,
        retryDelay: 500,
    });

    return {
        isLoading,
        createResponse,
    };
};

export default useResponses;
