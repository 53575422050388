import { Button } from '@chakra-ui/react';
import { Company } from '../../types/schema';

import { useColors } from '../../styles/colors';
import { getColorBasedOnBg } from '../../utils';

const CompanyButton = ({ company, ...otherProps }: any) => {
    const colors = useColors();
    const themeColor = company?.themeColors?.[0] || colors.primary;

    return (
        <Button
            colorScheme="foreground"
            bg={themeColor}
            color={getColorBasedOnBg(themeColor, 'white', 'black')}
            {...otherProps}
        />
    );
};

export default CompanyButton;
