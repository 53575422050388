import styled from 'styled-components';
import FlexView from 'react-flexview';
import moment from 'moment';
import { GiDoubleFish } from 'react-icons/gi';

import Icon from './Icon';
import NotificationBell from './NotificationBell';
import ProfileCircle from './ProfileCircle';
import Search from './Search';
import fonts, { fontSizes } from '../styles/fonts';
import dimensions from '../styles/dimensions';
import Button from './Button';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCurrentCompany, useIsMobile, usePageTitle } from '../utils/hooks';
import { BodyDark, BodyLight, BodyText, ButtonText, Heading, SubHeading } from './Fonts';
import {
    Avatar,
    Box,
    Flex,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Spacer,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { useAuthFunctions, useAuthState } from '../context/auth';
import { useColors } from '../styles/colors';
import useUser from '../api/hooks/useUser';
import SwitchCompanyModal from '../pages/Settings/SwitchCompanyModal';
import { CgArrowLeft } from 'react-icons/cg';
import HelpDrawer, { HelpDrawerIconButton } from './HelpDrawer';
import analytics from '../utils/analytics';

interface Tab {
    name: string;
    active: boolean;
}

const NavBarContainer = styled.div`
    /* width: 100vw; */
    height: ${dimensions.navBarHeight};

    background-color: ${({ theme }) => theme.background};

    border-bottom: 1px solid ${({ theme }) => theme.foregroundDark};

    padding: 1em 2% 0em 2%;

    display: flex;
    position: relative;
    flex-direction: column;
`;

const TabsRow = styled.div`
    min-width: 350px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    justify-content: space-evenly;
    align-self: center;
`;

const TabContainer = styled.div`
    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        font-size: ${fontSizes.md};
        width: 35%;
    }

    cursor: pointer;
    /* font-size: ${fontSizes.xs}; */
    text-align: center;

    min-width: 100px;
    max-width: 200px;

    /* margin: 0 7%; */
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${(props) => props.theme.onBackground};
`;

const TabIndicator = styled.div<{ active: boolean }>`
    height: 0.4em;
    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        height: 0.3em;
    }

    background-color: ${({ theme, active }) => (active ? theme.primary : theme.background)};

    border-radius: 15px 15px 0 0;

    margin-top: 0.5em;
`;

const Tab: React.FC<TabInput> = ({ name, active, link, onClick }) => {
    const colors = useColors();

    const Name = () => (
        <ButtonText color={active ? colors.primary : colors.onBackgroundLight}>
            {name.toUpperCase()}
            <TabIndicator active={active} />
        </ButtonText>
    );

    return (
        <TabContainer onClick={onClick}>
            {link ? (
                <StyledLink to={link}>
                    <Name />
                </StyledLink>
            ) : (
                <Name />
            )}
        </TabContainer>
    );
};

const StyledBackButton = styled.div`
    ${fonts.buttonLight}

    cursor: pointer;
    display: flex;
    align-items: center;
`;

const BackButton = ({ showText = false }: { showText?: boolean }) => {
    const navigate = useNavigate();

    return (
        <StyledBackButton onClick={() => navigate(-1)}>
            <CgArrowLeft size="20" />
            {showText && 'BACK'}
        </StyledBackButton>
    );
};

const Profile = () => {
    const { companyId } = useParams();
    const { user, authenticated, permissions } = useAuthState();
    const { logout } = useAuthFunctions();

    const company = useCurrentCompany();

    const navigate = useNavigate();
    const navigateToProfile = () => {
        navigate(`/settings/${companyId}`);
    };

    // For company switch
    const { isOpen, onOpen, onClose } = useDisclosure();
    const companyOptions = permissions.map((p) => ({
        label: p.company.name,
        value: p.company.id,
        logo: p.company?.logos?.[0],
    }));

    const helpControl = useDisclosure();

    if (!authenticated) return <Box height="md" />;
    if (!user) return <Box height="md" />;
    if (!company) return <Box height="md" />;

    return (
        <Menu>
            <MenuButton cursor="pointer">
                <Avatar
                    showBorder
                    name={user.name}
                    src={user.picture?.[0]}
                    bg="secondaryLight.main"
                    color="onSecondary.main"
                    h={[10, 10, 12]}
                    w={[10, 10, 12]}
                />
            </MenuButton>

            <MenuList zIndex="modal" fontSize="md">
                <VStack p="3" textAlign="center">
                    <Avatar
                        name={user.name}
                        src={user.picture?.[0]}
                        bg="secondaryLight.main"
                        color="onSecondary.main"
                        size="xl"
                        cursor="pointer"
                        onClick={navigateToProfile}
                    />
                    <Box>
                        <SubHeading>{user.name}</SubHeading>
                        <BodyLight>{user.email}</BodyLight>
                    </Box>
                </VStack>

                <MenuDivider />

                <MenuItem
                    maxW="16rem"
                    onClick={() => {
                        analytics.event('Company Switch', 'Modal opened', 'Button clicked');
                        onOpen();
                    }}
                >
                    <Flex flex="1" align="center">
                        {company.name}
                        <Spacer />
                        {/* {companyOptions.length > 1 && (
                            <GiDoubleFish size="18" style={{ minWidth: 18 }} />
                        )} */}
                        <Box fontSize="xs" fontWeight="bold" color="primary.main">
                            SWITCH
                        </Box>
                    </Flex>
                </MenuItem>

                <MenuDivider />

                <MenuItem onClick={navigateToProfile}>Profile</MenuItem>

                <MenuItem color="black" onClick={helpControl.onOpen}>
                    Help
                </MenuItem>

                <MenuDivider />

                <MenuItem onClick={() => logout.mutate()}>Log out</MenuItem>
            </MenuList>

            <SwitchCompanyModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />

            <HelpDrawer {...helpControl} />
        </Menu>
    );
};

type TabInput = {
    name: string;
    active: boolean;
    link?: string;
    onClick?: () => void;
};

const VerificationNotification = () => {
    const colors = useColors();
    const { user } = useAuthState();

    const { sendVerificationLink } = useUser();
    const [timeLeft, setTimeLeft] = useState(0);
    const [show, setShow] = useState(false);

    const { isLoading, isSuccess, mutate, reset } = sendVerificationLink;

    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    useEffect(() => {
        if (isSuccess) {
            setTimeLeft(30);
            reset();
        }
    }, [isSuccess]);

    useEffect(() => {
        const isVerified = user?.isVerified;

        const createdAt = moment(user?.createdAt);
        const checkPeriod = moment().isAfter(createdAt);

        console.log('D_514', { isVerified, createdAt, checkPeriod });
        if (!isVerified && checkPeriod) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [user]);

    return show ? (
        <div
            style={{
                backgroundColor: colors.primary,
                color: '#fff',
                padding: 3,
                paddingLeft: 10,
                paddingRight: 10,
            }}
        >
            <BodyLight>
                Email not verified, click on the verification link recieved on your email.{' '}
                {isLoading ? (
                    <strong>Sending link...</strong>
                ) : timeLeft === 0 ? (
                    <span style={{ cursor: 'pointer' }} onClick={() => mutate()}>
                        <strong>Click here</strong> to recieve it again
                    </span>
                ) : (
                    <span>
                        <strong>Link sent successfully</strong>
                        {` `}Retry in {timeLeft} secs
                    </span>
                )}
            </BodyLight>
        </div>
    ) : (
        <></>
    );
};

export const Tabs: React.FC<{
    tabs?: Array<TabInput>;
}> = ({ tabs = [] }) => {
    return (
        <TabsRow>
            {tabs.map((tab) => (
                <Tab {...tab} />
            ))}
        </TabsRow>
    );
};

const NavBar: React.FC<{
    moduleName?: string;
    onlyTabs?: boolean;
    hideHelp?: boolean;
    hideProfile?: boolean;
    tabs?: Array<TabInput>;
    navBarRight?: any;
}> = ({
    moduleName = 'Candidates',
    onlyTabs,
    hideHelp,
    hideProfile,
    tabs = [],
    navBarRight: NavBarRight = () => <></>,
}) => {
    let { pathname = '' } = useLocation();
    const canGoBack = pathname.split('/').length - 1 > 2;

    const isMobile = useIsMobile();
    const showHeading = !isMobile;

    usePageTitle(`Sieve - ${moduleName}`);

    return (
        <div style={{ width: '100%' }}>
            <VerificationNotification />
            <NavBarContainer>
                {!onlyTabs && (
                    <FlexView vAlignContent="center">
                        {canGoBack && <BackButton showText={false} />}
                        {showHeading && <Heading marginLeft="0.5em">{moduleName}</Heading>}

                        {isMobile && NavBarRight}

                        <FlexView grow />

                        {/* <Search /> */}
                        {/* <NotificationBell /> */}

                        {!isMobile && NavBarRight}

                        {!hideHelp && <HelpDrawerIconButton ml={[1, 2, 8]} mr={[0, 1, 2]} />}
                        {!hideProfile && <Profile />}
                    </FlexView>
                )}

                {/* to align tabs to the bottom of the navbar if there is no content above */}
                <FlexView grow />

                <TabsRow>
                    {tabs.map((tab) => (
                        <Tab {...tab} />
                    ))}
                </TabsRow>
            </NavBarContainer>
        </div>
    );
};

export default NavBar;
