import styled, { DefaultTheme, css } from 'styled-components';
import dimensions from '../styles/dimensions';
import fonts, { fontSize, fontSizes } from '../styles/fonts';
import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';

enum ButtonColor {
    primary = 'primary',
    secondary = 'secondary',
    foreground = 'foreground',
}

enum ButtonVariant {
    inverted = 'inverted',
    text = 'text',
}

interface ButtonProps extends ChakraButtonProps {
    disabled?: boolean;
    onClick?: any;
    theme: DefaultTheme;

    color?: ButtonColor | string;
    variant?: ButtonVariant | string;

    margin?: string;
    marginLeft?: string;
    marginRight?: string;
    marginTop?: string;
    marginBottom?: string;
}

const color = ({ theme, color }: ButtonProps) =>
    (color === ButtonColor.secondary && theme.secondary) ||
    (color === ButtonColor.foreground && theme.foreground) ||
    theme.primary;

const colorDark = ({ theme, color }: ButtonProps) =>
    (color === ButtonColor.secondary && theme.secondaryDark) ||
    (color === ButtonColor.foreground && theme.foregroundDark) ||
    theme.primaryDark;

const backgroundColor = ({ theme, color }: ButtonProps) =>
    (color === ButtonColor.secondary && theme.onSecondary) ||
    (color === ButtonColor.foreground && theme.onForeground) ||
    theme.onPrimary;

const backgroundColorDark = ({ theme, color }: ButtonProps) => theme.backgroundDark;

const textButtonStyle = css<ButtonProps>`
    ${fonts.body}

    color: ${color};
    background: ${backgroundColor};

    &:hover {
        color: ${(props) => (props.disabled ? color : colorDark)};
    }

    &:hover:disabled {
        color: ${(props) => color};
    }

    border: none;

    padding: 0.4em 2em;
`;

const invertedButtonStyle = css<ButtonProps>`
    color: ${color};
    background: ${backgroundColor};

    &:hover {
        background: ${(props) => (props.disabled ? backgroundColor : backgroundColorDark)};
    }

    &:hover:disabled {
        background: ${(props) => backgroundColor};
    }

    border: 1.3px solid ${color};
`;

const defaultButtonStyle = css<ButtonProps>`
    color: ${backgroundColor};

    background: ${color};

    &:hover {
        background: ${(props) => (props.disabled ? color : colorDark)};
    }

    &:hover:disabled {
        background: ${(props) => color};
    }

    border: none;
`;

interface FontDivProps {
    margin?: string;
    marginLeft?: string;
    marginRight?: string;
    marginTop?: string;
    marginBottom?: string;

    color?: string;

    textAlign?: string;
}

const Button = styled(ChakraButton)<ButtonProps>`
    ${fonts.button}
    cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};

    border-radius: ${dimensions.borderRadius};

    padding: 0.8em 2em;

    margin: ${({ margin }) => margin || '0.5em'};
    margin-left: ${({ marginLeft }) => marginLeft};
    margin-right: ${({ marginRight }) => marginRight};
    margin-top: ${({ marginTop }) => marginTop};
    margin-bottom: ${({ marginBottom }) => marginBottom};

    ${({ variant }) =>
        (variant === ButtonVariant.text && textButtonStyle) ||
        (variant === ButtonVariant.inverted && invertedButtonStyle) ||
        defaultButtonStyle}
`;

export default Button;
