import {
    ModalOverlay,
    ModalContent,
    MenuList,
    MenuItem,
    MenuDivider,
    Flex,
    Box,
    Modal,
    Menu,
    Image,
    Avatar,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useCurrentCompany, useCurrentPermission } from '../../utils/hooks';
import { useAuthState } from '../../context/auth';
import analytics from '../../utils/analytics';
import { AddIcon } from '@chakra-ui/icons';

export default ({ isOpen, onOpen, onClose }: any) => {
    const navigate = useNavigate();

    const { permissions } = useAuthState();
    const permission = useCurrentPermission();
    const { company } = permission || {};

    const companyOptions = permissions.map((p) => ({
        label: p.company.name,
        value: p.company.id,
        logo: p.company?.logos?.[0],
    }));

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent w="80">
                <Menu isOpen>
                    <MenuList>
                        {companyOptions.map((o) => (
                            <MenuItem
                                key={o.value}
                                w="80"
                                p="2"
                                pl="4"
                                fontSize="lg"
                                onClick={() => {
                                    analytics.event(
                                        'Company Switch',
                                        'Successfully switched',
                                        'Button clicked',
                                    );
                                    navigate(`/${o.value}`);
                                }}
                                isDisabled={company.id === o.value}
                            >
                                <Flex align="center" fontWeight="500">
                                    {/* <Avatar m="1" mr="4" size="md" name={o.label} src={o.logo} /> */}
                                    <Image h="10" w="10" mr="4" src={o.logo} fit="contain" />

                                    {o.label}
                                    <Box fontSize="xs" fontWeight="bold" ml="4">
                                        {company.id === o.value && 'CURRENT'}
                                    </Box>
                                </Flex>
                            </MenuItem>
                        ))}
                        <MenuDivider />
                        <MenuItem
                            key={'new'}
                            w="80"
                            pl="4"
                            fontSize="lg"
                            onClick={() => {
                                analytics.event(
                                    'Company Switch',
                                    'Successfully switched',
                                    'Button clicked',
                                );
                                navigate(`/onboarding`);
                            }}
                        >
                            <Flex align="center" fontWeight="500" color="primary.main">
                                <AddIcon m="3" mr="6" />
                                New Company
                            </Flex>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </ModalContent>
        </Modal>
    );
};
