import { useEffect, useState } from 'react';
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import FlexView from 'react-flexview/lib';
import moment from 'moment';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import styled from 'styled-components';
import dimensions from '../../styles/dimensions';
import { Heading, SubHeading } from '../../components/Fonts';
import Form from '../../components/Form/FormContainer';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import DatePicker from '../../components/Form/DatePicker';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import { useManyPositions } from '../../api/hooks/usePositions';
import useManyInterviews from '../../api/hooks/useManyInterviews';
import analytics from '../../utils/analytics';
import MessageBox from '../../components/MessageBox';

const StyledDialog = styled(Dialog)`
    width: 90%;
    max-width: 30em;
    border-radius: ${dimensions.borderRadius};
`;

const CandidateAddSingle = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { companyId } = useParams();

    const { positions } = useManyPositions(companyId);
    const positionOptions = positions?.map((p) => ({ label: p.name, value: p.id })) || [];

    const {
        create,
        errors,
        interviews: interviewData,
        count,
        filterParams,
        setFilterParams,
        isLoading: isListLoading,
    } = useManyInterviews(companyId);
    const { mutate: createInterview, isLoading, isSuccess, reset } = create;

    const [email, setEmail] = useState<string>();
    const [name, setName] = useState<string>();
    const [deadline, setDeadline] = useState<string>();
    const [positionId, setPositionId] = useState<number>();

    useEffect(() => {
        if (isSuccess) {
            analytics.event('Single Interview', 'Successfully created', email as string);
            toast.success('Interview sent successfully!');
            reset();
            onClose();
        }
    }, [isSuccess]);

    console.log('E_13', filterParams.email, count);

    const navigate = useNavigate();

    return (
        <>
            <Button
                className="add-candidate-button" // for react-joyride
                onClick={() => {
                    analytics.event('Single Interview', 'Modal opened', 'Button clicked');
                    onOpen();
                }}
            >
                ADD
            </Button>

            <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
                <ModalOverlay />
                <ModalContent margin="1.5em">
                    <ModalHeader>Add new candidate</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FlexView column>
                            <Input
                                name="email"
                                label="Email"
                                type="email"
                                placeholder="Email"
                                error={errors.email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={(e) => setFilterParams({ email })}
                            />

                            {filterParams.email && !!count && (
                                <MessageBox
                                    status="info"
                                    title={count + ' Interviews with the same email'}
                                    description="See Interviews"
                                    onAction={() => {
                                        onClose();
                                        navigate(
                                            '/candidates/' +
                                                companyId +
                                                '?email=' +
                                                filterParams.email,
                                        );
                                    }}
                                />
                            )}

                            <Input
                                name="name"
                                type="name"
                                label="Name"
                                placeholder="Full Name"
                                error={errors.name}
                                onChange={(e) => setName(e.target.value)}
                            />

                            <Select
                                placeholder="Position"
                                label="Position"
                                options={positionOptions}
                                error={errors.positionId}
                                onChange={(p: typeof positionOptions[0]) => setPositionId(p.value)}
                            />

                            <DatePicker
                                name="deadline"
                                placeholder="Default is 2 weeks"
                                label="Deadline (optional)"
                                minDate={moment()}
                                error={errors.deadline}
                                onChange={(momentDate: moment.Moment) => {
                                    const date = momentDate
                                        ? moment(momentDate).endOf('day').format()
                                        : undefined;

                                    setDeadline(date);
                                }}
                            />
                        </FlexView>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                createInterview({
                                    name,
                                    email,
                                    positionId,
                                    deadline,
                                    companyId: parseInt(companyId as string),
                                });
                            }}
                            isLoading={isLoading}
                            loadingText="SENDING"
                            marginTop="1em"
                        >
                            SEND INTERVIEW
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CandidateAddSingle;
