import { Button, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAuthState } from '../context/auth';
import SwitchCompanyModal from '../pages/Settings/SwitchCompanyModal';
import { useIsReviewer } from '../utils/hooks';

export default ({ text, size, ...otherProps }: any) => {
    const isReviewer = useIsReviewer();
    const { permissions } = useAuthState();
    // const permissions = [1, 2];

    const { isOpen, onOpen, onClose } = useDisclosure();

    const buttonText =
        text || (permissions.length <= 1 ? 'Create own workspace' : 'Switch workspace');

    return (
        <>
            {permissions.length <= 1 ? (
                <Link to={isReviewer ? '/onboarding' : '/register'} target="_blank">
                    <Button
                        colorScheme="primary"
                        bg="primary.main"
                        size={size || 'md'}
                        {...otherProps}
                    >
                        {buttonText}
                    </Button>
                </Link>
            ) : (
                <Button
                    colorScheme="primary"
                    bg="primary.main"
                    size={size || 'md'}
                    onClick={onOpen}
                    {...otherProps}
                >
                    {buttonText}
                </Button>
            )}

            <SwitchCompanyModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        </>
    );
};
