// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import 'webrtc-adapter';
import RecordRTC from 'recordrtc';

import 'videojs-record/dist/css/videojs.record.css';
import 'videojs-record/dist/videojs.record.js';
import 'videojs-record/dist/plugins/videojs.record.ts-ebml.js';

import { SubHeading } from './Fonts';
import { useColors } from '../styles/colors';
import dimensions from '../styles/dimensions';

export let RecorderInstance;

export let AUDIO_DEVICE_ID;
export let updateAudioDevice = (id) => {
    AUDIO_DEVICE_ID = id;
    RecorderInstance.setAudioInput(id);
};
export let VIDEO_DEVICE_ID;
export let updateVideoDevice = (id) => {
    VIDEO_DEVICE_ID = id;
    RecorderInstance.setVideoInput(id);
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isEdge = /Edge/.test(navigator.userAgent);
const isOpera = !!window.opera || navigator.userAgent.indexOf('OPR/') !== -1;
// To test if conversion with ts-ebml is needed
const isChrome = !!window.chrome || /chrome/.test(navigator.userAgent?.toLowerCase());
const isAppleDevice = /iphone|ipod|ipad|mac/.test(navigator.userAgent?.toLowerCase());

const videoJsOptions = {
    bigPlayButton: false,
    width: 640,
    height: 480,
    fluid: true,
    aspectRatio: '4:3',
    controls: false,
    controlBar: {
        recordToggle: false,
        volumePanel: false,
        pipToggle: false,
        fullscreenToggle: false,
        durationDisplay: false,
    },
    plugins: {
        record: {
            autoMuteDevice: true,
            maxLength: 900,
            debug: true,
            audio: true,
            video: {
                // video media constraints: set resolution of camera
                width: 640,
                height: 480,
            },
            // dimensions of captured video frames
            frameWidth: 640,
            frameHeight: 480,

            convertEngine: isAppleDevice ? '' : 'ts-ebml',

            // videoEngine: 'webm-wasm',
            // videoWorkerURL: '../../node_modules/webm-wasm/dist/webm-worker.js',
            // videoWebAssemblyURL: 'webm-wasm.wasm',
            // videoBitRate: 1200,
            videoFrameRate: 24,
            videoMimeType: isChrome && 'video/webm;codecs=vp8', // otherwise chrome gives mkv which doesn't play on firefox developer, safari still not working
        },
    },
};

if (isSafari || isEdge) {
    if (isSafari && window.MediaRecorder !== undefined) {
        // this version of Safari has MediaRecorder
        // but use the only supported mime type
        videoJsOptions.plugins.record.audioMimeType = 'audio/mp4';
    } else {
        // support recording in safari 11/12
        // see https://github.com/collab-project/videojs-record/issues/295
        videoJsOptions.plugins.record.audioRecorderType = StereoAudioRecorder;
        videoJsOptions.plugins.record.audioSampleRate = 44100;
        videoJsOptions.plugins.record.audioBufferSize = 4096;
        videoJsOptions.plugins.record.audioChannels = 2;
    }

    console.log('applied audio workarounds for this browser');
}

// use correct video mimetype for opera
if (isOpera) {
    videoJsOptions.plugins.record.videoMimeType = 'video/webm;codecs=vp8'; // or vp9
}

const RecorderContainer = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;

    width: 100%;
    max-width: 40em;
    max-height: 30em;
    aspect-ratio: 4 / 3;
    overflow: hidden;

    background: #222;
    border-radius: 1em;

    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        /* border-radius: 0 0 1em 1em; */
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const StyledVideo = styled.video`
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
`;

const StyledTimer = styled(SubHeading)`
    position: absolute;
    bottom: 1em;
    right: 1.2em;
    background-color: rgba(255, 255, 255, 0.5);
    color: ${(props) => props.theme.secondary};

    padding: 0.4em;
    border-radius: 2em;
`;

const StyledMicVisualizer = styled.div`
    position: absolute;
    bottom: 1em;
    left: 1.2em;

    height: 2.4em;
    width: 2.4em;
    border-radius: 1em;

    background-color: rgba(255, 255, 255, 0.6);
`;

const MicVisualizer = ({
    stream,
    onMicVolumeChange,
}: {
    stream: MediaStream;
    onMicVolumeChange?: (volume: number) => void;
}) => {
    const analyserCanvas: any = React.useRef(null);
    let animationFrame = 0;
    let audioCtx = null;

    const height = 34;
    const width = 34;
    const color = useColors().primaryDark;

    const visualizeBars = () => {
        audioCtx = new AudioContext();
        const analyser = audioCtx.createAnalyser();
        analyser.fftSize = 32; // number of bars
        analyser.smoothingTimeConstant = 0.8;

        const audioSrc = audioCtx.createMediaStreamSource(stream);
        audioSrc.connect(analyser);

        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const canvasCtx = analyserCanvas.current.getContext('2d');
        canvasCtx.clearRect(0, 0, width, height);

        let max = 1;

        function draw() {
            analyser.getByteFrequencyData(dataArray);

            const arraySum = dataArray.reduce((sum, value) => sum + value, 0);
            const nonZeroArray = dataArray.filter((v) => v); // There were a lot of zeroes on mobiles
            const average = arraySum / (nonZeroArray.length || 1);

            const currentMax = Math.max(...dataArray);
            max = currentMax > max ? currentMax : max; // To normalize volumne on various devices

            // Normalize volume in range 0-9
            onMicVolumeChange && onMicVolumeChange((average / max) * 9);

            // Clear canvas
            canvasCtx.clearRect(0, 0, width, height);

            const barWidth = width / 7; // 3 bars and 4 spaces
            let barHeight;
            let x = width / 7;

            // Draw 3 bars using average
            for (let i = 0; i < 3; i++) {
                barHeight = (average / max) * height;
                if (barHeight > height * 0.8) barHeight = height * 0.8;
                if (barHeight <= height / 10) barHeight = height / 10;

                if (i !== 1) barHeight = barHeight * 0.7; // Side bars are shorter

                canvasCtx.fillStyle = color;
                canvasCtx.fillRect(x, height / 2 - barHeight / 2, barWidth, barHeight);

                x += barWidth * 2;
            }

            animationFrame = requestAnimationFrame(draw);
        }

        animationFrame = requestAnimationFrame(draw);
    };

    useEffect(() => {
        stream && visualizeBars();

        return () => {
            cancelAnimationFrame(animationFrame);
            // not working as intended
            // There maybe multiple contexts open that set the old value of volume
            // audioCtx?.close();
        };
    }, [stream]);

    return (
        <StyledMicVisualizer>
            <canvas ref={analyserCanvas}></canvas>
        </StyledMicVisualizer>
    );
};

const VideoRecorder = ({
    recordOnStart = true,
    onRecordingAvailable,
    hideTimer,
    hideRec,
    onMicVolumeChange,
}: any) => {
    const videoRef = useRef<any>(null);

    const [recordingSeconds, setRecordingSeconds] = useState(0);

    useEffect(() => {
        const player = videojs(videoRef.current, videoJsOptions, () => {
            // print version information at startup
            const version_info =
                'Using video.js ' +
                videojs.VERSION +
                ' with videojs-record ' +
                videojs.getPluginVersion('record') +
                ' and recordrtc ' +
                RecordRTC.version;
            videojs.log(version_info);
        });

        RecorderInstance = player.record();
        AUDIO_DEVICE_ID && RecorderInstance.setAudioInput(AUDIO_DEVICE_ID);
        VIDEO_DEVICE_ID && RecorderInstance.setVideoInput(VIDEO_DEVICE_ID);

        // Start device on load
        RecorderInstance.getDevice();

        if (hideRec) {
            player.recordIndicator?.disable();
        }

        player.on('deviceError', () => {
            console.log('device error:', player.deviceErrorCode);
        });

        player.on('error', (element, error) => {
            console.error(error);
        });

        player.on('deviceReady', () => {
            console.log('device ready');
            if (recordOnStart) {
                RecorderInstance.start();
            }
        });

        // user clicked the record button and started recording
        player.on('startRecord', () => {
            // console.log('started recording!');
        });

        player.on('progressRecord', () => {
            setRecordingSeconds(parseInt(player.record().getDuration()));
        });

        // user completed recording and stream is available
        player.on('finishRecord', () => {
            // the blob object contains the recorded data that
            // can be downloaded by the user, stored on server etc.
            console.log('finished recording: ', player.recordedData);
            onRecordingAvailable && isAppleDevice && onRecordingAvailable(player.recordedData);
        });

        // added metadata to the blob, doesn't work on Apple
        player.on('finishConvert', () => {
            // the blob object contains the recorded data that
            // can be downloaded by the user, stored on server etc.
            console.log('finished converting: ', player.convertedData);
            onRecordingAvailable && !isAppleDevice && onRecordingAvailable(player.convertedData);
        });

        return () => {
            player?.dispose(); // remove event listeners, destroy player on unmount
        };
    }, []);

    return (
        <RecorderContainer>
            <StyledVideo ref={videoRef} playsInline className="video-js vjs-default-skin" />

            {!hideTimer && (
                <StyledTimer>
                    {recordingSeconds
                        ? moment({}).minutes(0).seconds(recordingSeconds).format('mm:ss')
                        : 'Loading'}
                </StyledTimer>
            )}

            <MicVisualizer
                stream={RecorderInstance?.stream}
                onMicVolumeChange={onMicVolumeChange}
            />
        </RecorderContainer>
    );
};

export default VideoRecorder;
