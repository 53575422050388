import { Flex, Image, Heading } from '@chakra-ui/react';
import { useState } from 'react';

const AppLogo = ({ color, onlyText, onlyLogo, ...otherProps }: any) => {
    const [showLogo, setShowLogo] = useState(onlyText ? false : true);

    return (
        <Flex cursor="pointer" align="flex-end">
            {!onlyText && (
                <Image boxSize={['1.4rem', '1.4rem', '1.7rem']} src="/logo_sq_dark.svg" />
            )}
            {!onlyLogo && (
                <Heading
                    color={color}
                    fontWeight="thin"
                    letterSpacing={6}
                    size="sm"
                    ml={[2, 2, 3]}
                    {...otherProps}
                >
                    SIEVE
                </Heading>
            )}
        </Flex>
    );
};

export default AppLogo;
