import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Box,
    Center,
    Heading,
    Spinner,
    Text,
    Link,
    Flex,
    Spacer,
    Image,
    VStack,
    Progress,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import useInterviewCandidate from '../../api/hooks/useInterviewCandidate';
import CompanyButton from '../../components/Company/CompanyButton';
import { useAppState } from '../../context/app';
import { useColors } from '../../styles/colors';
import { Footer, Header } from './components';

export default () => {
    const colors = useColors();

    const { interviewId, slug } = useParams();
    const { interview, confirmedResponseLength, interviewLength } = useInterviewCandidate({
        interviewId,
        slug,
    });
    const company = interview?.company;
    const themeColor = company?.themeColors?.[0] || colors.primary;

    const isUploaded = interview?.status === 'REVIEW_PENDING';

    const { progress } = useAppState();
    // const progress: any = {
    //     1: {
    //         speed: 1209,
    //         percent: 68,
    //     },
    //     2: {
    //         speed: 789,
    //         percent: 100,
    //     },
    // };
    console.log('OUTRO UPLOADING:  ==>', progress);

    return (
        <Flex h="100%" flexDir="column" align="center">
            <Header company={company} />

            <Center height="100%" flexDir="column">
                {!!isUploaded ? (
                    <>
                        <Image src="/interview_submitted.svg" height="48" mt="-4" />

                        <Heading textAlign="center" size="lg" m="4" mt="8">
                            Interview{' '}
                            <Box as="span" color={themeColor}>
                                Submitted{' '}
                            </Box>
                            Successfully
                        </Heading>

                        <VStack
                            textAlign="center"
                            m="4"
                            fontWeight="500"
                            maxW="md"
                            lineHeight="1.2em"
                            spacing="5"
                        >
                            <Box>
                                Thank you {interview?.candidate.name} for completing the interview.
                            </Box>
                            <Box>
                                It is being currently reviewed by our team and upon assessment, you
                                shall receive an email notification.
                            </Box>
                            <Box>
                                Meanwhile, you can tell us about your interview experience and how
                                we can make it better.
                            </Box>
                        </VStack>

                        <Link
                            href="https://forms.gle/PHPE7es2nxiVctwY9"
                            isExternal
                            style={{ textDecoration: 'none', marginTop: '3em' }}
                        >
                            <CompanyButton company={company} type="submit">
                                SUBMIT FEEDBACK <ExternalLinkIcon ml="3" />
                            </CompanyButton>
                        </Link>
                    </>
                ) : (
                    <>
                        <Text m="2em" my="16" textAlign="center" fontWeight="500" color="red.500">
                            Don't Close the window, while the interview is being uploaded.
                        </Text>

                        {Object.keys(progress).map((questionId, index) => {
                            const { percent, speed } = progress[questionId];

                            return (
                                <Box p="4" w="100%">
                                    <Flex align="center">
                                        <Heading size="sm" flex="1">
                                            Question {index + 1}
                                        </Heading>

                                        {percent !== 100 &&
                                            (speed >= 1000
                                                ? `${(speed / 1000).toFixed(1)} mbps`
                                                : `${speed} kbps`)}

                                        <Heading size="xs" ml="4">
                                            {percent}%
                                        </Heading>
                                    </Flex>
                                    <Progress
                                        size="sm"
                                        hasStripe={percent !== 100}
                                        isAnimated={percent !== 100}
                                        value={percent}
                                        mt="2"
                                    />
                                </Box>
                            );
                        })}
                    </>
                )}
            </Center>

            <Spacer />

            <Footer hideImage={isUploaded} />
        </Flex>
    );
};
