import styled from 'styled-components';
import Select from 'react-select';
import fonts, { fontSizes } from '../../styles/fonts';
import dimensions from '../../styles/dimensions';
import FormField from './FormField';

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];

const StyledSelect = styled(Select)`
    flex-grow: 1;

    .Select__control {
        cursor: pointer;
        min-width: 10em;

        ${fonts.body}
        font-size: ${fontSizes.md};

        color: ${(props) => props.theme.onBackground};

        background: ${(props) => props.theme.foreground};
        border: none;
        border-radius: ${dimensions.borderRadius};

        &:hover {
            background: ${(props) => props.theme.backgroundDark};
        }

        &:focus {
            box-shadow: 0 0 0 1px black;
            /* background: ${(props) => props.theme.foregroundDark}; */
            outline: none;
        }

        padding: 0.42em;
        padding-left: 0.2em;
    }

    .Select__indicator-separator {
        display: none;
    }

    .Select__menu {
        background: ${(props) => props.theme.background};
        color: ${(props) => props.theme.onForeground};
    }
`;

export default (props: any) => (
    <FormField label={props.label} error={props.error} labelRight={props.labelRight}>
        <StyledSelect
            classNamePrefix="Select"
            closeMenuOnSelect={!props.isMulti}
            isDisabled={props.disabled}
            {...props}
        />
    </FormField>
);
