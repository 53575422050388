import { useEffect, useState } from 'react';
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { CommentType, ErrorType } from '../../types/global';
import { InterviewCandidate } from '../../types/schema';
import useAxios from './useAxios';

const useInterviewEvaluation = ({
    companyId,
    interviewId,
    publicSlug,
}: {
    companyId: string | undefined;
    interviewId: string | undefined;
    publicSlug: string | undefined;
}) => {
    const [errors, setErrors] = useState<ErrorType>({});
    const { api, isReady } = useAxios();

    const getInterviewForEvaluation = async ({
        queryKey: [_, interviewId, companyId, publicSlug],
    }: QueryFunctionContext): Promise<{
        success: boolean;
        [key: string]: any;
    }> => {
        const { data } = await api.post(`/interviews/getForEvaluation`, {
            companyId: parseInt(companyId as string),
            interviewId,
            publicSlug,
        });

        return data.interview;
    };

    const { data: interview, refetch } = useQuery({
        queryKey: ['evaluation', interviewId, companyId, publicSlug],
        queryFn: getInterviewForEvaluation,
        enabled: !!interviewId && !!publicSlug && !!companyId && isReady,
        onError: (error: any) => setErrors(error.response.data),
    });

    const setInterviewPublic = async (isPublic: boolean): Promise<{ success: boolean }> => {
        const { data } = await api.put('/interviews/setPublic', {
            interviewId: parseInt(interviewId as string),
            companyId: parseInt(companyId as string),
            isPublic,
            publicSlug,
        });
        return data.updatedInterview;
    };

    const addCommentText = async (text: string): Promise<CommentType> => {
        const { data } = await api.post('/comments/create', {
            companyId: parseInt(companyId as string),
            interviewId: parseInt(interviewId as string),
            publicSlug,
            text,
        });
        return data.createdComment;
    };

    const deleteCommentText = async (commentId: number): Promise<CommentType> => {
        const { data } = await api.delete('/comments/remove', {
            params: { companyId, publicSlug, commentId },
        });
        return data.deletedComment;
    };

    const verdict = async (
        verdict: string,
    ): Promise<{ createdComment: CommentType; updatedInterview: InterviewCandidate }> => {
        const { data } = await api.post('/comments/verdict', {
            companyId: parseInt(companyId as string),
            interviewId: parseInt(interviewId as string),
            publicSlug,
            verdict,
        });
        return data;
    };

    const rating = async (
        rating: number,
    ): Promise<{ comment: CommentType; updatedInterview: InterviewCandidate }> => {
        const { data } = await api.post('/comments/rating', {
            companyId: parseInt(companyId as string),
            interviewId: parseInt(interviewId as string),
            publicSlug,
            rating,
        });
        return data;
    };

    const queryClient = useQueryClient();

    const setIsPublic = useMutation(setInterviewPublic, {
        onMutate: () => setErrors({}),
        onSuccess: (updatedInterview) => {
            queryClient.setQueryData(
                ['evaluation', interviewId, companyId, publicSlug],
                updatedInterview,
            );
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const addText = useMutation(addCommentText, {
        onSuccess: (createdComment) => {
            queryClient.setQueryData(
                ['evaluation', interviewId, companyId, publicSlug],
                (interview: any) => {
                    const comments = [createdComment, ...interview.comments];
                    return { ...interview, comments };
                },
            );
        },
    });

    const deleteText = useMutation(deleteCommentText, {
        onSuccess: (deletedComment) => {
            queryClient.setQueryData(
                ['evaluation', interviewId, companyId, publicSlug],
                (interview: any) => {
                    const comments = interview.comments.filter(
                        ({ id }: CommentType) => id !== deletedComment.id,
                    );
                    return { ...interview, comments };
                },
            );
        },
    });

    const setVerdict = useMutation(verdict, {
        onSuccess: ({ updatedInterview, createdComment }) => {
            queryClient.setQueryData(
                ['evaluation', interviewId, companyId, publicSlug],
                (interview: any) => {
                    /**
                     * Add the created comment and updated data
                     */
                    const comments = [createdComment, ...interview.comments];
                    return { ...interview, ...updatedInterview, comments };
                },
            );

            queryClient.setQueriesData('candidate-list', (data: any) => {
                if (!data) return data;

                /**
                 * Add the created comment and updated data
                 */
                const interviews = data.interviews.map((int: any) => {
                    if (updatedInterview.id === int.id) {
                        const comments = [createdComment, ...int.comments];
                        return { ...int, ...updatedInterview, comments };
                    }

                    return int;
                });

                return { ...data, interviews };
            });
        },
    });

    const injectRatingComment = (comment: CommentType, interview: any) => {
        const doesCommentExists = interview.comments.find(
            ({ id }: CommentType) => id === comment.id,
        );

        let comments = interview.comments;
        if (doesCommentExists) {
            comments = [comment, ...comments.filter(({ id }: CommentType) => id !== comment.id)];
        } else {
            comments = [comment, ...comments];
        }

        return { ...interview, comments };
    };

    const setRating = useMutation(rating, {
        onSuccess: ({ updatedInterview, comment }) => {
            queryClient.setQueryData(
                ['evaluation', interviewId, companyId, publicSlug],
                (interview: any) => {
                    /**
                     * Add the created comment and updated data
                     */
                    const injectedComments = injectRatingComment(comment, interview);
                    return { ...injectedComments, ...updatedInterview };
                },
            );

            queryClient.setQueriesData('candidate-list', (data: any) => {
                if (!data) return data;

                /**
                 * Add the created comment and updated data
                 */
                const interviews = data.interviews.map((int: any) => {
                    if (updatedInterview.id === int.id) {
                        const injectedComments = injectRatingComment(comment, int);
                        return { ...injectedComments, ...updatedInterview };
                    }

                    return int;
                });

                return { ...data, interviews };
            });
        },
    });

    return {
        errors,
        setErrors,
        interview,
        setIsPublic,
        addText,
        deleteText,
        setVerdict,
        setRating,
    };
};

export default useInterviewEvaluation;
