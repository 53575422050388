import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import useUser from '../../api/hooks/useUser';
import Button from '../../components/Button';
import MessageBox from '../../components/MessageBox';
import { BodyText, Heading } from '../../components/Fonts';
import Form from '../../components/Form/FormContainer';
import Input from '../../components/Form/Input';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';
import dimensions from '../../styles/dimensions';
import fonts from '../../styles/fonts';
import { Image } from '@chakra-ui/react';
import analytics from '../../utils/analytics';

const FORM_MAX_WIDTH = 500;

const MainContainer = styled.div`
    height: 100%;

    display: flex;
    align-items: center;
`;

const Container = styled.div`
    width: 60%;
    min-width: 300px;
    max-width: 420px;

    display: flex;
    flex-direction: column;

    margin: 0 auto;
`;

const Art = styled.div`
    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        display: none;
    }

    height: 100%;
    width: 5%;
    min-width: 60px;
    max-width: 150px;

    background-color: ${({ theme }) => theme.secondary};
`;

const NoAccountDiv = styled.div`
    text-align: center;

    ${fonts.body}
`;

const ForgotPasswordButton = styled(Button)`
    margin-top: 4em;
`;

export default () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const { userId, slug } = useParams();
    const { resetPassword, errors, setErrors } = useUser();
    const [newPassword, setNewPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();

    const resetUserPassword = () => {
        if (newPassword !== confirmPassword) {
            setErrors({ confirmPassword: `Passwords don't match` });
            return;
        }

        if (!userId || !slug) {
            setErrors({ message: 'Invalid verification URL' });
            return;
        }

        resetPassword.mutate({ slug, userId: parseInt(userId), newPassword });
    };

    return (
        <MainContainer>
            <Art />

            <Container>
                <Heading marginTop="3em">Reset Password</Heading>

                {!resetPassword.isSuccess ? (
                    <>
                        <BodyText marginTop="1em">Enter the details to set a new password</BodyText>
                        <FlexView width="100%" column grow marginTop="4em">
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    analytics.event(
                                        'Forgot Password',
                                        'New Password Set',
                                        'Button clicked',
                                    );
                                    resetUserPassword();
                                }}
                            >
                                <Input
                                    name="newPassword"
                                    placeholder="New Password"
                                    type="password"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    autoComplete="off"
                                    error={errors.newPassword}
                                />

                                <Input
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    type="password"
                                    autoComplete="off"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    error={errors.confirmPassword}
                                />

                                <Button
                                    disabled={!newPassword}
                                    isLoading={resetPassword.isLoading}
                                    type="submit"
                                    marginTop="2rem"
                                >
                                    SET NEW PASSWORD
                                </Button>
                            </Form>
                        </FlexView>
                    </>
                ) : (
                    <>
                        <MessageBox
                            status="success"
                            title="Password Changed"
                            description="Your password was successfully changed. You can now login using new credentials"
                        ></MessageBox>
                        <NoAccountDiv>
                            <Link to="/login">
                                <ForgotPasswordButton variant="text">Login</ForgotPasswordButton>
                            </Link>
                        </NoAccountDiv>
                    </>
                )}

                {!!errors.message && (
                    <MessageBox
                        status="error"
                        title="Error"
                        description={errors.message}
                    ></MessageBox>
                )}
            </Container>

            <Image
                src="/12.svg"
                position="absolute"
                maxH={['15rem', '20rem', '22rem']}
                bottom="0"
                right="0"
                opacity="0.9"
            />
        </MainContainer>
    );
};
