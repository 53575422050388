import { useEffect, useState } from 'react';
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { apiAuth } from '.';
import { InterviewCandidate, Question } from '../../types/schema';

const useInterviewCandidate = ({
    interviewId,
    slug,
}: {
    interviewId: string | undefined;
    slug: string | undefined;
}) => {
    const getInterviewForCandidate = async ({
        queryKey: [_, interviewId, slug],
    }: QueryFunctionContext): Promise<InterviewCandidate> => {
        const { data } = await apiAuth.post(`/interviews/getOne`, {
            interviewId: parseInt(interviewId as string),
            slug,
        });
        return data.interview;
    };

    const { data: interview, isLoading } = useQuery({
        queryKey: ['candidate-interview', interviewId, slug],
        queryFn: getInterviewForCandidate,
        enabled: !!interviewId && !!slug,
    });

    const initiateInterview = async (): Promise<boolean> => {
        /** To log from where the interview is being initiated */

        const page = window.location.href;
        await apiAuth.get(`/interviews/initiate`, {
            params: { id: interviewId, slug, page: page.split('/')[4] },
        });
        return true;
    };

    const confirmInterview = async (): Promise<boolean> => {
        await apiAuth.put(`/interviews/confirm`, {
            id: parseInt(interviewId as string),
            slug,
        });
        return true;
    };

    const queryClient = useQueryClient();
    const initiate = useMutation(initiateInterview, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['candidate-interview', interviewId, slug]);
        },
    });

    const confirm = useMutation(confirmInterview, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['candidate-interview', interviewId, slug]);
        },
    });

    /** Derived Values */
    const activeOrderNumber = interview?.progress || 0;
    const currentQuestion = interview?.position.questions.find(
        ({ orderNumber }: Question) => activeOrderNumber === orderNumber,
    );

    const interviewLength = interview?.position.questions.length;
    const isFinished = interviewLength && activeOrderNumber + 1 > interviewLength;
    const isFinalQuestion = activeOrderNumber + 1 === interviewLength;

    const confirmedResponseLength = interview?.responses.filter(({ storageStatus }: any, index) => {
        return storageStatus === 'CONFIRMED';
    }).length;

    const isCompleted =
        interviewLength && confirmedResponseLength && interviewLength === confirmedResponseLength;

    useEffect(() => {
        if (isCompleted && interview?.status === 'UNDERWAY') confirm.mutate();
    }, [isCompleted]);

    return {
        isLoading,
        interview,
        initiate,
        activeOrderNumber,
        currentQuestion,
        interviewLength,
        isFinished,
        isFinalQuestion,
        isCompleted,
        confirmedResponseLength,
    };
};

export default useInterviewCandidate;
