import { useState } from 'react';
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { ErrorType } from '../../types/global';
import { Permission } from '../../types/schema';
import useAxios from './useAxios';

interface AddMemberParams {
    email: string;
    role: string;
    jobTitle: string;
    positions: Array<number> | null;
    message?: string;
}

interface ChangeRoleParams {
    role: string;
    jobTitle: string;
    id: number;
    positions: Array<number> | null;
}

const useWorkspace = (companyId: string | undefined) => {
    const { api, isAuthenticated } = useAxios();
    const [errors, setErrors] = useState<ErrorType>({});

    const getManyPermission = async ({
        queryKey: [_, companyId],
    }: QueryFunctionContext): Promise<Array<Permission>> => {
        const { data } = await api.get(`/permissions/getMany`, { params: { companyId } });
        return data.permissions;
    };

    const { data: permissions, isLoading } = useQuery({
        queryKey: ['permissions-list', companyId],
        queryFn: getManyPermission,
        enabled: !!companyId && isAuthenticated,
        onError: (error: any) => setErrors(error.response.data),
    });

    const addPermission = async (createData: AddMemberParams): Promise<Permission> => {
        const { data } = await api.post(`/permissions/add`, {
            companyId: parseInt(companyId as string),
            ...createData,
        });
        return data.permission;
    };

    const changePermission = async (changeData: ChangeRoleParams): Promise<Permission> => {
        const { data } = await api.put(`/permissions/change`, {
            companyId: parseInt(companyId as string),
            ...changeData,
        });
        return data.updatedPermission;
    };

    const removePermission = async (id: number): Promise<boolean> => {
        const { data } = await api.delete(`/permissions/remove`, { params: { companyId, id } });
        return data.success;
    };

    const queryClient = useQueryClient();

    const addMember = useMutation(addPermission, {
        onMutate: () => setErrors({}),
        onSuccess: () => {
            queryClient.invalidateQueries(['permissions-list', companyId]);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const changeRole = useMutation(changePermission, {
        onMutate: () => setErrors({}),
        onSuccess: () => {
            queryClient.invalidateQueries(['permissions-list', companyId]);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const removeMember = useMutation(removePermission, {
        onMutate: () => setErrors({}),
        onSuccess: () => {
            queryClient.invalidateQueries(['permissions-list', companyId]);
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    return {
        isLoading,
        errors,
        setErrors,
        permissions: permissions || [],
        addMember,
        removeMember,
        changeRole,
    };
};

export default useWorkspace;
