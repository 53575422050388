import { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

export default ({
    label,
    labelRight,
    error,
    value,
    children,
    containerStyle,
}: {
    label?: any;
    labelRight?: any;
    error?: string;
    value?: any;
    children: any;
    containerStyle?: any;
}) => {
    return (
        <Flex flexDir="column" m="0.5em" flexGrow="inherit" style={containerStyle}>
            <Flex align="center">
                {label && (
                    <Text m="2px" mt="0" fontWeight="500" fontSize="xs" color="gray.500">
                        {label}
                    </Text>
                )}
                {labelRight}
            </Flex>

            {children}

            {error && (
                <Text m="1" mb="0" color="red.main" fontWeight="500">
                    {error}
                </Text>
            )}
        </Flex>
    );
};
