import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/react';
import FlexView from 'react-flexview/lib';
import { useParams } from 'react-router-dom';
import usePermissions from '../../api/hooks/usePermissions';

import { BodyDark, BodyLight, BodyText, Heading, SubHeading } from '../../components/Fonts';
import Loading from '../../components/Loading';
import { ROLE_OPTIONS } from '../../utils/constants';

export default () => {
    const { permissionId, slug } = useParams();
    const { permission, isRegistered } = usePermissions({ permissionId, slug });

    if (!permission) return <Loading />;
    const roleValue = ROLE_OPTIONS.find((o) => o.value === permission.role);

    return (
        <Box maxW="sm">
            <Box textAlign="center">
                <Heading>Invitation</Heading>
                <BodyText>
                    You've received the following invitation to make contributions to a workspace
                </BodyText>
            </Box>

            <Box bg="foreground.main" borderRadius="md" p="6" mt="8">
                <BodyLight>From</BodyLight>
                <Heading>{permission.company.name}</Heading>

                <BodyLight width="3.5rem" marginTop="1rem">
                    Position
                </BodyLight>
                <BodyDark>{permission.jobTitle}</BodyDark>

                <BodyLight width="3.5rem" marginTop="0.5em">
                    For role
                </BodyLight>
                <BodyDark>{roleValue?.label}</BodyDark>
            </Box>

            {isRegistered && (
                <Box bg="foreground.main" borderRadius="md" p="6" mt="8">
                    <BodyLight>To</BodyLight>
                    <Heading>{permission.user.name}</Heading>
                    <BodyDark>{permission.user.email}</BodyDark>
                </Box>
            )}
        </Box>
    );
};
