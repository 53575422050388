import { useEffect, useState } from 'react';
import '@reach/dialog/styles.css';
import FlexView from 'react-flexview/lib';
import moment from 'moment';
import {
    Center,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    VStack,
    Box,
    Spacer,
    Flex,
} from '@chakra-ui/react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import dimensions from '../../styles/dimensions';
import { BodyDark, BodyLight, BodyText, SubHeading } from '../../components/Fonts';
import Select from '../../components/Form/Select';
import DatePicker from '../../components/Form/DatePicker';
import Input from '../../components/Form/Input';
import FilePicker, { FileTypes } from '../../components/Form/FilePicker';
import { useCurrentCompany, useIsMobile } from '../../utils/hooks';
import { useParams } from 'react-router-dom';
import { useManyPositions } from '../../api/hooks/usePositions';
import { Position } from '../../types/schema';
import useManyInterviews from '../../api/hooks/useManyInterviews';
import analytics from '../../utils/analytics';

interface Candidate {
    name?: string;
    email?: string;
    position?: string;
    positionId?: number;
    companyId?: number;
}

const candidateFields = [
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'email', minWidth: '18em' },
    { label: 'Position', value: 'position' },
    // { label: 'Deadline', value: 'deadline' },
];

const checkRow = async (row: any, positions: any) => {
    const validEmail = (e: string) => e && /^[^@]+@[^@]+\.[^@]+$/.test(e);
    const validPhone = (e: string) => e && /^[0-9()-]+$/.test(e) && e.length === 10;
    const validFormat = (name: string) =>
        positions.find((p: any) => p.name.toLowerCase() === name.toLowerCase());
    const validDate = (date: string) =>
        !date || (moment(date).isValid() && moment(date).isAfter(moment()));

    const check = (r: any) =>
        (!validEmail(r.email) && 'Invalid Email') ||
        // (!validPhone(r.phone) && 'Invalid Phone') ||
        (!validFormat(r.format) && 'No format with this name') ||
        (!validDate(r.expiryTimestamp) && 'Invalid deadline') ||
        'Valid';

    return check(row);
};

const CandidateRow = ({
    index,
    candidate,
    setCandidate,
    errors,
    positions,
}: {
    index: number;
    candidate: Candidate;
    setCandidate: any;
    errors: { [key: string]: string };
    positions: Array<Position>;
}) => {
    const positionOptions = positions?.map((p: Position) => ({ label: p.name, value: p.id })) || [];

    return (
        <FlexView>
            <Input
                name="name"
                placeholder="Name"
                value={candidate.name || ''} // prev value shows if undefined
                error={errors[`interviews[${index}].name`]}
                onChange={(e) => setCandidate({ ...candidate, name: e.target.value })}
            />
            <Input
                name="email"
                placeholder="Email"
                value={candidate.email || ''} // prev value shows if undefined
                error={errors[`interviews[${index}].email`]}
                minWidth="18em"
                onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
            />
            <Select
                name="position"
                placeholder="Position"
                value={positionOptions.find((o) => o.value === candidate.positionId) || null}
                error={errors[`interviews[${index}].positionId`]}
                options={positionOptions}
                onChange={(p: typeof positionOptions[0]) => {
                    setCandidate({ ...candidate, positionId: p.value });
                }}
            />
        </FlexView>
    );
};

const DownloadTemplateSheet = () => {
    const { companyId } = useParams();
    const { positions } = useManyPositions(companyId);

    const exportExcel = async () => {
        analytics.event('Multiple Interviews', 'Template Sheet Downloaded', `Button clicked`);
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Sheet 1');

        // Add positions sheet for dropdown list
        const positionsSheet = workbook.addWorksheet('POSITIONS', { state: 'veryHidden' });
        positionsSheet.addRows(positions.map((p) => [p.name]));

        // Add column names
        sheet.columns = candidateFields.map((c) => ({ header: c.label, key: c.value, width: 24 }));

        // validation for position
        const positionLetter = sheet.getColumn('position').letter;
        // @ts-ignore
        sheet.dataValidations.model[`${positionLetter}2:${positionLetter}9999`] = {
            type: 'list',
            showErrorMessage: true,
            errorStyle: 'error',
            error: 'Please select a valid job position from the list',
            allowBlank: true,
            formulae: [`=POSITIONS!$A$1:$A$${positions.length + 1}`], // POSITIONS is a worksheet with all positions
        };
        sheet.getCell(`${positionLetter}1`).note = 'Select a position from the dropdown';

        // // validation for deadline
        // const deadlineLetter = sheet.getColumn('deadline').letter;
        // // @ts-ignore
        // sheet.dataValidations.model[`${deadlineLetter}2:${deadlineLetter}9999`] = {
        //     type: 'date',
        //     operator: 'greaterThanOrEqual',
        //     showErrorMessage: true,
        //     allowBlank: true,
        //     formulae: [new Date()],
        // };
        // sheet.getCell(`${deadlineLetter}1`).note = 'Enter a date after today';

        // Export xlsx
        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        const blob = new Blob([buffer], { type: fileType });

        await saveAs(blob, 'sieve_template.xlsx');
    };

    return (
        <Link color="blue" fontSize="lg" onClick={exportExcel}>
            Download template
        </Link>
    );
};

const CandidateAddMultiple = () => {
    const isMobile = useIsMobile();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [candidates, setCandidates] = useState<Array<Candidate>>([]);
    const setCandidateAtIndex = (candidate: Candidate, index: number) => {
        const tempC = [...candidates];
        tempC[index] = candidate;
        setCandidates(tempC);
    };

    const { id: companyId } = useCurrentCompany();
    const { createMany, errors } = useManyInterviews(companyId?.toString());
    const { isSuccess, reset } = createMany;
    const { positions, isLoading } = useManyPositions(companyId?.toString());

    const [deadline, setDeadline] = useState<string>();

    // update deadline in candidates array
    useEffect(() => {
        setCandidates((cs) => cs.map((c) => ({ ...c, deadline: deadline })));
    }, [deadline]);

    const onSpreadsheetChange = (sheet: any) => {
        analytics.event('Multiple Interviews', 'File picker opened', `Button clicked`);
        const [columns, ...sheetWithoutFirstRow] = sheet;
        const candidatesFromSheet = sheetWithoutFirstRow
            .filter((row: []) => row.length) // Filter empty rows (Google sheets gave empty rows)
            .map((row: any) => {
                const candidateData: any = {};
                columns.map((columnName: string, index: number) => {
                    const key = candidateFields.find(
                        (k) => k.label?.toLowerCase() === columnName?.toLowerCase(),
                    )?.value;

                    if (key) candidateData[key] = row[index];
                    if (key === 'deadline')
                        candidateData[key] = row[index] && moment(row[index]).endOf('day').format();
                });
                return candidateData;
            });

        setCandidates(
            candidatesFromSheet.map((c: any) => ({
                ...c,
                companyId: companyId,
                positionId: positions.find((p) => p.name === c.position)?.id,
                deadline: deadline,
            })),
        );
    };

    const sendInvites = () => {
        console.log(candidates);
        createMany.mutate(candidates);
    };

    useEffect(() => {
        if (isSuccess) {
            analytics.event('Multiple Interviews', 'Successfully created', `${candidates.length}`);
            toast.success('Interviews sent successfully!');
            setCandidates([]);
            reset();
            onClose();
        }
    }, [isSuccess]);

    // Clear candidate on closing
    useEffect(() => {
        if (!isOpen) {
            setCandidates([]);
        }
    }, [isOpen]);

    return (
        <>
            <Button
                className="import-excel-button" // for react-joyride
                color="foreground"
                onClick={() => {
                    analytics.event('Multiple Interviews', 'Import modal opened', `Button clicked`);
                    onOpen();
                }}
            >
                IMPORT
            </Button>

            <Modal
                size={isMobile ? 'full' : '4xl'}
                onClose={onClose}
                isOpen={isOpen}
                motionPreset="slideInBottom"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add multiple candidates</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex flex="1">
                            <FilePicker
                                type={FileTypes.spreadsheet}
                                onChange={onSpreadsheetChange}
                            />

                            <Flex flex="1" />

                            {candidates.length && (
                                <>
                                    <Box maxW="md">
                                        <DatePicker
                                            name="deadline"
                                            placeholder="Deadline (2 weeks)"
                                            // label="Deadline (optional)"
                                            minDate={moment()}
                                            error={errors.deadline}
                                            onChange={(momentDate: moment.Moment) => {
                                                const date = momentDate
                                                    ? moment(momentDate).endOf('day').format()
                                                    : undefined;

                                                setDeadline(date);
                                            }}
                                        />
                                    </Box>
                                    <Button
                                        onClick={sendInvites}
                                        isLoading={createMany.isLoading}
                                        loadingText="SENDING"
                                        margin="0.8rem"
                                    >
                                        SEND INVITES
                                    </Button>
                                </>
                            )}
                        </Flex>

                        <Box my="8">
                            {!!candidates.length ? (
                                // <Table columns={columns} data={candidates} />
                                candidates.map((c, index) => (
                                    <CandidateRow
                                        index={index}
                                        candidate={c}
                                        setCandidate={(toSet: any) =>
                                            setCandidateAtIndex(toSet, index)
                                        }
                                        positions={positions}
                                        errors={errors}
                                    />
                                ))
                            ) : (
                                <Box textAlign="center" p="8">
                                    Select an excel file with the following column names
                                    <BodyDark>
                                        {candidateFields.map((f) => f.label).join(', ')}
                                    </BodyDark>
                                    <br />
                                    <br />
                                    OR
                                    <br />
                                    <br />
                                    <DownloadTemplateSheet />
                                </Box>
                            )}
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CandidateAddMultiple;
