import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import FlexView from 'react-flexview';

import { Position } from '../../types/schema';
import NavBar from '../../components/NavBar';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';

import { useColors } from '../../styles/colors';
import dimensions from '../../styles/dimensions';
import Card from '../../components/Card';
import { Heading, SubHeading } from '../../components/Fonts';
import fonts from '../../styles/fonts';
import ModuleScrollView from '../../components/ModuleScrollView';
import RichTextInput from '../../components/Form/RichTextInput';
import Editable from '../../components/Form/Editable';
import Form from '../../components/Form/FormContainer';
import Button from '../../components/Button';
import PositionStats from './PositionStats';
import ArchivePosition from './ArchivePosition';
import { useIsMobile, useLocalStorage } from '../../utils/hooks';
import { usePosition } from '../../api/hooks/usePositions';
import {
    Box,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useToast,
    Button as ChakraButton,
    MenuDivider,
    useDisclosure,
    Spacer,
    Flex,
    useInterval,
    Image,
    Tooltip,
} from '@chakra-ui/react';
import { ChevronDownIcon, InfoIcon, InfoOutlineIcon, SettingsIcon } from '@chakra-ui/icons';
import PositionQuestions from './PositionQuestions';
import Loading from '../../components/Loading';
import Input from '../../components/Form/Input';
import analytics from '../../utils/analytics';
import { showChangesSavedToast } from '../../utils';
import Tutorial from '../../components/Tutorial';

const POSITIONS_MAX_WIDTH = 1200;

const HalfScreenWidth = styled.div`
    height: calc(100vh - ${dimensions.navBarHeight});
    width: 50%;
    overflow: auto;

    padding: 1em;
`;

const Overview: React.FC = () => {
    const isMobile = useIsMobile();
    const { positionId, companyId } = useParams();
    const navigate = useNavigate();

    const {
        position: serverPosition,
        isLoading,
        update,
        errors,
    } = usePosition({ positionId, companyId });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [jobDescription, setJobDescription] = useState(serverPosition?.jobDescription);
    const maxDesLength = 8000;
    const isOverflowing = (jobDescription?.length || 0) > maxDesLength;

    // Description is long, update it on server every 5 seconds
    useInterval(() => {
        !isOverflowing &&
            jobDescription &&
            serverPosition?.jobDescription !== jobDescription &&
            updateOnline('jobDescription', jobDescription);
    }, 5000);

    const navigateToCandidates = () => {
        navigate({
            pathname: `/candidates/${companyId}`,
            search: createSearchParams({ positionId: positionId || 'undefined' }).toString(),
        });
    };

    const updateOnline = (key: string, value: any) => {
        update.mutate({ ...serverPosition, [key]: value });
    };

    const formFieldMarginTop = isMobile ? '1em' : '3em';

    if (isLoading && !serverPosition) return <Loading />;

    return (
        <>
            <Form onSubmit={(e) => e.preventDefault()}>
                <FlexView vAlignContent="center">
                    <SubHeading marginLeft="1em">Position Name</SubHeading>

                    <Spacer />

                    <Menu>
                        <MenuButton
                            as={ChakraButton}
                            size="sm"
                            leftIcon={<SettingsIcon />}
                            rightIcon={<ChevronDownIcon />}
                            colorScheme={'primary'}
                            variant="ghost"
                        >
                            Options
                        </MenuButton>

                        <MenuList fontSize="md" fontWeight="bold">
                            <MenuItem
                                className="manage-candidates-button" // for react-joyride
                                onClick={navigateToCandidates}
                            >
                                Manage Candidates
                            </MenuItem>
                            {/* <MenuItem>Create a Copy</MenuItem> */}
                            <MenuDivider />
                            <MenuItem
                                color="red.main"
                                onClick={() => {
                                    analytics.event(
                                        'Archive Position',
                                        'Modal opened',
                                        'Button clicked',
                                    );
                                    onOpen();
                                }}
                            >
                                Archive
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </FlexView>

                <Input
                    name="name"
                    defaultValue={serverPosition?.name}
                    placeholder="Position Name"
                    style={{ ...fonts.heading }}
                    onBlur={(e) => updateOnline('name', e.target.value)}
                />

                <Flex marginTop={formFieldMarginTop} align="center">
                    <SubHeading marginLeft="1em">Job description</SubHeading>

                    <Tooltip
                        label={`Put job description the same as you would in a job listing. This is shown before a candidate starts an interview.`}
                    >
                        <InfoOutlineIcon ml="2" />
                    </Tooltip>
                </Flex>
                <RichTextInput
                    className="job-description-input" // for react-joyride
                    name="jobDescription"
                    defaultValue={serverPosition?.jobDescription}
                    placeholder="Summary, roles and responsibilites, qualifications etc"
                    onChange={(value: any) => setJobDescription(value)}
                    onBlur={() => !isOverflowing && updateOnline('jobDescription', jobDescription)}
                    style={{ border: isOverflowing && '2px solid tomato' }}
                />
            </Form>

            <Flex m="2" fontSize="xs" color="primary.400">
                Changes are saved automatically
                <Spacer />
                <Box color={(isOverflowing && 'tomato') || 'gray.800' || undefined}>
                    {`${jobDescription?.length || 0} / ${maxDesLength}`}
                </Box>
            </Flex>

            <Button className="back-button" color="secondary" onClick={() => navigate(-1)}>
                GO BACK
            </Button>

            <ArchivePosition position={serverPosition} isOpen={isOpen} onClose={onClose} />
        </>
    );
};

export default () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const colors = useColors();
    const [tutorialDone, setTutorialDone] = useLocalStorage('POSITION_OVERVIEW_TUTORIAL_DONE');

    const [activeTab, setActiveTab] = useState('overview');
    const isOverviewActive = activeTab === 'overview';

    return (
        <>
            <NavBar
                moduleName="Overview"
                tabs={
                    isMobile
                        ? [
                              {
                                  name: 'Overview',
                                  active: isOverviewActive,
                                  onClick: () => setActiveTab('overview'),
                              },
                              {
                                  name: 'Questions',
                                  active: !isOverviewActive,
                                  onClick: () => setActiveTab('questions'),
                              },
                          ]
                        : []
                }
                // navBarRight={<Button onClick={() => navigate(-1)}>SAVE & GO BACK</Button>}
            />

            <ModuleScrollView padding="0">
                <ModuleMaxWidthContainer maxWidth={POSITIONS_MAX_WIDTH}>
                    {isMobile ? (
                        <div style={{ padding: '1em' }}>
                            {isOverviewActive ? <Overview /> : <PositionQuestions />}
                        </div>
                    ) : (
                        <FlexView>
                            <HalfScreenWidth>
                                <Overview />
                            </HalfScreenWidth>

                            <HalfScreenWidth>
                                <PositionQuestions />
                            </HalfScreenWidth>
                        </FlexView>
                    )}
                </ModuleMaxWidthContainer>
            </ModuleScrollView>

            <Tutorial
                tutorialKey={'POSITION_OVERVIEW_TUTORIAL_DONE'}
                steps={[
                    {
                        disableBeacon: true,
                        target: '.job-description-input',
                        content: 'Add a job description like you would do in a job posting.',
                    },
                    {
                        target: '.add-question-button',
                        content: 'Add interview questions next.',
                    },
                    {
                        target: '.candidates-module-link',
                        content:
                            'After adding all the questions you want, go to the candidates screen to send an interview.',
                    },
                    {
                        target: '.back-button',
                        content:
                            'All changes in this screen are automatically saved. You can go back anytime.',
                    },
                ]}
            />
        </>
    );
};
