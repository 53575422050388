import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import styled from 'styled-components';
import { Box, Center, Flex, Image, Text, Heading } from '@chakra-ui/react';

import { BodyDark, SubHeading, BodyText } from '../../components/Fonts';
import AppLogo from '../../components/AppLogo';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';
import ModuleScrollView from '../../components/ModuleScrollView';
import dimensions from '../../styles/dimensions';
import { useColors } from '../../styles/colors';
import Button from '../../components/Button';
import Form from '../../components/Form/FormContainer';
import Input from '../../components/Form/Input';
import ColorPicker from '../../components/Form/ColorPicker';
import { useAuthState } from '../../context/auth';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useCompany from '../../api/hooks/useCompany';
import { useCurrentCompany, useCurrentPermission } from '../../utils/hooks';
import ImageUpload from '../../components/Form/ImageUpload';
import LoginArt from '../Login/LoginArt';
import analytics from '../../utils/analytics';
import { useQueryClient } from 'react-query';
import { Company } from '../../types/schema';
import ErrorString from '../../components/ErrorString';

const defaultCompany = {
    jobTitle: 'HR',
    name: 'Mr. Developer',
    themeColor: '#443',
};

const FORM_MAX_WIDTH = 500;
const NAVBAR_MAX_WIDTH = 700;
const NAVBAR_HEIGHT = '5rem';

const NavBarContainer = styled.div`
    height: ${NAVBAR_HEIGHT};

    background-color: ${({ theme }) => theme.background};

    border-bottom: 1px solid ${({ theme }) => theme.foregroundDark};

    padding: 0.5em 1em 1.2em 1em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
`;

const OnboardingNavBar = ({ activeStep = 1 }) => {
    return (
        <NavBarContainer>
            <ModuleMaxWidthContainer maxWidth={NAVBAR_MAX_WIDTH}>
                <FlexView grow vAlignContent="bottom">
                    <AppLogo />

                    <FlexView grow />

                    <BodyDark>{`Step ${activeStep} of 2`}</BodyDark>
                </FlexView>
            </ModuleMaxWidthContainer>
        </NavBarContainer>
    );
};

const OnboardingScrollView = styled(ModuleScrollView)`
    height: calc(100% - ${NAVBAR_HEIGHT});
`;

const IntroContainer = styled(ModuleMaxWidthContainer)`
    max-width: ${NAVBAR_MAX_WIDTH}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const FormContainer = styled(ModuleMaxWidthContainer)`
    max-width: ${FORM_MAX_WIDTH}px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const OnboardingVideo = () => {
    const colors = useColors();

    return (
        <div
            style={{
                width: '100%',
                height: '25em',
                margin: '1em',
                background: colors.backgroundDark,
            }}
        ></div>
    );
};

const OnboardingIntro = ({ setStep }: any) => {
    return (
        <IntroContainer>
            <Heading marginTop="3em">
                Welcome Recruiter,
                <br />
                Hiring Just Became Your Superpower
            </Heading>

            <Box maxW="xl" mt="2" mb="4">
                <BodyText marginTop="1em">
                    A task as critical as recruitment must be done with refined care and expertise.
                    Sieve is the first of many tools to help make recruitment a core competency of
                    your team.
                </BodyText>
            </Box>

            <OnboardingVideo />

            <Button marginTop="2rem" onClick={() => setStep(2)}>
                CONTINUE
            </Button>
        </IntroContainer>
    );
};

const CompanySetup: React.FC = () => {
    const navigate = useNavigate();

    const [jobTitle, setJobTitle] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [themeColor, setThemeColor] = useState<string>('');
    const [file, setFile] = useState<any>();
    const [logo, setLogo] = useState<string>();

    const { register, uploadLogo, errors, setErrors } = useCompany();
    const { mutate, isLoading } = register;

    const queryClient = useQueryClient();

    return (
        <FormContainer>
            <Box w="100%" mt="4">
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        analytics.event('Company Data', 'Onboardning Page', undefined);
                        mutate(
                            { name, jobTitle, themeColor, file },
                            {
                                onSuccess: async (company: Company) => {
                                    await queryClient.invalidateQueries('me');
                                    navigate(`/${company.id}`);
                                },
                            },
                        );
                    }}
                >
                    <Input
                        name="name"
                        placeholder="Company Name"
                        onChange={(e) => {
                            setErrors({});
                            setName(e.target.value);
                        }}
                        error={errors.name}
                    />

                    <Input
                        name="position"
                        placeholder="Your Position"
                        onChange={(e) => {
                            setErrors({});
                            setJobTitle(e.target.value);
                        }}
                        error={errors.jobTitle}
                    />

                    <ColorPicker
                        placeholder={'Brand Color (Optional)'}
                        value={themeColor}
                        onChange={(color: string) => {
                            setErrors({});
                            setThemeColor(color);
                        }}
                        error={errors.themeColor}
                    />
                    {/* {!errors.themeColor && (
                        <Box ml="2" mb="4" color="gray">
                            Input the primary color of your brand
                        </Box>
                    )} */}

                    <Center>
                        <ImageUpload
                            value={logo}
                            onChange={(file: any) => {
                                setFile(file);
                                setLogo(URL.createObjectURL(file));
                            }}
                            isLoading={uploadLogo.isLoading}
                            label="Logo (Optional)"
                        />
                    </Center>

                    <Center>
                        <Text
                            mx="4"
                            mt="4"
                            maxW="sm"
                            fontSize="md"
                            fontWeight="500"
                            textAlign="center"
                        >
                            Your logo and color will be used to customize what your candidates see.
                        </Text>
                    </Center>

                    <Button isLoading={isLoading} type="submit" marginTop="4rem">
                        CONTINUE
                    </Button>

                    {!!errors.message && <ErrorString error={errors.message} />}
                </Form>
            </Box>
        </FormContainer>
    );
};

export default () => {
    useEffect(() => {
        analytics.pageView();
    }, []);

    const [step, setStep] = useState(1);

    // Users can add new companies now
    // const permission = useCurrentPermission();
    // if (permission?.status === 'ACTIVE') return <Navigate to={`/`} replace />;

    return (
        <Flex h="100%">
            <LoginArt />

            <Box flex="1" overflow="auto">
                <Center flexDir="column" mt="16" mb="8">
                    <Heading mx="2" size="xl" maxW="xl" textAlign="center">
                        Add{' '}
                        <Box as="span" color="primary.main">
                            Company{' '}
                        </Box>
                        Details To Make Sieve Yours.
                    </Heading>
                </Center>

                <Center>
                    <CompanySetup />
                </Center>
            </Box>

            <Image
                src="/12_1.svg"
                position="absolute"
                maxH={['0', '16rem', '22rem']}
                bottom="0"
                right="4"
                opacity="0.9"
            />
        </Flex>
    );
};
