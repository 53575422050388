import moment from 'moment';
import { DatePicker as AntDatePicker } from 'antd';

import dimensions from '../../styles/dimensions';
import { useColors } from '../../styles/colors';
import FormField from './FormField';
import { fontSizes } from '../../styles/fonts';
import styled from 'styled-components';

const StyledDatePicker = styled(AntDatePicker)`
    .ant-picker-input > input {
        font-size: ${fontSizes.md};
    }
`;

const DatePicker = (props: any) => {
    const colors = useColors();

    return (
        <FormField label={props.label} error={props.error}>
            <StyledDatePicker
                style={{
                    backgroundColor: colors.foreground,
                    padding: '0.85em',
                    borderWidth: 0,
                    borderRadius: dimensions.borderRadius,
                }}
                popupStyle={{
                    zIndex: 9999,
                    flexDirection: 'column',
                }}
                disabledDate={(current) =>
                    current &&
                    ((props.minDate && current < moment(props.minDate)) ||
                        (props.maxDate && current > moment(props.maxDate).endOf('day')))
                }
                {...props}
            />
        </FormField>
    );
};

export default DatePicker;
