import { useState, useEffect } from 'react';
import FlexView from 'react-flexview/lib';
import styled from 'styled-components';
import { Navigate, useParams } from 'react-router-dom';
import { Box, Flex, Spacer } from '@chakra-ui/react';

import Button from '../../components/Button';
import VideoRecorder from '../../components/VideoRecorder';
import { BodyText, Title } from '../../components/Fonts';
import { Footer, FullWidthContainer, HalfWidthContainer, Header } from './components';
import useInterviewCandidate from '../../api/hooks/useInterviewCandidate';

const HugeNumber = styled(Title)`
    font-size: 4em;
    letter-spacing: 400;
`;

const ContentContainer = styled(HalfWidthContainer)`
    align-items: center;
    padding: 2em;
`;

const useCountDown = (time: number) => {
    const [countdown, setCountdown] = useState<number>(time);

    useEffect(() => {
        if (!countdown) return;

        const intervalId = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [countdown]);

    return countdown;
};

export default () => {
    const { interviewId, slug } = useParams();
    const { interview, initiate } = useInterviewCandidate({ interviewId, slug });
    const company = interview?.company;

    const countdown = useCountDown(10);
    const isReady = interview?.status === 'UNDERWAY';

    console.log('D_72', interview);
    useEffect(() => {
        initiate.mutate();
    }, []);

    if (isReady && countdown === 0)
        return <Navigate to={`/interviews/underway/${interviewId}/${slug}`} />;

    return (
        <Flex h="100%" flexDir="column" align="center">
            <Header company={company} />

            <FullWidthContainer>
                <VideoRecorder hideTimer hideRec />

                <ContentContainer>
                    <HugeNumber>{countdown}</HugeNumber>

                    <BodyText>Take a deep breath. You got this!</BodyText>

                    <FlexView grow />

                    <Button color={'foreground'} marginTop="2em">
                        Starting interview...
                    </Button>
                </ContentContainer>
            </FullWidthContainer>

            <Spacer />

            <Footer />
        </Flex>
    );
};
