import { forwardRef, InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { Input as ChakraInput, InputRightElement, IconButton, InputGroup } from '@chakra-ui/react';

import dimensions from '../../styles/dimensions';
import fonts, { fontSizes } from '../../styles/fonts';
import FormField from './FormField';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';
import { FiEye, FiEyeOff } from 'react-icons/fi';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: string;
    label?: any;
    minWidth?: string;
    disabled?: boolean;
}

const Input = styled(ChakraInput)<InputProps>`
    ${fonts.body}
    font-size: ${fontSizes.md};

    min-width: ${(props) => props.minWidth || '10em'};
    /* flex-grow: 1; */

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};

    color: ${(props) => props.theme.onBackground};
    ::placeholder {
        color: ${(props) => props.theme.onBackgroundLight};
    }

    background: ${(props) => props.theme.foreground};
    border: none;
    border-radius: ${dimensions.borderRadius};

    &:hover {
        background: ${(props) =>
            props.disabled ? props.theme.foreground : props.theme.backgroundDark};
    }

    &:focus {
        background: ${(props) => props.theme.foregroundDark};
        outline: none;
    }

    padding: 1.55em 0.8em;
`;

const PasswordInput = forwardRef((props: InputProps, ref: any) => {
    const [show, setShow] = useState(false);
    return (
        <InputGroup>
            <Input {...props} type={show ? 'text' : 'password'} ref={ref} />
            <InputRightElement>
                <IconButton
                    mt="2"
                    mr="2"
                    variant="ghost"
                    color="gray.500"
                    aria-label="Toggle password"
                    // icon={show ? <VscEyeClosed size="20" /> : <VscEye size="20" />}
                    icon={show ? <RiEyeLine size="22" /> : <RiEyeCloseLine size="20" />}
                    // icon={show ? <FiEyeOff size="20" /> : <FiEye size="20" />}
                    onClick={() => setShow(!show)}
                />
            </InputRightElement>
        </InputGroup>
    );
});

export default forwardRef((props: InputProps, ref: any) => {
    const isPassword = props.type === 'password';

    return (
        <FormField label={props.label} error={props.error}>
            {isPassword ? <PasswordInput {...props} ref={ref} /> : <Input {...props} ref={ref} />}
        </FormField>
    );
});
