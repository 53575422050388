import { Center, Button, Text } from '@chakra-ui/react';
import { SiLinkedin } from 'react-icons/si';

import { useAuthFunctions } from '../../context/auth';
import analytics from '../../utils/analytics';

export default ({ isRegistering }: any) => {
    const { linkedInLogin } = useAuthFunctions();

    return (
        <Button
            onClick={() => {
                analytics.event('Login', 'LinkedIn', 'Button clicked');
                linkedInLogin();
            }}
            // w={'full'}
            maxW={'md'}
            m="2"
            borderRadius="sm"
            fontSize="md"
            colorScheme={'linkedin'}
            leftIcon={<SiLinkedin />}
        >
            <Center>
                <Text>{isRegistering ? 'Continue with LinkedIn' : 'Sign in with LinkedIn'}</Text>
            </Center>
        </Button>
    );
};
