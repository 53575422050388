import { Box, toast } from '@chakra-ui/react';

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const showChangesSavedToast = () => {
    toast.notify(
        () => (
            <Box bg="white" color="gray.600" mt="0" ml="100" p="20" fontSize="sm">
                Changes saved!
            </Box>
        ),
        {
            position: 'top-right',
            duration: 2000,
        },
    );
};

export const hexToRgb = (hex: string) => {
    return (
        hex
            ?.replace(
                /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                (m, r, g, b) => '#' + r + r + g + g + b + b,
            )
            ?.substring(1)
            ?.match(/.{2}/g)
            ?.map((x) => parseInt(x, 16)) || [0, 0, 0]
    );
};

export const getColorBasedOnBg = (bgColor: string, lightColor: string, darkColor: string) => {
    const [r, g, b] = hexToRgb(bgColor);
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

export const searchParamsToObj = (params: any) =>
    [...params.entries()].reduce((acc, tuple) => {
        // getting the key and value from each tuple
        const [key, val] = tuple;
        if (acc.hasOwnProperty(key)) {
            // if the current key is already an array, we'll add the value to it
            if (Array.isArray(acc[key])) {
                acc[key] = [...acc[key], val];
            } else {
                // if it's not an array, but contains a value, we'll convert it into an array
                // and add the current value to it
                acc[key] = [acc[key], val];
            }
        } else {
            // plain assignment if no special case is present
            acc[key] = val;
        }

        return acc;
    }, {});
