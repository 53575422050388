import { useEffect, useState } from 'react';
import Joyride from 'react-joyride';

import { useLocalStorage } from '../utils/hooks';
import { useColors } from '../styles/colors';

export default (props: any) => {
    const colors = useColors();
    const [tutorialDone, setTutorialDone] = useLocalStorage(props.tutorialKey || 'TUTORIAL_DONE');
    const [delayStartDone, setDelayStart] = useState(false);

    useEffect(() => {
        setTimeout(() => setDelayStart(true), props.delay || 1000);
    }, []);

    return (
        <Joyride
            continuous
            showSkipButton
            run={delayStartDone && !tutorialDone}
            callback={({ action, index, status, type }) => {
                if (['skipped', 'finished'].includes(status)) {
                    setTutorialDone(true);
                }
            }}
            styles={{
                options: {
                    primaryColor: colors.success,
                },
            }}
            locale={{ last: 'Done' }}
            {...props}
        />
    );
};
