import { Avatar, Box, Flex, useDisclosure, Image } from '@chakra-ui/react';
import FlexView from 'react-flexview/lib';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthState } from '../context/auth';
import SwitchCompanyModal from '../pages/Settings/SwitchCompanyModal';
import { useColors } from '../styles/colors';
import { useCurrentCompany, useCurrentRole, useIsAdmin } from '../utils/hooks';

import AppLogo from './AppLogo';
import { BodyLight, BodyText, ButtonText, SubHeading, SubTitle } from './Fonts';
import Icon from './Icon';

const SideBarContainer = styled.div`
    height: 100vh;
    width: 13%;
    min-width: 12em;

    background-color: ${(props) => props.theme.secondary};

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 1.8em;
    padding-bottom: 1.5em;
`;

const ModuleLinkContainer = styled(Link)<{ selected?: boolean }>`
    cursor: pointer;
    text-decoration: none;

    width: 100%;

    display: flex;
    align-items: center;

    background-color: ${(props) => props.selected && props.theme.secondaryDark};
    padding: 0.5em 1em;
    margin-top: 0.5em;
`;

const ModuleLink = ({ selected, name, logo, to }: any) => {
    const colors = useColors();

    return (
        <ModuleLinkContainer selected={selected} to={to}>
            <Icon size={22} color="white" name={logo} />
            <SubHeading color={colors.onSecondary} marginLeft="0.5em" style={{ fontWeight: 500 }}>
                {name}
            </SubHeading>
        </ModuleLinkContainer>
    );
};

export default () => {
    const { companyId } = useParams();
    const { pathname = '' } = useLocation();

    const { permissions } = useAuthState();
    const company = useCurrentCompany();

    const permissionRole = useCurrentRole();
    const isAdmin = useIsAdmin();
    console.log('D_4', permissions, permissionRole);

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <SideBarContainer>
            <Flex align="center" px="4" cursor="pointer" onClick={onOpen}>
                <Image
                    h="10"
                    w="10"
                    borderRadius="full"
                    src={company?.logos?.[0]}
                    bg="gray.50"
                    fit="contain"
                />
                <Box color="onSecondary.main" ml="3">
                    {company.name}
                </Box>
            </Flex>

            <SwitchCompanyModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />

            <FlexView grow column width="100%" marginTop="5em" marginBottom="6em">
                {!!isAdmin && (
                    <ModuleLink
                        name="Positions"
                        logo="briefcase"
                        to={`positions/${companyId}`}
                        selected={pathname.includes('position')}
                    />
                )}
                <div
                    className="candidates-module-link" // for react-joyride
                >
                    <ModuleLink
                        name="Candidates"
                        logo="user"
                        to={`candidates/${companyId}`}
                        selected={pathname.includes('candidate')}
                    />
                </div>

                {/* <FlexView grow /> */}
                <ModuleLink
                    name="Settings"
                    logo="settings"
                    to={`settings/${companyId}`}
                    selected={pathname.includes('settings')}
                />
            </FlexView>

            <AppLogo color="white" />
            <BodyLight marginTop="5px" color="#aaa">
                Beta v0.42
            </BodyLight>
        </SideBarContainer>
    );
};
