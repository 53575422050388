import { useTheme, DefaultTheme } from 'styled-components';

export enum Theme {
    light = 'light',
}

export const lightTheme: DefaultTheme = {
    primary: '#836DFF',
    primaryDark: '#5843CF',
    primaryLight: '#AFA1FF',
    onPrimary: '#FFFFFF',

    secondary: '#3B4248',
    secondaryDark: '#2A3136', // secondary is too dark, so dark is in fact lighter
    secondaryLight: '#586671',
    onSecondary: '#FFFFFF',

    background: '#FFFFFF',
    backgroundDark: '#F1F1F1',
    onBackground: '#3B4248',
    onBackgroundLight: '#A1A1A1',

    foreground: '#F5F5F5',
    foregroundDark: '#D9D9D9',
    onForeground: '#3B4248',

    blue: '#125FAF',
    lightblue: '#9CCBE7',

    green: '#009D79',
    lightgreen: '#ABD1BE',

    orange: '#F79D1E',
    lightorange: '#EDCF82',

    red: '#E35541',
    lightred: '#F6B28D',

    pink: '#EC6D9E',
    lightpink: '#FAB8C4',

    success: '#00B74A',
    error: '#F93154',
    warning: '#FFA900',
    info: '#39C0ED',

    // brand: 'onyx',
};

const themes = {
    [Theme.light]: lightTheme,
};

export const useColors = useTheme;

export default themes;
