import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';

console.log('WELCOME TO SIEVE INSPECT ELEMENT');

ReactDOM.render(
    <React.StrictMode>
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y4T749RCB8"></script>
            <script>
                {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-Y4T749RCB8');`}
            </script>
        </Helmet>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
