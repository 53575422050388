import { Box, Flex, Button, Heading, Text, Image } from '@chakra-ui/react';
import NavBar from '../../components/NavBar';
import NavBarPromo from '../../components/NavBarPromo';
import YoutubeEmbed from '../../components/YoutubeEmbed';
import { useCurrentCompany, useIsReviewer } from '../../utils/hooks';
import StartCreatingInterviewsButton from '../../components/NewWorkspaceButton';
import ModuleScrollView from '../../components/ModuleScrollView';
import ModuleMaxWidthContainer from '../../components/ModuleMaxWidthContainer';
import NewWorkspaceButton from '../../components/NewWorkspaceButton';

export default () => {
    const isReviewer = useIsReviewer();
    const company = useCurrentCompany();

    return (
        <>
            {isReviewer ? (
                <NavBar
                    moduleName="Positions"
                    navBarRight={
                        <>
                            <Box
                                mr="6"
                                textAlign="right"
                                display={['none', 'none', 'none', 'inherit']}
                            >
                                You have limited functionality in {company.name}'s workspace
                            </Box>
                            <NewWorkspaceButton />
                        </>
                    }
                />
            ) : (
                <NavBarPromo />
            )}

            <ModuleScrollView>
                <ModuleMaxWidthContainer maxWidth={1200}>
                    <Flex
                        position="relative"
                        bg="foreground.main"
                        p={[6, 6, 12]}
                        m={[4, 4, 8, 8, 8, 12, 16]}
                        maxW="60rem"
                        borderRadius="12"
                    >
                        <Box>
                            <Heading color="primaryLight.600" maxW="sm">
                                Setup Questions Once, Interview Thousands
                            </Heading>
                            <Text fontSize={['lg', 'xl']} mt={[6, 6, 12]} maxW="sm">
                                Or Simply select one job position, from our bank of 100+ curated
                                jobs
                            </Text>

                            <Image
                                src="/feature_image1.jpg"
                                maxH={['sm', 'sm']}
                                display={['inherit', 'inherit', 'none']}
                                boxShadow="5px 5px 18px 0px rgba(0,0,0,0.1)"
                                mt="10"
                            />

                            <StartCreatingInterviewsButton
                                text="Start Creating Interviews"
                                size="lg"
                                mt="16"
                            />
                        </Box>

                        <Image
                            position="absolute"
                            right="-8%"
                            src="/feature_image1.jpg"
                            maxH={['xs', 'xs', 'xs', 'md', 'lg']}
                            display={['none', 'none', 'inherit']}
                            boxShadow="5px 5px 18px 0px rgba(0,0,0,0.1)"
                            borderRadius="8"
                        />
                    </Flex>

                    <Text fontSize={['md', 'md', 'md', 'lg']} mt="44" ml={[6, 6, 12]}>
                        Watch Tutorial Videos To Learn More
                    </Text>

                    <Box
                        ml={[4, 4, 8, 8, 8, 12, 16]}
                        mt="6"
                        mb="20"
                        maxW="lg"
                        borderRadius="16"
                        overflow="hidden"
                    >
                        <YoutubeEmbed embedId="rEmRRxEcmwo" />
                    </Box>
                </ModuleMaxWidthContainer>
            </ModuleScrollView>
        </>
    );
};
