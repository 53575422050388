import styled from 'styled-components';

import dimensions from '../styles/dimensions';

const ModuleMaxWidthContainer = styled.div<{
    width?: string;
    maxWidth?: number;
    height?: string;
    style?: any;
}>`
    width: ${({ width }) => width || '100%'};
    max-width: ${({ maxWidth = dimensions.maxWidth }) => `${maxWidth}px`};

    height: ${({ height }) => height};

    ${({ style }) => style}
`;

export default ModuleMaxWidthContainer;
