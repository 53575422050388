import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    HStack,
    IconButton,
    Spacer,
    Stat,
    StatHelpText,
    StatNumber,
    Tooltip,
    useDisclosure,
    Wrap,
} from '@chakra-ui/react';
import comment from 'antd/lib/comment';
import { useEffect, useRef, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { GiRoundStar } from 'react-icons/gi';
import { useParams } from 'react-router-dom';
import useInterviewEvaluation from '../../api/hooks/useInterviewEvaluation';
import { SubHeading } from '../../components/Fonts';
import Editable from '../../components/Form/Editable';
import { useAuthState } from '../../context/auth';
import { showChangesSavedToast } from '../../utils';
import analytics from '../../utils/analytics';

import { RATING_OPTIONS } from '../../utils/constants';
import { useCurrentCompany } from '../../utils/hooks';

// amount 0 to 1
const blendColors = (colorA: string, colorB: string, amount: number) => {
    const [rA, gA, bA] = colorA.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [];
    const [rB, gB, bB] = colorB.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [];
    const r = Math.round(rA + (rB - rA) * amount)
        .toString(16)
        .padStart(2, '0');
    const g = Math.round(gA + (gB - gA) * amount)
        .toString(16)
        .padStart(2, '0');
    const b = Math.round(bA + (bB - bA) * amount)
        .toString(16)
        .padStart(2, '0');
    return '#' + r + g + b;
};

export const getColorForRating = (rating: number) => {
    const colorA = RATING_OPTIONS[rating > 1 ? Math.floor(rating) - 1 : 0].color;
    const colorB = RATING_OPTIONS[rating > 0 && rating < 5 ? Math.ceil(rating) - 1 : 4].color;
    return blendColors(colorA, colorB, rating % 1);
};

export const CurrentRating = ({ rating = 0, count = 0 }: { rating?: number; count?: number }) => {
    const color = getColorForRating(rating);

    return (
        <Box
            // boxShadow="rgba(0, 0, 0, 0.24) 0px 0.5px 2px"
            border="1px solid #ccc"
            fontWeight="bold"
            borderRadius="md"
            overflow="hidden"
            minW="16"
        >
            <Flex align="center" bg={rating ? color : 'gray.500'} px="3" py="2.5" color="white">
                <Box mr="2">{rating ? rating.toFixed(1) : '--'}</Box>
                <GiRoundStar />
            </Flex>

            <Box p="2" textAlign="center" fontWeight="500" fontSize="xs">
                {`${count} rating${count !== 1 ? 's' : ''}`}
            </Box>
        </Box>
    );
};

const RatingPicker = ({ rating }: { rating?: number }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef<any>();

    const [commentText, setCommentText] = useState<string | undefined>();
    // const [ratingNum, setRatingNum] = useState<number>(rating || 0);
    const ratingNum = rating || 0;
    const ratingColor = getColorForRating(ratingNum);

    const company = useCurrentCompany();
    const { interviewId, publicSlug } = useParams();
    const { setRating, addText } = useInterviewEvaluation({
        companyId: company.id?.toString(),
        interviewId,
        publicSlug,
    });

    useEffect(() => {
        if (setRating.isSuccess) {
            setRating.reset();
            showChangesSavedToast();
            // onClose();
        }
    }, [setRating.isSuccess]);

    // Set rating to what it was initially on close
    // useEffect(() => {
    //     if (!isOpen) {
    //         setRatingNum(rating || 0);
    //     }
    // }, [isOpen]);

    const RatingButton = ({ onClick, label, value }: any) => (
        <Button
            variant="outline"
            fontSize="md"
            m="0.25em"
            w="14"
            // rightIcon={(ratingNum <= value && <GiRoundStar size={14} />) || undefined}
            rightIcon={<GiRoundStar size={14} />}
            bg={(ratingNum >= value && ratingColor) || undefined}
            color={(ratingNum >= value && 'white') || 'gray.600'}
            // borderColor={(ratingNum >= value && ratingColor) || undefined}
            // color="gray.700"
            onClick={onClick}
        >
            {/* {(ratingNum <= value && label) || ''} */}
            {label}
        </Button>
    );

    return (
        <>
            <Flex flexDir="column">
                <SubHeading marginBottom="0.5em">Rating</SubHeading>

                <Wrap
                    mt="1"
                    className="rating-input" // for react-joyride
                >
                    {RATING_OPTIONS.map((o) => (
                        <RatingButton
                            key={o.value}
                            value={o.value}
                            label={o.label}
                            onClick={() => {
                                // setRatingNum(o.value);
                                // onOpen();
                                analytics.event('Rating', 'Set', o.label);
                                setRating.mutate(o.value);
                            }}
                        />
                    ))}
                </Wrap>
            </Flex>

            {/* <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Rating
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Flex flexDir="column">
                                <Flex mb="4">
                                    {RATING_OPTIONS.map((o) => (
                                        <RatingButton
                                            value={o.value}
                                            label={o.label}
                                            onClick={() => {
                                                setRatingNum(o.value);
                                            }}
                                        />
                                    ))}
                                </Flex>

                                <Editable
                                    name="comment"
                                    value={commentText || ''}
                                    onChange={(e: any) => setCommentText(e.target.value)}
                                    minRows={3}
                                    placeholder="Add a comment..."
                                />
                            </Flex>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                ref={cancelRef}
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                CANCEL
                            </Button>
                            <Button
                                ml="4"
                                colorScheme={'primary'}
                                isLoading={setRating.isLoading || addText.isLoading}
                                onClick={() => {
                                    setRating.mutate(ratingNum);
                                    commentText && addText.mutate(commentText as string);
                                }}
                            >
                                POST
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog> */}
        </>
    );
};

export default ({ interview }: any) => {
    const { user } = useAuthState();

    const userComment = interview?.comments?.find(({ userId, type }: any) => {
        if (userId === user?.id && type === 'RATING') return true;
        return false;
    });

    console.log('RATING', userComment?.rating, interview.averageRating, interview.totalRatings);

    if (!interview) return <></>;

    return (
        <HStack
            m="0.5em"
            mt="3em"
            spacing="2"
            className="rating-container" // for react-joyride
        >
            <RatingPicker rating={userComment?.rating} />
            <Spacer />
            <CurrentRating rating={interview.averageRating} count={interview.totalRatings} />
        </HStack>
    );
};
