const baseFontSize = 16;
export const fontSize = (size: number = 16) => `${size / baseFontSize}rem`;

export const fontSizes = {
    xxl: fontSize(22),
    xl: fontSize(20),
    lg: fontSize(18),
    md: fontSize(16),
    sm: fontSize(14),
    xs: fontSize(12),
};

const fonts = {
    title: {
        fontFamily: 'Sintony',
        fontWeight: 'bold',
        fontSize: fontSizes.xxl,
    },
    subTitle: {
        fontFamily: 'Sintony',
        fontWeight: 'normal',
        fontSize: fontSizes.lg,
    },

    heading: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: fontSizes.xl,
    },
    subHeading: {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        fontSize: fontSizes.md,
    },

    body: {
        fontFamily: 'Inter',
        fontWeight: 'normal',
        fontSize: fontSizes.sm,
    },
    bodyDark: {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        fontSize: fontSizes.sm,
    },
    bodyLight: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: fontSizes.xs,
    },

    button: {
        fontFamily: 'Sintony',
        fontWeight: 'bold',
        fontSize: fontSizes.sm,
    },
    buttonLight: {
        fontFamily: 'Sintony',
        fontWeight: 'normal',
        fontSize: fontSizes.xs,
    },
};

export default fonts;
