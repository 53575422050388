import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    useDisclosure,
} from '@chakra-ui/react';

import ModuleMaxWidthContainer from '../../../components/ModuleMaxWidthContainer';
import dimensions from '../../../styles/dimensions';
import CompanyLogo from '../../../components/Company/CompanyLogo';
import AppLogo from '../../../components/AppLogo';

export const FullWidthContainer = styled(ModuleMaxWidthContainer)`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    padding: 0.5em;
    padding-top: 2em;

    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        padding: 0;
    }
`;

export const HalfWidthContainer = styled.div`
    width: 50%;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    margin-left: 2em;
    padding: 1em;

    @media only screen and (max-width: ${dimensions.mobileWidth}) {
        width: 100%;
        margin-top: 0.5em;
        margin-left: 0;
    }
`;

export const Header = ({ company }: any) => {
    return (
        <Box w={['98%', '98%', '80%']}>
            <CompanyLogo company={company} />
        </Box>
    );
};

export const Footer = ({ hideImage }: { hideImage?: boolean }) => {
    return (
        <Flex
            w="100%"
            p="3"
            px={['4%', '4%', '10%']}
            mt="8"
            align="center"
            bg="foreground.main"
            position="relative"
        >
            {!hideImage && (
                <Image src="/guy_sitting.svg" position="absolute" bottom="2" height={[0, 0, 32]} />
            )}
            <Spacer />
            powered by
            <AppLogo onlyText color="secondary.main" ml="2" pt="0" fontWeight="400" />
        </Flex>
    );
};

export function checkUploadSpeed(
    iterations: number,
    update: (speed: number, average: number, isDone?: boolean) => void,
) {
    var average = 0,
        index = 0,
        timer = window.setInterval(check, 3000); //check every 3 seconds

    check();

    function check() {
        var xhr = new XMLHttpRequest(),
            url = '?cache=' + Math.floor(Math.random() * 10000), //random number prevents url caching
            mb = 0.1,
            data = getRandomString(mb), //1 meg POST size handled by all servers
            startTime: number,
            speed = 0;

        xhr.onreadystatechange = function (event) {
            if (xhr.readyState == 4) {
                speed = Math.round((1024 * mb) / ((Date.now() - startTime) / 1000));
                average == 0 ? (average = speed) : (average = Math.round((average + speed) / 2));
                update(speed, average);
                index++;
                if (index == iterations) {
                    window.clearInterval(timer);
                    update(speed, average, true);
                }
            }
        };
        xhr.open('POST', url, true);
        startTime = Date.now();
        xhr.send(data);
    }

    function getRandomString(sizeInMb: number) {
        var chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;':,./<>?", //random data prevents gzip effect
            iterations = sizeInMb * 1024 * 1024, //get byte count
            result = '';
        for (var index = 0; index < iterations; index++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    }
}

export const UploadSpeedWarning = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [avgSpeed, setAvgSpeed] = useState(0);

    useEffect(() => {
        checkUploadSpeed(5, (speed, avg, isDone) => {
            console.log('Upload Speed', speed, avg, isDone);
            isDone && setAvgSpeed(avg);
        }); // kbps
    }, []);

    useEffect(() => {
        // kbps
        if (avgSpeed && avgSpeed < 50) {
            onOpen();
        }
    }, [avgSpeed]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Internet too slow</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    We detected a low upload speed of {avgSpeed} kbps.
                    <br />
                    <br />
                    It might cause the interview to load very slowly. Please try on another network.
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="primary" mr={3} onClick={onClose}>
                        Okay
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
