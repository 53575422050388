import { Center, Spinner } from '@chakra-ui/react';

export default (props: any) => {
    return (
        <Center height="100%" {...props}>
            <Spinner m="4" />
            Loading
        </Center>
    );
};
